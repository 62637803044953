import React, {
  useContext, useEffect, useState, useRef, useReducer,
} from 'react';
import {
  useParams, useHistory,
} from 'react-router-dom';

import urlHash from '../../utils/functions/urlHash';
import useGridKPI from '../../hooks/useGridKpi';
import useKpiData from '../../hooks/useKpiData';
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';

import LabelCreate from '../../components/LabelCreate';
import LabelCreateToolbar from '../../components/Toolbars/LabelCreateToolbar';
import UnsplashPicker from '../../juristec-ui/core/UnsplashPicker';
import Loader from '../../juristec-ui/core/Loader';
import FilePreview from '../../components/Modals/FilePreview';

import handleStyleConfig from '../../components/LabelCreate/handleLabelConfig';
import { labelCreateInitialState, dateOptions } from '../../options';

const EMPTYOPT = { label: '', value: '' };

const columnInfoReducer = (state, action) => {
  switch (action.type) {
    case 'SET_COLUMN':
      return {
        ...state,
        selected: action.selected,
        block: false,
      };
    case 'SET_LINE':
      return { ...state, line: action.line };
    case 'SET_FORMAT': {
      return { ...state, format: action.selected };
    }
    case 'RESET': {
      return {
        block: true,
        selected: EMPTYOPT,
        line: 1,
        format: dateOptions[0],
      };
    }
    case 'SET_ALL': {
      const col = action.column ?? '';
      return {
        block: !action.column,
        selected: {
          label: col, value: col, id: col, type: action.colType,
        },
        line: action.line + 1 ?? 1,
        format: action.format?.length > 0 ? (
          dateOptions.find((d) => d.value === action.format)
        ) : dateOptions[0],
      };
    }
    default:
      return state;
  }
};

export default function LabelCreatePage() {
  const history = useHistory();
  const { hash } = useParams();
  const goBack = () => history.goBack();

  const getParams = () => {
    const str = urlHash(hash, true).split('/');
    return { dashboardKey: str[0], kpiKey: str[1] };
  };
  const { dashboardKey, kpiKey } = getParams();

  const { userCompany, currentUser, user } = useContext(AuthContext);
  const { setModalConfig, toggleModal } = useContext(ModalContext);
  const { setAlertConfig } = useContext(AlertContext);
  const { isLoading: fileIsLoading, state: filesState, filesAPI } = useContext(FilesContext);
  const [{
    dashboardDoc,
    kpisLayout,
    isLoading,
  }, gridKpiAPI] = useGridKPI(currentUser.uid, dashboardKey, currentUser, user);
  const [kpiDataAPI] = useKpiData(currentUser);

  const refCode = useRef('');

  const [styleConfig, setStyleConfig] = useState(labelCreateInitialState);
  const [rawImg, setRawImg] = useState('');
  const [typeTab, setTypeTab] = useState('SIMPLE');
  const [content, setContent] = useState('');
  const [question, setQuestion] = useState({ value: '', isDirty: true });
  const [selectedDatabase, setSelectedDatabase] = useState({
    label: '', value: '', id: '', owner: '',
  });
  const [dataFrame, setDataFrame] = useState(null);
  const [internalLoading, setInternalLoading] = useState(false);
  const [columnInfo, dispatchColumnInfo] = useReducer(columnInfoReducer, {
    block: true,
    selected: EMPTYOPT,
    line: 1,
    format: dateOptions[0],
  });

  useEffect(() => {
    if (kpiKey !== 'new' && filesState.started) {
      gridKpiAPI.getKpiInfo(kpiKey).then((kpi) => {
        if (kpi) {
          const captitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
          setStyleConfig({
            ...labelCreateInitialState,
            ...kpi.style,
            fontFamily: { value: kpi.style.fontFamily, label: captitalize(kpi.style.fontFamily) },
            name: kpi.name,
            link: kpi.link,
            kpiKey,
          });
          if (kpi.type === 'smartLabel') setTypeTab('SMART');
          if (kpi.type === 'Content') setTypeTab('CONTENT');
          setContent(kpi.name || '');
          setQuestion({ value: kpi.question || '', isDirty: false });
          refCode.current = kpi.code || '';
          setRawImg(kpi.style?.image || '');
          const kpiFile = filesState.files.find((f) => f.file_id === kpi.database) ?? {};
          setSelectedDatabase({
            label: kpiFile?.filename?.replace('.metrics', '') ?? '',
            value: kpiFile.file_id ?? '',
            id: kpiFile.file_id ?? kpiFile.filename ?? '',
            owner: kpiFile.owner ?? '',
          });
          const metaValue = kpi?.meta?.values?.[0] ?? {};
          dispatchColumnInfo({
            type: 'SET_ALL',
            column: metaValue.column,
            line: metaValue.line,
            format: metaValue.map,
            colType: metaValue.type,
          });
        } else {
          goBack();
        }
      }).catch((err) => {
        console.log('errr', err);
      });
    }
    gridKpiAPI.loadLayoutAndDash();
  }, [gridKpiAPI, kpiKey, filesState.started]);

  useEffect(() => {
    if (!user || !currentUser || !userCompany?.gptApi || filesState.started) return;
    (async () => {
      const filesRes = await filesAPI.init();
      if (filesRes.error) {
        setAlertConfig({
          type: 'error',
          text: filesRes.msg,
        });
      }
    })();
  }, [filesAPI, currentUser, user, filesState.started]);

  useEffect(() => {
    if (selectedDatabase.value !== '') {
      (async () => {
        setInternalLoading(true);
        if (dataFrame) {
          setContent('');
        }
        const sampleRes = await filesAPI.getFileSample(selectedDatabase.value, false);
        if (sampleRes.error) {
          goBack();
          setAlertConfig({
            type: 'error',
            text: sampleRes.msg,
            child: sampleRes.raw,
          });
          goBack();
        } else {
          setDataFrame(sampleRes.res);
        }
        setInternalLoading(false);
      })();
    }
  }, [selectedDatabase, filesAPI]);

  const submitLabel = async (config) => {
    const LabelObject = {
      name: content,
      link: styleConfig.link || '',
      style: {
        bgColor: styleConfig.bgColor,
        fontColor: styleConfig.fontColor,
        fontSize: styleConfig.fontSize,
        bold: styleConfig.bold,
        underline: styleConfig.underline,
        fontFamily: styleConfig.fontFamily.value,
        italic: styleConfig.italic,
        colorOpacityText: styleConfig.colorOpacityText,
        opacityText: styleConfig.opacityText,
        opacityImage: styleConfig.opacityImage,
        imageFit: styleConfig.imageFit,
        textPosition: styleConfig.textPosition,
        UseGlobalColor: styleConfig.UseGlobalColor ?? true,
      },
      status: 'active',
      type: 'label',
    };

    if (typeTab === 'SMART') {
      LabelObject.type = 'smartLabel';
      LabelObject.question = question.value;
      LabelObject.code = refCode.current;
      LabelObject.database = selectedDatabase.id;
      LabelObject.fileOwner = selectedDatabase.owner;
    } else if (typeTab === 'CONTENT') {
      LabelObject.type = 'Content';
      LabelObject.database = selectedDatabase.id;
      LabelObject.fileOwner = selectedDatabase.owner;
      LabelObject.meta = JSON.stringify({
        values: [{
          column: columnInfo.selected.value,
          map: columnInfo.selected.type === 'datetime64[ns]' ? columnInfo.format.value : '',
          line: columnInfo.line - 1,
          type: columnInfo.selected.type,
        }],
        kpi_type: 'Content',
        lines: [],
        columns: [],
        filters: [],
        linesMap: [],
        columnsMap: [],
        filterValues: [],
        groupValues: [],
        database: selectedDatabase.id,
      });
      LabelObject.style.isNumeric = styleConfig.isNumeric ?? false;
      LabelObject.style.Decimals = styleConfig.Decimals ?? 'auto';
      LabelObject.style.separadorNumericoCasas = styleConfig.separadorNumericoCasas ?? 0;
      LabelObject.style.LabelCurrencyControl = styleConfig.LabelCurrencyControl ?? 'Nenhum';
      LabelObject.style.LabelGroupingControl = styleConfig.LabelGroupingControl ?? 'Nenhum';
    }
    const result = await gridKpiAPI.saveInsightCard(
      kpisLayout, kpiKey, LabelObject, rawImg,
    );
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
      });
      return;
    }

    URL.revokeObjectURL(config?.style?.image);
    goBack();
  };

  const handleStyleChanges = (value, diff) => {
    setStyleConfig(handleStyleConfig(styleConfig, diff, value));
  };

  const handleImgPick = (url) => {
    setStyleConfig((prev) => ({ ...prev, image: url }));
    setRawImg(url);
  };

  const addDesktopImg = (file) => {
    URL.revokeObjectURL(styleConfig.image);
    setStyleConfig((prev) => ({ ...prev, image: URL.createObjectURL(file) }));
    setRawImg(file);
  };

  const removeImg = () => {
    URL.revokeObjectURL(styleConfig.image);
    setStyleConfig((prev) => ({ ...prev, image: '' }));
    setRawImg('');
  };

  const openUnsplashPicker = () => {
    setModalConfig({
      title: 'Selecionar Fotos',
      children: (
        <UnsplashPicker
          closeModal={() => toggleModal('unsplash-picker-modal')}
          selectImg={handleImgPick}
          defaultSearch="Insight"
        />
      ),
      nodeTarget: 'unsplash-picker-modal',
    });
  };

  const checkInputs = () => {
    const val = content?.trim() || '';
    if (typeTab === 'SMART') {
      return (
        (val.length > 0 && val.length <= 2000)
        && selectedDatabase.id?.length > 0
        && !question.isDirty
        && !internalLoading
      );
    } if (typeTab === 'CONTENT') {
      return (
        (val.length > 0 && val.length <= 2000)
        && selectedDatabase.id?.length > 0
        && columnInfo.selected.value.length > 0
        && !internalLoading
      );
    }
    return (
      (val.length > 0 && val.length <= 2000)
      || styleConfig?.image?.length > 0
    );
  };

  const openFilePreviewModal = () => {
    setModalConfig({
      title: selectedDatabase.label,
      children: !!dataFrame && <FilePreview dataFrame={dataFrame} />,
    });
  };

  const handleVirtualAssistant = async () => {
    if (selectedDatabase.id !== '') {
      setInternalLoading(true);
      const gptRes = await filesAPI.callVirtualAssistant(selectedDatabase.id, question.value, 'insight');
      setContent(gptRes.message);
      setQuestion((q) => ({ ...q, isDirty: false }));
      refCode.current = gptRes.code;
      setInternalLoading(false);
    }
  };

  const handleGetContent = async () => {
    setInternalLoading(true);
    const kpiBody = {
      values: [{
        column: columnInfo.selected.value,
        map: columnInfo.selected.type === 'datetime64[ns]' ? columnInfo.format.value : '',
        line: columnInfo.line - 1,
        type: columnInfo.selected.type,
      }],
      kpi_type: 'Content',
      lines: [],
      columns: [],
      filters: [],
      linesMap: [],
      columnsMap: [],
      filterValues: [],
      groupValues: [],
    };
    const res = await kpiDataAPI.genKpiData(kpiBody, selectedDatabase.value);
    setContent(res?.data?.[0]?.[0] ?? '-');
    setInternalLoading(false);
  };

  return (
    <>
      {(fileIsLoading || isLoading || !filesState.started) && <Loader /> }
      <LabelCreateToolbar
        isEdit={!!dashboardDoc && kpiKey !== 'new'}
        dashboardTitle={dashboardDoc?.name || ''}
        handleClickReturn={goBack}
        handleSubmit={submitLabel}
        disableSave={!checkInputs()}
      />
      <LabelCreate
        allowGpt={userCompany?.gptApi}
        styleConfig={styleConfig}
        handleStyleChanges={handleStyleChanges}
        rawImgState={[rawImg, setRawImg]}
        typeTabState={[typeTab, setTypeTab]}
        contentState={[content, setContent]}
        handleUnsplashPicker={openUnsplashPicker}
        addDesktopImg={addDesktopImg}
        removeImg={removeImg}
        databaseOptions={filesState.files.map((val) => ({
          label: val.filename.replace('.metrics', ''),
          value: val.file_id,
          id: val.file_id || val.filename,
          owner: val.owner,
        }))}
        selectedDatabaseState={[selectedDatabase, setSelectedDatabase]}
        openFilePreviewModal={openFilePreviewModal}
        columns={Object.entries(dataFrame?.types ?? {}).map(([k, v]) => ({
          label: k, value: k, id: k, type: v,
        }))}
        lineMax={(dataFrame?.index?.length ?? 0) * (dataFrame?.total_pages ?? 0)}
        questionState={[question, setQuestion]}
        submitQuestion={handleVirtualAssistant}
        getContent={handleGetContent}
        columnInfoState={[columnInfo, dispatchColumnInfo]}
        isLoading={internalLoading || !filesState?.files?.length > 0}
        dashboardDoc={dashboardDoc}
      />
    </>
  );
}
