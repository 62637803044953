/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ExpandDiagonally,
  Compact,
  BookOpen,
  BookClosed,
} from '../../../juristec-ui/icons';
import FilePaginationContainer from '../../FilePaginationContainer';
import DataTable from '../../../juristec-ui/core/DataTable';
import { DataTableContainer, ButtonIconWrapper } from './styled/DataFrameModal.styled';
import Button from '../../../juristec-ui/core/Button';
import formatValue from '../../../utils/functions/formatTableValue';

const DataFrameModal = ({
  dataFrame, fileLength, fetchFileSampleData, theme, columnsDescription,
}) => {
  const [compactView, setCompactView] = useState(false);
  const [showColumnsDescription, setShowColumnsDescription] = useState(false);
  const [page, setPage] = useState(0);
  const [stateSort, setStateSort] = useState({
    order: 0,
    field: '',
  });
  const [dataTable, setDatatable] = useState(dataFrame);

  useEffect(() => {
    const fetchData = async () => {
      const pageSize = compactView ? 40 : 20;
      const { newDT } = await fetchFileSampleData(page, stateSort, pageSize);
      setDatatable(newDT || dataFrame);
    };
    fetchData();
  }, [page, stateSort.field, stateSort.order, compactView]);

  const fileColumns = useMemo(() => {
    if (showColumnsDescription) {
      return [
        {
          field: 'metrica',
          label: 'Métricas',
          sortable: false,
          valueGetter: (row) => <b>{row.metrica}</b>,
          dataColor: theme.primary,
        },
        ...dataTable?.columns.reduce((acc, column) => {
          if (column.field !== 'index') {
            acc.push({
              field: column.field,
              label: column.label,
              sortable: false,
              dataColor: column.dataColor,
              valueGetter: (p) => (p[column.field] !== '-'
                ? formatValue(
                  p[column.field], dataTable.types[column.field],
                ) : p[column.field]),
            });
          }
          return acc;
        }, []),
      ];
    }

    // Original columns logic for data view
    return dataFrame?.columns || [];
  }, [dataFrame, showColumnsDescription]);

  const tableData = useMemo(() => {
    if (!showColumnsDescription) return dataTable?.rowData || [];

    const descriptionKeys = [
      { key: 'count', label: 'Contagem' },
      { key: 'unique', label: 'Valores Únicos' },
      { key: 'top', label: 'Valor Mais Frequente' },
      { key: 'freq', label: 'Frequência' },
      { key: 'mean', label: 'Média' },
      { key: 'std', label: 'Desvio Padrão' },
      { key: 'min', label: 'Mínimo' },
      { key: '25%', label: 'Primeiro Quartil' },
      { key: '50%', label: 'Mediana' },
      { key: '75%', label: 'Terceiro Quartil' },
      { key: 'max', label: 'Máximo' },
    ];
    return descriptionKeys.map((metric) => {
      const row = { metrica: metric.label };
      return dataTable?.columns.reduce((acc, column) => {
        if (column.field !== 'index') {
          acc[column.field] = columnsDescription[column?.field]?.[metric?.key] || '-';
        }
        return acc;
      }, row);
    });
  }, [dataTable, columnsDescription, showColumnsDescription]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!showColumnsDescription && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            shape="rounded"
            onClick={() => setCompactView(!compactView)}
          >
            <ButtonIconWrapper>
              {compactView
                ? <ExpandDiagonally width={16} height={16} fill={theme.white} />
                : <Compact width={16} height={16} fill={theme.white} />}
              <span style={{ marginLeft: '8px' }}>
                {compactView ? 'Visão normal' : 'Visão compacta'}
              </span>
            </ButtonIconWrapper>
          </Button>
        )}
        {!compactView && (
          <Button
            variant="contained"
            color="primary"
            size="medium"
            shape="rounded"
            onClick={() => setShowColumnsDescription(!showColumnsDescription)}
          >
            <ButtonIconWrapper>
              {showColumnsDescription
                ? <BookClosed width={16} height={16} fill={theme.white} />
                : <BookOpen width={16} height={16} fill={theme.white} />}
              <span style={{ marginLeft: '8px' }}>
                {showColumnsDescription ? 'Ocultar sumário das colunas' : 'Mostrar sumário das colunas'}
              </span>
            </ButtonIconWrapper>
          </Button>
        )}
      </div>
      <DataTableContainer>
        <DataTable
          columns={fileColumns}
          rowData={tableData}
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: '-5px',
            zIndex: 1,
            backgroundColor: theme.modal,
          }}
          style={{ width: 'max-content' }}
          compactView={compactView}
          setStateSort={setStateSort}
        />
      </DataTableContainer>
      <FilePaginationContainer
        page={page}
        setPage={setPage}
        totalPages={Math.floor(fileLength / (compactView ? 40 : 20))}
      />
    </>
  );
};

const columnDescriptionShape = PropTypes.shape({
  count: PropTypes.number.isRequired,
  unique: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  freq: PropTypes.number,
  first: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  last: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mean: PropTypes.number,
  std: PropTypes.number,
  min: PropTypes.number,
  '25%': PropTypes.number,
  '50%': PropTypes.number,
  '75%': PropTypes.number,
  max: PropTypes.number,
});

DataFrameModal.propTypes = {
  dataFrame: PropTypes.shape({
    columns: PropTypes.object,
    rowData: PropTypes.arrayOf(PropTypes.array),
  }).isRequired,
  fileLength: PropTypes.number.isRequired,
  fetchFileSampleData: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    modal: PropTypes.string,
    white: PropTypes.string,
    primary: PropTypes.string,
  }).isRequired,
  columnsDescription: PropTypes.objectOf(columnDescriptionShape).isRequired,
};

export default DataFrameModal;
