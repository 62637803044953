import styled from 'styled-components';
import { gap } from '../../../../juristec-ui/styles/theme';

export const Container = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 50vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${gap('10px', 'column')};
`;

export const Title = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const InfoContainer = styled.div`
  display: inline;
  width: 100%;
  max-width: 100%;
`;

export const InfoText = styled.span`
  font-size: 14px;
`;

export const InfoBold = styled.b`
  color: ${({ theme }) => theme.info};
  font-weight: bold;
`;

export const ErrorBold = styled.b`
  color: ${({ theme }) => theme.error};
  font-weight: bold;
`;
