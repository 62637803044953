/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../juristec-ui/core/DataTable';
import Popover from '../../juristec-ui/core/Popover';
import IconButton from '../../juristec-ui/core/IconButton';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import Tooltip from '../../juristec-ui/core/Tooltip';
// import Select from '../../juristec-ui/core/Select';

import {
  DateIcon, ExpandMore, Letters, Number,
} from '../../juristec-ui/icons';

import {
  MainContainer,
  ScrollContainer,
  IconWrapper,
  IconFlipWrapper,
} from './styled/FileViewData.styled';

import formatValue from '../../utils/functions/formatTableValue';
import FilePaginationContainer from '../FilePaginationContainer';
// import { csvDateFormatOptions, decimalOptions } from '../../options';

const typeColor = {
  float: 'typeNumber',
  abc: 'typeText',
  date: 'typeDate',
};

const typeIcon = {
  float: <Number />,
  abc: <Letters />,
  date: <DateIcon />,
};

/**
 * A component to display the file data
 */
const FileViewData = ({
  fileData,
  columnTypeChange,
  page,
  setPage,
  fileLength,
  canManipulate,
  columnTypes,
  origin,
  compactView,
  setStateSort,
  columnsDescription,
  showColumnsDescription,
  //
  // dateFormat,
  // setDateFormat,
  // decimalFormat,
  // setDecimalFormat,
  //
  // isCsv,
}) => {
  // console.log(columnTypes);
  const [openPopoverType, setOpenPopoverType] = useState({});

  const theme = useTheme();

  const totalPages = Math.floor(fileLength / (compactView ? 40 : 20));

  const handlePopoverType = (field, isOpen) => {
    setOpenPopoverType((old) => ({ ...old, [field]: isOpen }));
  };

  // Seletor de tipos no header da tabela
  const TypePicker = (field, isCustom) => (
    <Popover
      open={!isCustom && (openPopoverType[field] || false)}
      closePopover={() => handlePopoverType(field, false)}
      style={{
        zIndex: '1050',
      }}
    >
      <Popover.Action>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: compactView ? '5px' : '10px',
          marginRight: compactView ? '0px' : '4px',
          ...(compactView ? { maxWidth: '10.6rem' } : {}),
        }}
        >
          {(origin !== 'LegalOne' && origin !== 'LegalOneExtractor') && (canManipulate) && (
            <Tooltip
              text="Coluna customizada"
              textWhen={isCustom}
              direction="top"
              className={isCustom ? 'cc-type-picker' : 'type-picker'}
            >
              <IconButton
                onClick={() => handlePopoverType(field, !openPopoverType[field])}
                shape="rounded"
                variant="contained"
                size={compactView ? 'small' : 'medium'}
                color={theme[typeColor[columnTypes[field]]]}
                style={isCustom ? {
                  padding: compactView ? '0 3px 0 3px' : '0 5px 0 5px', marginLeft: compactView ? '3px' : '5px', cursor: 'default',
                } : {
                  padding: compactView ? '0 0 0 3px' : '0 0 0 5px', marginLeft: compactView ? '3px' : '5px', cursor: 'pointer',
                }}
              >
                <IconWrapper>{typeIcon[columnTypes[field]]}</IconWrapper>
                {!isCustom && (
                  <IconFlipWrapper flip={openPopoverType[field]}>
                    <ExpandMore />
                  </IconFlipWrapper>
                )}
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Popover.Action>
      <Popover.Content>
        <List>
          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'abc')}
          >
            <Letters />
            Validar como texto
          </ListItem>
          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'float')}
          >
            <Number />
            Validar como número
          </ListItem>

          <ListItem
            noOutline
            onClick={() => columnTypeChange(field, 'date')}
          >
            <DateIcon />
            Validar como data
          </ListItem>

        </List>
      </Popover.Content>
    </Popover>
  );

  const fileColumns = useMemo(() => {
    if (showColumnsDescription) {
      const columns = [
        {
          field: 'metrica',
          label: 'Métricas',
          sortable: false,
          valueGetter: (row) => <b>{row.metrica}</b>,
          dataColor: theme.primary,
        },
        ...(fileData?.columns || []).reduce((acc, column) => {
          if (column !== 'index') {
            acc.push({
              field: column,
              label: column,
              sortable: false,
              dataColor: theme[typeColor[columnTypes[column]]],
              valueGetter: (p) => (
                p[column] !== '-' ? formatValue(p[column], columnTypes[column]) : p[column]
              ),
            });
          }
          return acc;
        }, []),
      ];
      return columns;
    }

    // Original columns logic for data view
    const customNames = fileData?.custom_columns?.map((cc) => cc.name) ?? [];
    return fileData?.columns.reduce((aux, column, i) => {
      if (column === 'index') return aux;
      aux.push({
        tools: TypePicker(column, customNames.includes(column)),
        dataColor: theme[typeColor[columnTypes[column]]],
        valueGetter: (p) => formatValue(p[i], columnTypes[column]),
        sortable: true,
        field: column,
        label: column,
      });
      return aux;
    }, []) ?? [];
  }, [fileData, columnTypes, openPopoverType, compactView, showColumnsDescription]);

  const tableData = useMemo(() => {
    if (!showColumnsDescription) return fileData?.data || [];
    const descriptionKeys = [
      { key: 'count', label: 'Contagem' },
      { key: 'unique', label: 'Valores Únicos' },
      { key: 'top', label: 'Valor Mais Frequente' },
      { key: 'freq', label: 'Frequência' },
      { key: 'mean', label: 'Média' },
      { key: 'std', label: 'Desvio Padrão' },
      { key: 'min', label: 'Mínimo' },
      { key: '25%', label: 'Primeiro Quartil' },
      { key: '50%', label: 'Mediana' },
      { key: '75%', label: 'Terceiro Quartil' },
      { key: 'max', label: 'Máximo' },
    ];
    return descriptionKeys.map((metric) => {
      const row = { metrica: metric.label };
      return fileData?.columns.reduce((acc, column) => {
        if (column !== 'index') {
          acc[column] = columnsDescription[column]?.[metric.key]?.toString() || '-';
        }
        return acc;
      }, row);
    });
  }, [fileData, columnsDescription, showColumnsDescription]);

  return (
    <MainContainer className="file-view-data-container">
      <ScrollContainer>
        <DataTable
          columns={fileColumns}
          rowData={tableData}
          headerColor="transparent"
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.background,
          }}
          compactView={compactView}
          setStateSort={setStateSort}
          toolsPosition="start"
        />
      </ScrollContainer>
      <FilePaginationContainer page={page} setPage={setPage} totalPages={totalPages} />
    </MainContainer>
  );
};

const columnDescriptionShape = PropTypes.shape({
  count: PropTypes.number.isRequired,
  unique: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  freq: PropTypes.number,
  first: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  last: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mean: PropTypes.number,
  std: PropTypes.number,
  min: PropTypes.number,
  '25%': PropTypes.number,
  '50%': PropTypes.number,
  '75%': PropTypes.number,
  max: PropTypes.number,
});

FileViewData.propTypes = {
  /**
   * A string with the name of the file
   */
  // fileName: PropTypes.string.isRequired,
  /**
   * A object that contains the data of the file (columns, rows, types)
   */
  fileData: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.string,
    ),
    custom_columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  /**
   * The data row quantity of the file
   */
  fileLength: PropTypes.number,
  /**
   * The file page to be displayed
   * Each page has a maximum of 20 rows
   * Starts at 0
   */
  page: PropTypes.number.isRequired,
  /**
   * The origin type of file
   */
  origin: PropTypes.string.isRequired,
  /**
   * check wheter or not the user can manipulate data in the file
   */
  canManipulate: PropTypes.bool.isRequired,
  /**
   * A function change the page
   */
  setPage: PropTypes.func.isRequired,
  /**
   * An object to store the types of each column in the file
   */
  columnTypes: PropTypes.objectOf(PropTypes.string).isRequired,
  /**
     * A function to handle the change of the column type
     */
  columnTypeChange: PropTypes.func.isRequired,

  /**
   * A boolean to check if the view is compact
   */
  compactView: PropTypes.bool.isRequired,
  /**
   * object to store the selected Date format
   */
  // dateFormat: PropTypes.shape({
  //   label: PropTypes.string,
  //   value: PropTypes.string,
  //   id: PropTypes.string,
  // }).isRequired,

  /**
   *
   */
  // setDateFormat: PropTypes.func.isRequired,

  /**
   * object to store the selected decimal separator format
   */
  // decimalFormat: PropTypes.shape({
  //   label: PropTypes.string,
  //   value: PropTypes.string,
  //   id: PropTypes.string,
  // }).isRequired,

  /**
   * function to change the select
   */
  // setDecimalFormat: PropTypes.func.isRequired,
  //
  // isCsv: PropTypes.bool.isRequired,
  /**
   * A function to set the sort state
   */
  setStateSort: PropTypes.func,
  /**
   * An object with the columns description
   */
  columnsDescription: PropTypes.objectOf(columnDescriptionShape).isRequired,
  /**
   * A boolean to check if the columns description should be displayed
   */
  showColumnsDescription: PropTypes.bool.isRequired,
};

FileViewData.defaultProps = {
  fileLength: 0,
  setStateSort: () => {},
};

export default FileViewData;
