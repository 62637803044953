import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import Select from '../../../juristec-ui/core/Select';
import Button from '../../../juristec-ui/core/Button';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';

import { verifyEmail } from '../../../juristec-ui/utils/validators/inputTextValidators';

import {
  AddUserContainer,
  ItemContainer,
} from './styled/AddUser.styled';

const adminInfo = { id: 'super', label: 'Proprietário', value: 'super' };
const scientistInfo = { id: 'scientist', label: 'Cientista', value: 'scientist' };
const guestInfo = { id: 'guest', label: 'Convidado', value: 'guest' };

/**
 * A modal to handle the addition of new users to the instance
 */
const AddUser = ({
  user,
  userIsAdmin,
  usersEmails,
  scientistEmails,
  submitData,
  hide,
  fromAdmin,
  tourContext = { tourOpen: false },
}) => {
  const [emailInput, setEmailInput] = useState({ value: '', error: true, errorMsg: '' });
  const [selected, setSelected] = useState(guestInfo);
  const [scientist, setScientist] = useState({
    value: '',
    label: 'Selecione',
    id: 'select-label',
  });

  let profileOptions = [];
  if (user?.role === 'super' || userIsAdmin) {
    profileOptions = scientistEmails.length > 0
      ? [
        adminInfo,
        guestInfo,
        scientistInfo,
      ]
      : [
        adminInfo,
        scientistInfo,
      ];
  } else if (user?.role === 'scientist') {
    profileOptions = [
      guestInfo,
      scientistInfo,
    ];
  } else {
    profileOptions = [
      guestInfo,
    ];
  }

  const emailInputHandle = (value) => {
    const val = value.split(' ').filter((v) => v).join('');
    const msg = verifyEmail(val, true);
    setEmailInput({
      value: val,
      error: msg.length !== 0,
      errorMsg: msg,
    });
  };

  const handleSubmit = () => {
    const emailInUse = usersEmails.includes(emailInput.value);
    if (emailInUse) {
      setEmailInput({
        value: emailInput.value,
        error: true,
        errorMsg: 'Já existe um usuário adicionado com este email',
      });
    } else if (!emailInput.error) {
      const responsibleScientist = fromAdmin ? scientist.id : user.id;
      submitData({
        email: emailInput.value,
        role: selected.value,
        scientist: selected.value !== 'guest' ? '' : responsibleScientist,
      });
    } else {
      emailInputHandle(emailInput.value);
    }
  };

  return (
    <>
      <AddUserContainer>
        <ItemContainer>
          <InputTextLine
            label="E-mail do usuário"
            placeholder="novousuario@email.com"
            errorMessage={emailInput.errorMsg}
            error={emailInput.errorMsg.length > 0}
            onChange={(e) => emailInputHandle(e.target.value)}
            value={emailInput.value}
          />
        </ItemContainer>
        <ItemContainer>
          <Select
            selectLabel="Perfil do usuário"
            options={profileOptions}
            fullWidth
            placement="start"
            value={selected}
            onChange={(val) => setSelected(val)}
            atModal
          />
        </ItemContainer>
        {selected.value === 'guest' && fromAdmin && (
          <ItemContainer>
            <Select
              selectLabel="Cientista responsável"
              options={scientistEmails}
              fullWidth
              placement="start"
              value={scientist}
              onChange={(val) => setScientist(val)}
              atModal
            />
          </ItemContainer>
        )}
      </AddUserContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleSubmit()}
          size="large"
          disabled={emailInput.error || (fromAdmin ? selected?.value === 'guest' && scientist?.value === '' : false)}
        >
          Adicionar
        </Button>
      </ActionsGroup>
    </>
  );
};

AddUser.propTypes = {
  /**
   * Current user info
   */
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    id: PropTypes.string,
  }).isRequired,
  /**
   * Array of users emails already added
   */
  usersEmails: PropTypes.arrayOf(PropTypes.string),
  /*
  * Array of scientist emails
  */
  scientistEmails: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  /**
   * A function that sends the new data back to the parent
   */
  submitData: PropTypes.func.isRequired,
  /**
   * Function to close the modal
   */
  hide: PropTypes.func.isRequired,
  /**
   * A boolean that shows if the call of this modal comes from the admin page
   */
  fromAdmin: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
  tourContext: PropTypes.shape({
    tourOpen: PropTypes.bool,
    nextStep: PropTypes.func,
  }),
};

AddUser.defaultProps = {
  usersEmails: [],
  scientistEmails: [{}],
  fromAdmin: false,
  userIsAdmin: false,
  tourContext: { tourOpen: false, nextStep: () => {} },
};

export default AddUser;
