import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

// components
import DataTable from '../../../juristec-ui/core/DataTable';
import TablePagination from '../../../juristec-ui/core/TablePagination';
import Tooltip from '../../../juristec-ui/core/Tooltip';
import IconButton from '../../../juristec-ui/core/IconButton';

import {
  Upload, UpdateError, UpdateWarning, UpdateSuccess,
} from '../../../juristec-ui/icons';

// styled
import {
  Container,
  Infos,
  StyledFont,
  FilesList,
  KpisCount,
  IconWrapper,
  EmptyList,
  UpdateDateContainer,
  UpdatedByContainer,
  OwnerLabel,
  Title,
  Options,
} from './styled/DashboardDependencies.styled';

// utils
import { formatDateTime, isoDateToUTC } from '../../../juristec-ui/utils/functions/lab';
import originIcon, { originAutoUpdated } from '../../../juristec-ui/utils/originMap';

function DashboardDependencies({
  currentUser,
  dashboard,
  files,
  users,
  numberOfKpis,
  deviceType,
  adminAccess,
  getReportStatus,
  updateFile,
}) {
  const [page, setPage] = useState(0);
  const [data, setData] = useState(files);
  const theme = useTheme();

  const DependencyOptions = ({ dependency }) => {
    /** Checks if the file is automatically updated */
    const isAutoUpdated = originAutoUpdated(dependency.origin);

    /**
     * Return visual cues depending on file update characteristics
     * @returns {object} Object with corresponding update icon and color
     */
    const visualInfo = () => {
      if (!isAutoUpdated) return { icon: <Upload />, color: 'primary' };
      if (origin.lastAttemptStatus === 'ERROR') return { icon: <UpdateError />, color: 'error' };
      if (origin.lastAttemptStatus === 'WARNING') return { icon: <UpdateWarning />, color: 'warning' };
      return { icon: <UpdateSuccess />, color: 'success' };
    };

    const { icon, color } = visualInfo();

    const auxUpdateDate = () => {
      const date = new Date();
      const updateDate = isoDateToUTC(date);
      setData((p) => p.map((d) => {
        if (d.file_id === dependency.file_id) {
          return {
            ...d,
            updated_at: updateDate,
          };
        }
        return d;
      }));
    };

    const updateOrStatus = () => (isAutoUpdated
      ? getReportStatus(dependency, auxUpdateDate)
      : updateFile(dependency, auxUpdateDate));

    const updateMessage = () => {
      if (isAutoUpdated) {
        return 'Atualizado automaticamente';
      }
      if (dependency.origin === 'Snapshot') {
        return 'Histórico de arquivo não pode ser substituído';
      }
      if (currentUser.id === dependency.owner || dependency.shared_write.includes(currentUser.id)) {
        return 'Atualizar arquivo';
      }
      return 'Você não pode atualizar um arquivo de outra pessoa';
    };

    return (
      <Options>
        <Tooltip text={updateMessage()} atModal>
          <IconButton
            variant="pattern"
            shape="rounded"
            color={color}
            onClick={updateOrStatus}
            disabled={(!isAutoUpdated && !(currentUser.id === dependency.owner
              || dependency.shared_write.includes(currentUser.id))) || dependency.origin === 'Snapshot'}
          >
            {icon}
          </IconButton>
        </Tooltip>
      </Options>
    );
  };

  DependencyOptions.propTypes = {
    dependency: PropTypes.shape({
      origin: PropTypes.string,
      owner: PropTypes.string,
      shared_write: PropTypes.arrayOf(PropTypes.string),
      file_id: PropTypes.string,
    }).isRequired,
  };

  const getUpdateDate = (file) => (file.updated_at || file.updatedBy ? (
    <UpdateDateContainer>
      {file.updated_at && (
        <span>{formatDateTime(file.updated_at, { time: 'half' })}</span>
      )}
      {!!file.updatedBy && (
        <UpdatedByContainer>
          {users.filter((u) => u.uid === file.updatedBy)}
        </UpdatedByContainer>
      )}
    </UpdateDateContainer>
  ) : '-');

  const getOwner = (ownerId) => {
    const userOwner = adminAccess ? users.find((user) => user.user_id === ownerId)
      : users.find((user) => user.id === ownerId);
    return userOwner?.email || '';
  };

  const columns = [
    {
      field: 'origin',
      label: '',
      valueGetter: (param) => (
        <IconWrapper>{originIcon(param.origin)}</IconWrapper>
      ),
    },
    {
      field: 'name',
      label: 'Arquivo',
      valueGetter: (param) => (
        <>
          <div>
            {param?.filename || ''}
          </div>
          <OwnerLabel>{getOwner(param?.owner || param?.ownerId)}</OwnerLabel>
        </>
      ),
      sortable: true,
    },
    deviceType !== 'phone' ? {
      field: 'created_at',
      label: 'Criado em',
      valueGetter: (param) => (param.created_at ? formatDateTime(param.created_at, { time: 'half' }) : '-'),
      sortable: true,
    } : {},
    deviceType !== 'phone' ? {
      field: 'updatedAt',
      label: 'Atualizado em',
      valueGetter: (param) => getUpdateDate(param),
      sortable: true,
    } : {},
    (deviceType !== 'phone' && !adminAccess) ? {
      field: 'options',
      role: 'optionsBtn',
      label: 'Opções',
      valueGetter: (param) => <DependencyOptions dependency={param} />,
    } : {},
  ];

  return (
    <Container>
      <Infos>
        <StyledFont>
          <Title>Dashboard: </Title>
          {' '}
          {dashboard?.name || ''}
        </StyledFont>
        <KpisCount>
          {numberOfKpis ? (
            <StyledFont>
              <Title>KPIs: </Title>
              {' '}
              {numberOfKpis || 0}
            </StyledFont>
          ) : (
            <StyledFont>
              <Title>Arquivos: </Title>
              {' '}
              {data.length}
            </StyledFont>
          )}
        </KpisCount>
      </Infos>
      <FilesList>
        {data.length > 0 ? (
          <>
            <DataTable
              columns={columns}
              rowData={data}
              rowColor={theme.tableBackground}
              strippedRowsColor={theme.containerOdd}
              defaultSortField="name"
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.background,
              }}
              tbodyStyle={{
                fontSize: '14px',
              }}
              usePagination
              page={page}
              itemsPerPage={5}
            />
            <TablePagination
              page={page}
              setPage={setPage}
              totalPages={
                Math.floor(data.length % 5 === 0
                  ? (data.length / 5) - 1
                  : data.length / 5)
              }
            />
          </>
        ) : (
          <EmptyList>
            {'Este dashboard ainda não utilizou nenhum arquivo para a construção de KPI\'s.'}
          </EmptyList>
        )}
      </FilesList>
    </Container>
  );
}

DashboardDependencies.propTypes = {
  /**
   * Object user info.
  * */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  /**
   * Object with the dashboard data.
  * */
  dashboard: PropTypes.shape({
    name: PropTypes.string,
    databases: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }).isRequired,
  /**
   * Array of the files data.
  * */
  files: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      lastUpdateUser: PropTypes.string,
      filename: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Object with the list of users from the instance.
  * */
  users: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ).isRequired,
  /**
   * Number of KPI's in the dashboard.
   */
  numberOfKpis: PropTypes.number,
  /**
   * The current device type by viewport width
   */
  deviceType: PropTypes.string.isRequired,
  /**
   * Boolean indicating if the modal is opened from the admin page.
   */
  adminAccess: PropTypes.bool,
  /**
   * Function that opens the getReportStatus modal
   */
  getReportStatus: PropTypes.func,
  /**
   * Function that opens the getReportStatus modal
   */
  updateFile: PropTypes.func,
};

DashboardDependencies.defaultProps = {
  numberOfKpis: 0,
  adminAccess: false,
  getReportStatus: () => {},
  updateFile: () => {},
};

export default DashboardDependencies;
