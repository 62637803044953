/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveMarimekko } from '@nivo/marimekko';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis, {
  customPercentAxisRight,
  customPercentAxisLeft,
  customPercentAxisTop,
  customPercentAxisBottom,
} from '../../utils/formatCharts/formatAxis';
import KpiStatus from '../../utils/chartTools/KpiStatus';
import { genDataHistogram } from '../../utils/formatCharts/handleData';

const Histogram = ({
  data, styleConfig, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });

  useEffect(() => {
    setChartData(genDataHistogram(data, styleConfig));
  }, [
    data,
    styleConfig.DataFormatValueType,
  ]);

  const createTooltip = (val) => customTooltip(val, 'histogram', styleConfig);

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          data?.index?.[0] === 'Totais', // TODO
        ],
      ])}
    >
      <ResponsiveMarimekko
        data={chartData.data}
        dimensions={[{
          id: chartData.key,
          value: 'value',
        }]}
        id="label"
        value="bin"
        /* GENERAL */
        margin={styleConfig.Margin}
        layout={styleConfig.LayoutControl}
        innerPadding={0}
        outerPadding={0}
        /* BORDER */
        borderWidth={styleConfig.BorderWidthControl.value}
        borderColor={styleConfig.BorderColorControl}
        /* AXIS */
        enableGridX={styleConfig.EnableGridX}
        enableGridY={styleConfig.EnableGridY}
        axisTop={styleConfig.LayoutControl === 'horizontal' ? (
          customPercentAxisTop(styleConfig, null, false)
        ) : (
          customAxis('top', styleConfig, styleConfig.LayoutControl === 'horizontal')
        )}
        axisLeft={styleConfig.LayoutControl !== 'horizontal' ? (
          customPercentAxisLeft(styleConfig, null, false)
        ) : (
          customAxis('left', styleConfig)
        )}
        axisBottom={styleConfig.LayoutControl === 'horizontal' ? (
          customPercentAxisBottom(styleConfig, null, false)
        ) : (
          customAxis('bottom', styleConfig)
        )}
        axisRight={styleConfig.LayoutControl !== 'horizontal' ? (
          customPercentAxisRight(styleConfig, null, false)
        ) : (
          customAxis('right', styleConfig)
        )}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        tooltip={createTooltip}
        /* STYLE */
        colors={colorSelector({
          dataLength: chartData.data.length,
          config: styleConfig,
          colorKey: 'id',
          globalTheme,
        })}
        /* THEME */
        theme={{
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: styleConfig.AxisGlobal.legendFontSize,
                fontFamily: `'${styleConfig.AxisGlobal.fontFamily}', Arial`,
                fill: styleConfig.AxisGlobal.color,
              },
            },
            ticks: {
              text: {
                fontFamily: `'${styleConfig.AxisGlobal.fontFamily}', Arial`,
                fontSize: styleConfig.AxisGlobal.fontSize,
                fill: styleConfig.AxisGlobal.color,
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
      />
    </KpiStatus>
  ) : <></>;
};

Histogram.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Histogram.defaultProps = {
  globalTheme: {},
};

export default Histogram;
