/* eslint-disable no-param-reassign */
import { expDateOrder } from '../../../utils/formatCharts/orderDatetime';
import { customValueLabel } from './handleCustomValue';
import formatTableValue from '../../../../utils/functions/formatTableValue';
import getStateInitials from '../../charts/Map/mapConfigs/getStateInitials';
import compare from '../../../../utils/functions/sorting';

/**
 * Calculates totals based on actual data (not 'Totals' category).
 * @param {string[]} index Index from data matrix.
 * @param {array<string[]>} data Values from data matrix.
 * @param {string[]} columns Columns names.
 * @returns {object} Object that contains column totals and global totals.
 */
const getDataTotals = (index, data, columns) => (
  index.reduce((auxSum, idx, i) => {
    if (idx !== 'Totais') {
      columns.forEach((key, j) => {
        auxSum[idx] = (auxSum[idx] || 0) + (+data[i][j]);
        auxSum.global += (+data[i][j]);
      });
    }
    return auxSum;
  }, { global: 0 })
);

/**
 * Sorts data based on style configs.
 * @param {object[]} data Data for the chart.
 * @param {object} config Style config.
 * @param {boolean|false} invert When `true` inverts the sorting order.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {object[]} Data sorted.
 */
export const dataSort = (data, config, invert = false) => {
  if (config && data) {
    const copyData = [...data];
    if (config.CustomSortControl?.checked && config.CustomSortControl.kpiCategories) {
      const { kpiCategories } = config.CustomSortControl;
      return copyData.sort(
        (a, b) => kpiCategories.indexOf(a.label) - kpiCategories.indexOf(b.label),
      );
    }
    switch (config.SortValuesControl) {
      // 'Não ordenado' now is labeled as 'Automático'
      case 'Não ordenado':
      case 'Alfabética A-Z':
        return copyData.sort((a, b) => (invert ? -1 : 1) * (
          compare(expDateOrder(a.label), expDateOrder(b.label))
        ));
      case 'Alfabética Z-A':
        return copyData.sort((a, b) => (invert ? -1 : 1) * (
          compare(expDateOrder(b.label), expDateOrder(a.label))
        ));
      case 'Valor Crescente':
        return copyData.sort((a, b) => (invert ? -1 : 1) * (
          compare(expDateOrder(a.value), expDateOrder(b.value), true)
        ));
      case 'Valor Decrescente':
        return copyData.sort((a, b) => (invert ? -1 : 1) * (
          compare(expDateOrder(b.value), expDateOrder(a.value), true)
        ));
      default:
        return copyData;
    }
  }
  return data;
};

/**
 * Receives a 1 dimension (value + line) data matrix and converts it to a chart format.
 * Only works for `Pie`, `Funnel` and `Waffle` charts.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {boolean|false} invert When `true` inverts the sorting order.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   id: string,
 *   label: string,
 *   value: number,
 *  }>;
 *  total: number,
 *  hasNegative: boolean;
 * }} Data formatted for the chart.
 */
export const genData1D = (rawData, config, invert = false) => {
  const { index, data, columns } = rawData;
  const dataTotal = getDataTotals(index, data, columns);
  const isPercentual = config?.DataFormat?.type === 'percentual';
  let hasNegative = false;

  let fData = index.reduce((aux, idx, i) => {
    if (idx !== 'Totais') {
      if (+data[i][0] < 0) hasNegative = true;
      aux.push({
        id: idx,
        label: idx,
        value: isPercentual ? +((data[i][0] * 100) / dataTotal.global) : +data[i][0],
      });
    }
    return aux;
  }, []);

  fData = dataSort(fData, config, invert);

  return {
    ready: true, data: fData, total: isPercentual ? 100 : +dataTotal.global, hasNegative,
  };
};

/**
 * Receives a 1 dimension (value + line) data matrix and converts it to a `calendar` chart format.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   id: string,
 *   label: string,
 *   day: string,
 *   value: number,
 *  }>;
 *  total: number,
 * }} Data formatted for the calendar chart.
 */
export const genData1DCalendar = (rawData, config) => {
  const genericData = genData1D(rawData, config);
  const calendarData = genericData.data?.reduce((aux, datum) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(datum.id)) {
      aux.push({
        ...datum,
        day: datum.id,
      });
    }
    return aux;
  }, []);
  return { ...genericData, ready: calendarData.length > 0, data: calendarData };
};

/**
 * Receives a 1 dimension (value + line) data matrix and converts it to a `map` chart format.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {string} field Key that determines the region of the map.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   id: string,
 *   label: string,
 *   value: number,
 *  }>;
 *  total: number,
 * }} Data formatted for the map chart.
 */
export const genData1DMap = (rawData, config, field) => {
  const genericData = genData1D(rawData, config);
  let min = 0;
  let max = config?.DataFormat?.type === 'percentual' ? 10 : 0;

  const mapData = genericData.data?.map((datum) => {
    const valId = (`${datum.id}`).normalize('NFD').replace(/\p{Diacritic}/gu, '').trim().toLowerCase();
    min = Math.min(datum.value, min);
    max = Math.max(datum.value, max);
    return ({
      ...datum,
      id: field === 'UF' ? getStateInitials(valId) : valId,
    });
  });
  return {
    ...genericData, ready: mapData.length > 0, data: mapData, domain: [min, max],
  };
};

/**
 * Takes a raw array of columns and normalizes the structure.
 * @param {array<string>|array<array<string>} rawColumns Array of columns from the data matrix.
 * @param {boolean} [withTotals] If true, keep the `Totais` column.
 * @returns {string[]} A array of columns names, in string.
 */
const normalizeColumns = (rawColumns, withTotals = false) => (
  rawColumns.reduce((aux, columnDt) => {
    if (typeof columnDt === 'string') aux.push(columnDt);
    else {
      const k = columnDt[1];
      if (k !== 'Totais' || withTotals) aux.push(k);
    }
    return aux;
  }, [])
);

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a chart format.
 * Only works for `Radar` and `Bar` charts.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {boolean|false} invert When `true` inverts the sorting order.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   uid: string,
 *   label: string,
 *   value: number,
 *   nCategories: string,
 *  }>;
 *  keys: string[],
 *  biggests: {
 *    global: number,
 *    categories: number,
 *  }
 *  hasNegative: boolean;
 * }} Data formatted for the chart.
 */
export const genData2DWithKeys = (rawData, config, invert = false) => {
  const { index, data, columns } = rawData;
  const keys = normalizeColumns(columns);
  const dataTotals = getDataTotals(index, data, keys);
  const biggests = { global: 0, categories: 0 };
  const isPercentual = config?.DataFormat?.type === 'percentual';
  let isTgtGlobal = config?.DataFormat?.target === 'global';
  let hasNegative = false;

  if (columns.length === keys.length) {
    isTgtGlobal = true;
  }

  let fData = index.reduce((aux, idx, i) => {
    if (idx !== 'Totais') {
      const datum = { uid: idx, label: idx, value: 0 };
      keys.forEach((key, j) => {
        if (+data[i][0] < 0) hasNegative = true;
        datum[key] = isPercentual ? (
          (data[i][j] * 100) / (isTgtGlobal ? dataTotals.global : dataTotals[idx])
        ) : (+data[i][j]);
        datum.value += datum[key];
        biggests.categories = Math.max(biggests.categories, datum[key]);
      });
      biggests.global = Math.max(biggests.global, datum.value);
      aux.push(datum);
    }
    return aux;
  }, []);

  fData = dataSort(fData, config, invert);

  return {
    ready: true, data: fData, keys, biggests, hasNegative, total: dataTotals.global,
  };
};

/**
 * Sorts points data (categories and sub-categories) based on style configs.
 * Only works for data generated by `genData2DWithPoints` function.
 * @param {object[]} data Data for the chart.
 * @param {object} config Style config.
 * @param {boolean|false} invert When `true` inverts the sorting order.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {object[]} Data sorted.
 */
export const dataSortPoints = (data, config, invert) => (
  dataSort(
    data.map((datum) => ({
      ...datum,
      data: dataSort(datum.data, config, !invert),
    })),
    config,
    invert,
  )
);

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a chart format.
 * Only works for `Line` and `RadialBar` charts.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {boolean|false} invert When `true` inverts the sorting order.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   id: string,
 *   label: string,
 *   value: number,
 *   data: array<{
 *      id: string,
 *      label: string,
 *      value: number,
 *      x: string,
 *      y: number,
 *    }>
 *  }>;
 * biggest: number;
 * smallest: number;
 * hasNegative: boolean;
 * }} Data formatted for the chart.
 */
export const genData2DWithPoints = (rawData, config, invert = false) => {
  const { index, data, columns } = rawData;
  const keys = normalizeColumns(columns);
  const isPercentual = config?.DataFormat?.type === 'percentual';
  let biggest = 0;
  let smallest = 0;
  let isTgtGlobal = config?.DataFormat?.target === 'global';
  let hasNegative = false;

  let categories = keys;
  let pointers = index;
  let is2D = false;
  let fData = [];
  if (columns.length !== keys.length) {
    categories = index;
    pointers = keys;
    is2D = true;
  } else isTgtGlobal = true;

  const dataTotals = getDataTotals(index, data, keys);

  fData = categories.reduce((cAux, cat, i) => {
    if (cat !== 'Totais') {
      const datum = { id: cat, label: cat, value: 0 };
      datum.data = pointers.reduce((pAux, pt, j) => {
        if (pt !== 'Totais') {
          if (+(is2D ? data[i][j] : data[j][i]) < 0) hasNegative = true;
          const val = isPercentual ? (
            ((is2D ? data[i][j] : data[j][i]) * 100) / (
              isTgtGlobal ? dataTotals.global : dataTotals[cat]
            )
          ) : +(is2D ? data[i][j] : data[j][i]);
          datum.value += val;
          pAux.push({
            id: pt,
            label: pt,
            x: pt,
            y: val,
            value: val,
          });
          biggest = Math.max(biggest, val);
          smallest = Math.min(smallest, val);
        }
        return pAux;
      }, []);
      cAux.push(datum);
    }
    return cAux;
  }, []);

  fData = dataSortPoints(fData, config, invert);

  return {
    ready: true, data: fData, biggest, smallest, hasNegative,
  };
};

/**
 * Formats the table line values.
 * @param {string} index Atribute name.
 * @param {number} value Line value.
 * @param {number} len Quantity of columns (without 'Totais').
 * @param {object} config Style config.
 * @param {boolean} isAvg Checks if the value function is average (true) or other (false).
 * @returns Formatted line value.
 */
const formatLines = (index, value, len, config, isAvg) => {
  if (index === 'Totais') {
    if (isAvg && len > 0) {
      return customValueLabel(value / len, config);
    }
  }
  return customValueLabel(value, config);
};

/**
 * Formats the table column values.
 * @param {string} index Atribute name.
 * @param {number} value Column value.
 * @param {number} rowLen Quantity of lines (without 'Totais').
 * @param {number} colLen Quantity of columns (without 'Totais').
 * @param {object} config Style config.
 * @param {boolean} isAvg Checks if the value function is average (true) or other (false).
 * @returns Formatted column value.
 */
const formatColumns = (index, value, rowLen, colLen, config, isAvg) => {
  if (isAvg) {
    if (index === 'Totais') return customValueLabel(value / (rowLen * colLen), config);
    return customValueLabel(value / rowLen, config);
  }
  return customValueLabel(value, config);
};

/**
 * Formats the table row attribute to correctly tag the Total label.
 * @param {string} index Atribute name.
 * @param {boolean} isAvg Checks if the value function is average (true) or other (false).
 * @returns Formatted attribute name.
 */
const formatIndexTotals = (index, isAvg) => {
  if (index === 'Totais' && isAvg) {
    return 'Médias';
  }
  return index;
};

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a `Table` rows format.
 * Only works for `Table` chart.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {boolean} enableSorting Enables the "sort by click" function.
 * @param {boolean} isAvg Checks if the value function is average (true) or other (false).
 * @returns {{
 *  ready: boolean;
 *  cols: array<{
 *    field: string,
 *    label: string,
 *    sortable: boolean,
 *    valueGetter: function
 *  }>;
 * }} Data formatted for the chart.
 */
export const genDataColumns = (rawData, config, enableSorting, isAvg) => {
  const { index, columns } = rawData;
  const keys = normalizeColumns(columns, config.ColumnShowTotalControl);
  let totalsExists = false;

  let cols = keys.reduce((aux, k) => {
    if (k === 'Totais') {
      totalsExists = true;
      return aux;
    }
    aux.push({
      field: k,
      label: k,
      sortable: enableSorting,
      isNumeric: true,
      valueGetter: (param) => (
        formatLines(param.index, param[k], index.length - 1, config, isAvg)
      ),
    });
    return aux;
  }, []).sort((a, b) => compare(expDateOrder(a.field), expDateOrder(b.field)));

  if (totalsExists && config.ColumnShowTotalControl && config.ColumnOnlyShowTotalControl) cols = [];

  cols.unshift({
    field: 'index',
    label: '-',
    sortable: enableSorting,
    isNumeric: false,
    valueGetter: (param) => formatIndexTotals(param.index, isAvg),
  });

  if (totalsExists && config.ColumnShowTotalControl) {
    cols.push({
      field: 'Totais',
      label: isAvg ? 'Médias' : 'Totais',
      sortable: enableSorting,
      isNumeric: true,
      valueGetter: (param) => (
        formatColumns(
          param.index, param.Totais, keys.length - 1, index.length - 1, config, isAvg,
        )
      ),
    });
  }

  return { ready: true, cols };
};

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a `Table` columns format.
 * Only works for `Table` or `Value*` charts.
 * @param {object} rawData Data matrix from the api.
 * @param {boolean|false} globalTotalOnly Returns the global total
 * and removes the rows (for the `Value` chart).
 * @returns {{
 *  ready: boolean;
 *  total: number;
 *  qtdElements: number;
 *  }|{
 *  ready: boolean;
 *  rowTotals: array<{
 *    string: string,
 *  }>;
 *  rows: array<{
 *    string: string,
 *  }>;
 *  qtdElements: number;
 * }} Data formatted for the chart.
 */
export const genDataRows = (rawData, globalTotalOnly = false) => {
  const { index, data, columns } = rawData;
  const keys = normalizeColumns(columns);
  const rowTotals = { index: 'Totais', Totais: 0 };

  const rows = index.reduce((aux, idx, i) => {
    if (idx !== 'Totais') {
      const datum = { index: idx };
      keys.forEach((k, j) => {
        datum[k] = +data[i][j];
        datum.Totais = (datum.Totais || 0) + (+data[i][j]);
        rowTotals[k] = (rowTotals[k] || 0) + (+data[i][j]);
      });
      rowTotals.Totais += datum.Totais;
      aux.push(datum);
    }
    return aux;
  }, []);

  if (index.length === 1 && index[0] === 'Totais') {
    rowTotals.Totais += (+data[0][0]);
    rowTotals[keys[0]] = rowTotals.Totais;
  }

  if (globalTotalOnly) {
    return {
      ready: true,
      total: rowTotals.Totais,
      qtdElements: rows.length * keys.length,
    };
  }

  return {
    ready: true,
    rowTotals,
    rows,
    qtdElements: rows.length * keys.length,
  };
};

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a chart format.
 * Only works for `Value` charts.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @param {boolean} isValAvg Checks if the value function is average (true) or other (false).
 * @returns {{
 *  ready: boolean;
 *  value: number;
 * }} Data formatted for the chart.
 */
export const genDataValue = (rawData, config, isValAvg) => {
  const genericData = genDataRows(rawData, true);
  const valueFunction = config?.TotalFunctionControl || (isValAvg ? 'avg' : 'sum');

  return {
    ready: true,
    value: genericData.total / (valueFunction === 'avg' ? (genericData.qtdElements || 1) : 1),
  };
};

/**
 * Takes a raw array of indexes and normalizes the structure.
 * @param {array<string>|array<array<string>} rawIndexes Array of indexes from the data matrix.
 * @returns {string[][]} A array of columns names, in string.
 */
const normalizeIndexes = (rawIndexes) => {
  const unique = rawIndexes.reduce((aux, idxDt) => {
    if (typeof idxDt === 'string') aux[0].add(idxDt);
    else {
      aux[0].add(idxDt[0]);
      aux[1].add(idxDt[1]);
    }
    return aux;
  }, [new Set(), new Set()]);
  return [[...unique[0]], [...unique[1]]];
};

/**
 * Receives a specific data matrix and converts it to a `boxplot` chart format.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   uid: string,
 *   label: string,
 *   group: string,
 *   subGroup: string,
 *   n: number,
 *   sd: number,
 *   mean: number,
 *   extrema: number[],
 *   values: number[],
 *   quantiles: number[],
 *  }>;
 *  indexes: string[],
 *  subIndexes: string[],
 *  biggest: number,
 *  smallest: number;
 *  isBoxplot: boolean;
 * }} Data formatted for the chart.
 */
export const genDataBoxPlot = (rawData, config) => {
  const { index, data, columns } = rawData;
  const keys = normalizeColumns(columns);
  const [indexes, subIndexes] = normalizeIndexes(index);

  let biggest = 0;
  let smallest = 0;
  const isBoxplot = keys.length === 10 && [
    'count', 'mean', 'std', 'min', '25%', '50%', '75%', 'max', 'lower', 'upper',
  ].every((val) => keys.includes(val));

  let fData = index.reduce((aux, idx, i) => {
    if (idx === 'Totais') return aux;
    const group = typeof idx === 'string' ? idx : idx[0];
    let subGroup = '';
    if (typeof columns?.[0] === 'string') {
      subGroup = idx;
    } else if (typeof idx === 'string') {
      [[subGroup]] = columns;
    } else {
      [, subGroup] = idx;
    }
    const datum = {
      uid: group,
      label: typeof idx === 'string' ? group : subGroup,
      group,
      subGroup,
      n: 0,
      sd: 0,
      mean: 0,
      extrema: [0, 0],
      values: [0, 0, 0, 0],
      quantiles: [0.25, 0.5, 0.75],
    };
    keys.forEach((key, j) => {
      const datumVal = (+data[i][j]);
      switch (key) {
        case 'count':
          datum.n = datumVal;
          break;
        case 'std':
          datum.sd = datumVal;
          break;
        case 'min':
          datum.extrema[0] = datumVal;
          break;
        case 'max':
          datum.extrema[0] = datumVal;
          break;
        case 'lower':
          datum.values[0] = datumVal;
          smallest = Math.min(smallest, datumVal);
          break;
        case '25%':
          datum.values[1] = datumVal;
          break;
        case '50%':
          datum.values[2] = datumVal;
          break;
        case '75%':
          datum.values[3] = datumVal;
          break;
        case 'upper':
          datum.values[4] = datumVal;
          biggest = Math.max(biggest, datumVal);
          break;
        default:
          datum[key] = datumVal;
          break;
      }
    });
    aux.push(datum);
    return aux;
  }, []);

  fData = dataSort(fData, config);
  return {
    ready: true, data: fData, indexes, subIndexes, biggest, smallest, isBoxplot,
  };
};

/**
 * Receives a specific data matrix and converts it to a `scatterplot` chart format.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @returns {{
data
 * ready: boolean;
 * data: array<{
 *  id: string;
 *  uid: string,
 *  label: string,
 *  data: { x: string, y: string },
 * }>;
 * indexes: string[],
 * xMin: number,
 * xMax: number;
 * yMin: number,
 * yMax: number;
 * isScatterplot: boolean;
 * }}  Data formatted for the chart.
 */
export const genDataScatterPlot = (rawData, config) => {
  const { index, data } = rawData;
  const isScatterplot = Array.isArray(data[0]) && data[0].length === 2;

  const scales = {
    x: { min: 0, max: 0 },
    y: { min: 0, max: 0 },
  };
  let fData = [];
  let indexes = ['Valores'];
  if (typeof index[0] !== 'string') {
    fData.push({
      id: 'Valores',
      uid: 'Valores',
      label: 'Valores',
      data: data.map(([x, y]) => {
        scales.x.max = Math.max(scales.x.max, x);
        scales.x.min = Math.min(scales.x.min, x);
        scales.y.max = Math.max(scales.y.max, y);
        scales.y.min = Math.min(scales.y.min, y);
        return { x, y };
      }),
    });
  } else {
    const dataMap = index.reduce((aux, idx, i) => {
      scales.x.max = Math.max(scales.x.max, data[i][0]);
      scales.x.min = Math.min(scales.x.min, data[i][0]);
      scales.y.max = Math.max(scales.y.max, data[i][1]);
      scales.y.min = Math.min(scales.y.min, data[i][1]);
      if (aux[idx]) {
        aux[idx].data.push({ x: data[i][0], y: data[i][1] });
      } else {
        aux[idx] = {
          id: idx,
          uid: idx,
          label: idx,
          data: [{ x: data[i][0], y: data[i][1] }],
        };
      }
      return aux;
    }, {});
    fData = Object.values(dataMap);
    indexes = Object.keys(dataMap);
  }

  fData = dataSort(fData, config);
  return {
    ready: true,
    data: fData,
    indexes,
    xMin: scales.x.min,
    xMax: scales.x.max,
    yMin: scales.y.min,
    yMax: scales.y.max,
    isScatterplot,
  };
};

/**
 * Receives a specific data matrix and converts it to a sub table.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * @returns {{
 *  ready: boolean;
 *  cols: array<{
 *    field: string,
 *    label: string,
 *    sortable: boolean,
 *    valueGetter: function
 *  }>;
 *  rows: array<{
 *    string: string,
 *  }>;
 * }} Data formatted for the table.
 */
export const genDataSubset = (rawData, config) => {
  const {
    index, data, columns, types,
  } = rawData;
  const keys = normalizeColumns(columns);

  const cols = keys.map((k) => ({
    field: k,
    label: k,
    sortable: false,
    isNumeric: false,
    valueGetter: (param) => (
      config?.ValueFormatControl === 'locale' ? formatTableValue(param[k], types[k]) : param[k]
    ),
  }));

  const rows = index.map((_, i) => {
    const datum = {};
    keys.forEach((k, j) => {
      datum[k] = data[i][j];
    });
    return datum;
  });

  return { ready: true, rows, cols: dataSort(cols, config) };
};

/**
 * Receives a float value data matrix and converts it to a chart format.
 * Only works for `Histogram` chart.
 * @param {object} rawData Data matrix from the api.
 * @param {object} config Style config.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   id: string,
 *   label: string,
 *   value: number,
 *   min: number,
 *   max: number,
 *   bin: number,
 *  }>;
 *  isHistogram: boolean;
 *  keys: string,
 * }} Data formatted for the chart.
 */
export const genDataHistogram = (rawData, config) => {
  const { index, data, columns } = rawData;
  const [indexes, subIndexes] = normalizeIndexes(index);
  const keys = normalizeColumns(columns);
  const isHistogram = subIndexes.length > 0 && keys.length === 1;
  const bin = (subIndexes[subIndexes.length - 1] - indexes[0]) / index.length;
  const dataTotal = getDataTotals(index, data, columns);
  const isPercentual = config.DataFormatValueType === 'percentual';

  const fData = index.map((idx, i) => ({
    id: idx.toString(),
    label: `${indexes[i]}|${subIndexes[i]}`,
    min: +indexes[i],
    max: +subIndexes[i],
    value: isPercentual ? +(data[i][0] / dataTotal.global) : +data[i][0],
    bin,
  }));

  return {
    ready: true, data: fData, isHistogram, key: keys[0],
  };
};

/**
 * Receives a 1 dimension (value + line) or a 2 dimension (value + line + column)
 * data matrix and converts it to a chart format.
 * Only works for `Pareto` chart.
 * @param {object} rawData Data matrix from the api.
 * **Visual only:** Use only when chart orientation appears to be inverted.
 * @returns {{
 *  ready: boolean;
 *  data: array<{
 *   uid: string,
 *   label: string,
 *   value: number,
 *   nCategories: string,
 *   lineAbsolute: number,
 *   linePercent: number,
 *  }>;
 *  keys: string[],
 *  biggests: {
 *    global: number,
 *    categories: number,
 *  }
 *  hasNegative: boolean;
 * }} Data formatted for the chart.
 */
export const genParetoData = (rawData) => {
  const config = {
    DataFormat: { type: 'absolute', target: 'global' },
    SortValuesControl: 'Valor Decrescente',
    CustomSortControl: { checked: false },
  };
  const barData = genData2DWithKeys(rawData, config, false);
  let cumulative = 0;
  const data = barData.data.map((dt) => {
    cumulative += dt.value;
    dt.lineAbsolute = cumulative;
    dt.linePercent = cumulative / barData.total;
    return dt;
  });
  return { ...barData, data };
};
