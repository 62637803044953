import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../juristec-ui/core/SelectNew';
import {
  Container, Title, InfoContainer, InfoBold, InfoText,
  ErrorBold,
} from './styled/DeleteUser.styled';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';

const DeleteUser = ({
  user, instanceUsers, instance, handleGetInstanceUsers, theme, submitDelete, hide,
}) => {
  const [users, setUsers] = useState(instanceUsers);
  const [userToTransfer, setUserToTransfer] = useState({
    value: 'Selecione',
    label: 'Selecione',
    id: 'select-label',
  });

  useEffect(() => {
    const filterAndAddNoneOption = (usersList) => {
      const filteredUsers = usersList.filter(
        (u) => u.email !== user.email && u.role !== 'guest',
      );
      if (!filteredUsers.some((u) => u.email === '')) {
        filteredUsers.push({
          email: '', label: 'Nenhum', value: '', id: 'none',
        });
      }
      return filteredUsers;
    };

    const fetchInstanceUsers = async () => {
      if (handleGetInstanceUsers && instance) {
        const fetchedUsers = await handleGetInstanceUsers(instance);
        setUsers(filterAndAddNoneOption(fetchedUsers));
      }
    };

    const initialUsers = filterAndAddNoneOption(instanceUsers);
    setUsers(initialUsers);

    if (initialUsers.length === 1 && handleGetInstanceUsers && instance) {
      fetchInstanceUsers();
    }
  }, [instance, instanceUsers, handleGetInstanceUsers, user.email]);

  const handleSubmit = () => {
    submitDelete(userToTransfer?.email);
    hide();
  };
  return (
    <Container>
      <Title>
        Tem certeza que deseja excluir o usuário
        {' '}
        {user?.name || user?.displayName}
        ?
      </Title>
      <Select
        options={users.map((u) => (
          {
            value: u.email, label: u.email, id: u.user_id, ...u,
          }
        ))}
        onChange={setUserToTransfer}
        value={userToTransfer}
        atModal
        searchable
        label="Selecione um usuário para transferir os arquivos e dashboards"
        placeholder="Selecione o usuário para transferir os arquivos"
      />
      <InfoContainer>
        <ErrorBold theme={theme}>Atenção: </ErrorBold>
        {userToTransfer.id !== 'select-label' ? (
          <InfoText>
            Os arquivos e dashboards pertencentes ao usuário
            {' '}
            <InfoBold theme={theme}>{user?.name || user?.displayName}</InfoBold>
            {' '}
            {userToTransfer?.id === 'none' ? (
              'não poderão ser recuperados! Você tem certeza que deseja removê-lo mesmo assim?'
            ) : (
              <>
                serão transferidos para o usuário
                {' '}
                <InfoBold theme={theme}>
                  {userToTransfer?.name || userToTransfer?.displayName}
                </InfoBold>
                ! Você tem certeza que deseja removê-lo mesmo assim?
              </>
            )}
          </InfoText>
        ) : (
          <InfoText>
            Selecione um usuário para transferir os arquivos e dashboards
            do usuário
            {' '}
            <InfoBold theme={theme}>{user?.name || user?.displayName}</InfoBold>
          </InfoText>
        )}
      </InfoContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          size="large"
          disabled={userToTransfer?.id === 'select-label'}
        >
          Apagar
        </Button>
      </ActionsGroup>
    </Container>
  );
};

DeleteUser.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.string,
    }),
    name: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
  }),
  instanceUsers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
  })).isRequired,
  handleGetInstanceUsers: PropTypes.func,
  theme: PropTypes.objectOf(PropTypes.string).isRequired,
  submitDelete: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

DeleteUser.defaultProps = {
  instance: null,
  handleGetInstanceUsers: null,
};

export default DeleteUser;
