/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { customValueTooltip, staticPercent } from '../formatCharts/handleCustomValue';

import {
  CustomTooltipContainer,
  ColorLabelSymbol,
  TooltipColumn,
  TooltipRow,
  DrilldownTip,
} from './styled/KpiCreate.styled';

const BarTooltip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.id}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {tData.id} - {tData.indexValue}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
        </span>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const LineToolTip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.serieId}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {tData.serieId} - {tData.data.x}: <strong>{customValueTooltip(tData.data.y, styleConfig)}</strong>
        </span>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const PieToolTip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.label + tData.value}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {tData.label}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
        </span>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const RadarToolTip = ({ tData, styleConfig }) => {
  const auxData = tData?.data;
  auxData.sort((a, b) => a.id.localeCompare(b.id));
  return (
    auxData.map((item) => (
      <CustomTooltipContainer key={item.index + item.id}>
        <ColorLabelSymbol color={item.color} />
        <span>
          {item.id} - {tData.index}: <strong>{customValueTooltip(item.value, styleConfig)}</strong>
        </span>
      </CustomTooltipContainer>
    )));
};

const MapTooltip = ({ tData, styleConfig }) => (
  tData.feature.data
    ? (
      <CustomTooltipContainer key={tData.feature.label + tData.feature.value}>
        <ColorLabelSymbol color={tData.feature.color} />
        <span>
          {tData.feature.label}: <strong>{customValueTooltip(tData.feature.value, styleConfig)}</strong>
        </span>
      </CustomTooltipContainer>
    )
    : <></>
);

const RadialBarTooltip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.groupId}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {tData.groupId} - {tData.data.x}: <strong>{customValueTooltip(tData.data.y, styleConfig)}</strong>
        </span>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const CalendarTooltip = ({ tData, styleConfig }) => {
  const fDate = (d) => new Date(`${d} 00:00`).toLocaleDateString('pt-br');
  return (
    <CustomTooltipContainer key={tData.id}>
      <ColorLabelSymbol color={tData.color} />
      <span>
        {fDate(tData.day)}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
      </span>
    </CustomTooltipContainer>
  );
};

const FunnelTooltip = ({ tData, styleConfig, hasDrilldown }) => {
  const dt = tData.part;
  return (
    <CustomTooltipContainer key={dt.data.label + dt.data.value}>
      <TooltipColumn>
        <TooltipRow>
          <ColorLabelSymbol color={dt.color} />
          <span>
            {dt.data.label}: <strong>{customValueTooltip(dt.data.value, styleConfig)}</strong>
          </span>
        </TooltipRow>
        {hasDrilldown && (
          <DrilldownTip>
            Clique para ver mais detalhes
          </DrilldownTip>
        )}
      </TooltipColumn>

    </CustomTooltipContainer>
  );
};

const WaffleTooltip = ({ tData, styleConfig }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  }}
  >
    <ColorLabelSymbol color={tData.color} />
    <span>
      {tData.label}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
    </span>
  </div>
);

const BoxPlotTooltip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.label} style={{ flexDirection: 'column' }}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span style={{ marginRight: 'auto' }}>
          {tData.subGroup !== tData.group ? `${tData.subGroup} - ` : ''}
          {tData.group}
        </span>
      </TooltipRow>
      <TooltipRow>
        <TooltipColumn>
          <span>
            Quantidade: <strong>{tData.data.n}</strong>
          </span>
          <span>
            Mínimo: <strong>{customValueTooltip(tData.data.extrema[1], styleConfig)}</strong>
          </span>
          <span>
            Média: <strong>{customValueTooltip(tData.data.mean, styleConfig)}</strong>
          </span>
          <span>
            Máximo: <strong>{customValueTooltip(tData.data.extrema[0], styleConfig)}</strong>
          </span>
          <span>
            Desvio: <strong>{customValueTooltip(tData.data.sd, styleConfig)}</strong>
          </span>
        </TooltipColumn>
        <TooltipColumn>
          <span>
            Limite Inferior: <strong>{customValueTooltip(tData.data.values[0], styleConfig)}</strong>
          </span>
          <span>
            1° Quartil: <strong>{customValueTooltip(tData.data.values[1], styleConfig)}</strong>
          </span>
          <span>
            Mediana: <strong>{customValueTooltip(tData.data.values[2], styleConfig)}</strong>
          </span>
          <span>
            3° Quartil: <strong>{customValueTooltip(tData.data.values[3], styleConfig)}</strong>
          </span>
          <span>
            Limite Superior: <strong>{customValueTooltip(tData.data.values[4], styleConfig)}</strong>
          </span>
        </TooltipColumn>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const ScatterPlotTooltip = ({ tData, styleConfig, hasDrilldown }) => (
  <CustomTooltipContainer key={tData.node.id}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.node.color} />
        <span>
          {`${tData.node.serieId}: `}
          <strong>
            {`X: ${customValueTooltip(tData.node.xValue, styleConfig)}, `}
            {`Y: ${customValueTooltip(tData.node.yValue, styleConfig)}`}
          </strong>
        </span>
      </TooltipRow>
      {hasDrilldown && (
        <DrilldownTip>
          Clique para ver mais detalhes
        </DrilldownTip>
      )}
    </TooltipColumn>
  </CustomTooltipContainer>
);

const HistogramTooltip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.datum.id}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {`]${customValueTooltip(tData.datum.data.min, styleConfig)}; `}
          {`${customValueTooltip(tData.datum.data.max, styleConfig)}]: `}
          <strong>
            {styleConfig.DataFormatValueType === 'percentual' ? (
              staticPercent(tData.datum.data.value, styleConfig.InteractiveDecimals, styleConfig.InteractiveSeparadorNumericoCasasPercentuais)
            ) : tData.datum.data.value}
          </strong>
        </span>
      </TooltipRow>
    </TooltipColumn>
  </CustomTooltipContainer>
);

const ParetoTooltip = ({ tData, styleConfig }) => (
  <CustomTooltipContainer key={tData.id}>
    <TooltipColumn>
      <TooltipRow>
        <ColorLabelSymbol color={tData.color} />
        <span>
          {tData.id} - {tData.indexValue}: <strong>{customValueTooltip(tData.value, styleConfig)}</strong>
        </span>
      </TooltipRow>
      <TooltipRow>
        <span>
          {'Acumulado: '}
          <strong>
            {`${customValueTooltip(tData.data.lineAbsolute ?? 0, styleConfig)} `}
            {`(${staticPercent(tData.data.linePercent, styleConfig?.InteractiveDecimals, styleConfig?.InteractiveSeparadorNumericoCasasPercentuais)})`}
          </strong>
        </span>
      </TooltipRow>
    </TooltipColumn>
  </CustomTooltipContainer>
);

const customTooltip = (tData, type, styleConfig, hasDrilldown) => {
  switch (type) {
    case 'bar': return BarTooltip({ tData, styleConfig, hasDrilldown });
    case 'line': return LineToolTip({ tData, styleConfig, hasDrilldown });
    case 'pie': return PieToolTip({ tData: tData.datum, styleConfig, hasDrilldown });
    case 'radar': return RadarToolTip({ tData, styleConfig });
    case 'map': return MapTooltip({ tData, styleConfig });
    case 'radialbar': return RadialBarTooltip({ tData: tData.bar, styleConfig, hasDrilldown });
    case 'calendar': return CalendarTooltip({ tData, styleConfig });
    case 'waffle': return WaffleTooltip({ tData, styleConfig });
    case 'funnel': return FunnelTooltip({ tData, styleConfig, hasDrilldown });
    case 'boxplot': return BoxPlotTooltip({ tData, styleConfig, hasDrilldown });
    case 'scatterplot': return ScatterPlotTooltip({ tData, styleConfig, hasDrilldown });
    case 'histogram': return HistogramTooltip({ tData: tData.bar, styleConfig });
    case 'pareto': return ParetoTooltip({ tData, styleConfig });
    default: return null;
  }
};

export default customTooltip;
