import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Tooltip from '../../juristec-ui/core/Tooltip';
import IconButton from '../../juristec-ui/core/IconButton';

import { Container, CarouselButton, CarouselContainer } from './styled/KpiCarousel.styled';

import {
  ChartValue,
  ChartPie,
  ChartLine,
  ChartPareto,
  ChartBar,
  ChartRadar,
  ChartWorldMap,
  ChartFunnel,
  ChartTable,
  ChartRadialBar,
  ChartCalendar,
  ChartWaffle,
  ChartBoxPlot,
  ExpandMoreWide,
  ChartScatterPlot,
  ChartHistogram,
} from '../../juristec-ui/icons';

const KpiCarousel = ({
  selectedChartType, setChartType, disabled, hideList,
}) => {
  const [showControls, setShowControls] = useState(false);
  const carouselRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    const handleResize = () => {
      if (carouselRef?.current?.scrollWidth > carouselRef?.current?.offsetWidth) {
        setShowControls(true);
      } else {
        setShowControls(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = (side) => {
    if (carouselRef.current) {
      if (side === 'right') {
        carouselRef.current.scrollLeft = (
          carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
        );
      } else {
        carouselRef.current.scrollLeft = 0;
      }
    }
  };

  return (
    <Container>
      {showControls && (
        <CarouselButton className="left-arrow" onClick={() => handleScroll('left')}><ExpandMoreWide /></CarouselButton>
      )}
      <CarouselContainer id="kpiType" showControls={showControls} ref={carouselRef}>
        {hideList.includes('Table') || (
          <Tooltip text="Tabela" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Table')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Table' ? 'active' : ''}`}
            >
              <ChartTable primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Value') || (
          <Tooltip text="Valor" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Value')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Value' ? 'active' : ''}`}
            >
              <ChartValue primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Bar') || (
          <Tooltip text="Barra" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Bar')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Bar' ? 'active' : ''}`}
            >
              <ChartBar primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {/* Comentando o gráfico de Histograma para verificar a alteração dos planos */}
        {/* {hideList.includes('Histogram') || (
          <Tooltip text="Histograma" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Histogram')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Histogram' ? 'active' : ''}`}
            >
              <ChartHistogram primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )} */}

        {/* Comentando o gráfico de Pareto para verificar a alteração dos planos */}
        {/* {hideList.includes('Pareto') || (
          <Tooltip text="Pareto" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Pareto')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Pareto' ? 'active' : ''}`}
            >
              <ChartPareto primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )} */}

        {hideList.includes('Value') || (
          <Tooltip text="Linha" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Line')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Line' ? 'active' : ''}`}
            >
              <ChartLine primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Pie') || (
          <Tooltip text="Pizza" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Pie')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Pie' ? 'active' : ''}`}
            >
              <ChartPie primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Radar') || (
          <Tooltip text="Radar" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Radar')}
              className={`kpi-carousel-charts-btns radar ${selectedChartType === 'Radar' ? 'active' : ''}`}
            >
              <ChartRadar primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Map') || (
          <Tooltip text="Geográfico" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Map')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Map' ? 'active' : ''}`}
            >
              <ChartWorldMap primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Funnel') || (
          <Tooltip text="Funil" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Funnel')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Funnel' ? 'active' : ''}`}
            >
              <ChartFunnel primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('RadialBar') || (
          <Tooltip text="Barra Radial" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('RadialBar')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'RadialBar' ? 'active' : ''}`}
            >
              <ChartRadialBar primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Calendar') || (
          <Tooltip text="Calendário" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Calendar')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Calendar' ? 'active' : ''}`}
            >
              <ChartCalendar primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('Waffle') || (
          <Tooltip text="Waffle" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('Waffle')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'Waffle' ? 'active' : ''}`}
            >
              <ChartWaffle primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}

        {hideList.includes('BoxPlot') || (
          <Tooltip text="Diagrama de Caixa" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('BoxPlot')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'BoxPlot' ? 'active' : ''}`}
            >
              <ChartBoxPlot primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}
        {hideList.includes('ScatterPlot') || (
          <Tooltip text="Diagrama de Dispersão" className={disabled ? 'disabled' : ''}>
            <IconButton
              disabled={disabled}
              shape="rounded"
              color="n"
              variant="contained"
              size="large"
              onClick={() => setChartType('ScatterPlot')}
              className={`kpi-carousel-charts-btns ${selectedChartType === 'ScatterPlot' ? 'active' : ''}`}
            >
              <ChartScatterPlot primary={theme.primary} secondary={theme.secondary} />
            </IconButton>
          </Tooltip>
        )}
      </CarouselContainer>
      {showControls && (
        <CarouselButton className="right-arrow" onClick={() => handleScroll('right')}><ExpandMoreWide /></CarouselButton>
      )}
    </Container>
  );
};

KpiCarousel.propTypes = {
  selectedChartType: PropTypes.string,
  disabled: PropTypes.bool,
  setChartType: PropTypes.func,
  hideList: PropTypes.arrayOf(PropTypes.string),
};

KpiCarousel.defaultProps = {
  selectedChartType: '',
  disabled: false,
  setChartType: () => {},
  hideList: [],
};

export default KpiCarousel;
