import styled, { css } from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormContainer = styled(Column)`
  height: 55vh;
  width: 75vw;
  padding-left: 0.5rem;
`;

export const MainContainer = styled(Row)``;

export const InfoLabels = styled(Row)`
  color: ${({ theme }) => theme.color};
  font-size: 12px;
  & span:last-child {
    margin-left: auto;
  }
`;

export const InputContainer = styled(Column)`
  background-color: ${({ theme }) => theme.container};
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  & > span {
    font-size: 14px;
  }
  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}
`;

export const FileInfo = styled(Row)`
  padding: 5px;
  background-color: ${({ theme }) => theme.container};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.containerHover};
  justify-content: center;
  align-items: center;
  gap: 5px;
    
  & svg {
    height: 20px;
    fill: ${({ theme }) => theme.primary};
  }
`;

export const TabGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  width: 22vw;
  height: calc(55vh - 100px);
  & button {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  overflow-y: auto; 
`;

export const TabButton = styled.button`
  background-color: ${({ theme }) => theme.container};
  width: 100%;
  height: 45px;
  font-weight: bold;
  border: none;
  box-sizing: border-box;
  color: ${({ theme }) => theme.primary};
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  ${({ theme, active }) => (active ? css`
    background-color: ${theme.secondary};
    color: ${theme.white};
    & svg {
      fill: ${theme.white};
    }
    & .icon-detail svg {
      fill: ${theme.white};
    }
  ` : css`
    &:hover {
      background-color: ${theme.selectHover};
      & .icon-detail svg {
        fill: ${theme.selectHover};
      }
    }
  `)}
`;

export const TabGroupTitle = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  & svg {
    fill: ${({ theme }) => theme.primary};
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  & span {
    margin: auto;
  }
  & .icon-detail {
    transform: rotate(270deg);
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      fill: ${({ theme }) => theme.container};
      margin: 0;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55vh;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & .select-wrapper {
    max-width: 50px;
  }
  & > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  & .code-textarea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.inputBottomLine};
    border-radius: 5px;
  }
  & .extra-label {
    color: ${({ theme }) => theme.inputLabel};
    margin-left: auto;
  }
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    pointer-events: none;
  `}
`;

export const TextAreaContainer = styled.div`
    ${({ disabled }) => disabled && css`
      user-select: none;
      pointer-events: none;
      opacity: 0.3;
    `}
    & > .textAreaContainer {
    width: 100 %;
    height: 150px;
      & textarea {
      width: 100 %;
      font - size: 14px;
    }
      & .textAreaBar {
      bottom: 1px;
    }
}
`;

export const CreateETLButton = styled.button`
  background-color: ${({ theme, active }) => (active ? theme.secondary : theme.primary)};
  &:hover {
    background-color: ${({ theme, active }) => (!active ? theme.selectHover : theme.secondary)};
    & .icon-detail svg {
      fill: ${({ theme }) => theme.selectHover};
    }
  }
  color: ${({ theme }) => theme.white};
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  ${({ disabled }) => disabled && css`
    background-color: ${({ theme }) => theme.grey};
    cursor: not-allowed;
  `}
`;
