/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable/DataTable';
import IconButton from '../../juristec-ui/core/IconButton';
import Select from '../../juristec-ui/core/Select';
import Tooltip from '../../juristec-ui/core/Tooltip';
import TableCellPop from '../../juristec-ui/core/TableCellPop';
import TablePagination from '../../juristec-ui/core/TablePagination';
import TableFilters from '../Popovers/TableFilters';
import Popover from '../../juristec-ui/core/Popover';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import Badge from '../../juristec-ui/core/Badge';

import originIcon, {
  getOriginOptions,
  getConnectorOptions,
  getOriginOptionsSupport,
  getConnectorOptionsSupport,
} from '../../juristec-ui/utils/originMap';

import { printSize, formatDateTime } from '../../juristec-ui/utils/functions/lab';
import compare from '../../utils/functions/sorting';

import {
  Trash, UpdateError, UpdateSuccess, UpdateWarning, UpdateInactive, TransferUser, Columns,
  NewColumn, Reverse, MoreVert, LegalOne, Code,
} from '../../juristec-ui/icons';

import {
  MainContainer, TableOverFlow, IconWrapper, OwnerLabel, FooterContainer, Options,
  EtlAndCustomColumns,
} from './styled/ReportsTable.styled';
import ClockReverse from '../../juristec-ui/icons/ClockReverse';

const ReportsTable = ({
  reports,
  instances,
  //
  getReports,
  openReportETLs,
  deleteReport,
  resetReport,
  changeReportOwner,
  openReportStatus,
  openInactiveReportStatus,
  openReportColumns,
  openCustomColumns,
  convertToGed,
  resetReportAttempts,
  //
  userIsAdmin,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageQty, setPageQty] = useState(25);
  const [filters, setFilters] = useState({
    origin: { isOpen: false, selected: [], isDirty: false },
    status: { isOpen: false, selected: [], isDirty: false },
    connector: { isOpen: false, selected: [], isDirty: false },
    companyId: { isOpen: false, selected: [], isDirty: false },
    filename: { isOpen: false, selected: '', isDirty: false },
    inactive: { isOpen: false, selected: false, isDirty: false },
  });

  useEffect(() => {
    const fetchList = async () => {
      await getReports(page, filters, pageQty, false);
    };
    if (!reports.list || !reports.list[page]) {
      fetchList();
    }
  }, [page, filters]);

  useEffect(() => {
    setPage(0);
    const fetchList = async () => {
      await getReports(0, filters, pageQty, true);
    };
    if (filters.origin.isDirty
      || filters.status.isDirty
      || filters.connector.isDirty
      || filters.companyId.isDirty
      || filters.filename.isDirty
      || filters.inactive.isDirty
    ) {
      fetchList();
    }
  }, [
    filters.origin.selected,
    filters.status.selected,
    filters.connector.selected,
    filters.companyId.selected,
    filters.filename.selected,
    filters.inactive.selected,
  ]);

  const handlePageQty = async (selected) => {
    setPageQty(selected.value);
    setPage(0);
    await getReports(0, filters, selected.value, true);
  };

  /**
   * Sets status icon with related status
   * @param {object} report report info
   * @returns An icon with the corresponding status
   */
  const getStatusBadge = (report) => {
    const fetchList = async () => {
      setPage(0);
      await getReports(0, filters, pageQty, true);
    };
    const openInfo = () => openReportStatus(report, fetchList);

    if (!report.active) {
      if (report.deletedAt) {
        return (
          <IconButton color="gray" onClick={() => openInactiveReportStatus(report, true, fetchList)}>
            <UpdateError />
          </IconButton>
        );
      } return (
        <IconButton color="gray" onClick={() => openInactiveReportStatus(report, false, fetchList)}>
          <UpdateInactive />
        </IconButton>
      );
    }

    switch (report.status) {
      case 'ERROR':
        return (
          <IconButton color="error" onClick={openInfo}>
            <UpdateError />
          </IconButton>
        );
      case 'WARNING':
        return (
          <IconButton color="warning" onClick={openInfo}>
            <UpdateWarning />
          </IconButton>
        );
      default:
        return (
          <IconButton color="success" onClick={openInfo}>
            <UpdateSuccess />
          </IconButton>
        );
    }
  };

  const ReportsOptions = ({ report }) => {
    const getDeleteHandler = () => {
      const fetchList = async () => {
        setPage(0);
        await getReports(0, filters, pageQty, true);
      };
      deleteReport(report, fetchList);
    };

    const getChangeOwner = () => {
      const fetchList = async () => {
        setPage(0);
        await getReports(0, filters, pageQty, true);
      };
      changeReportOwner(report, fetchList);
    };

    return (
      <Options>
        <Tooltip text="Alterar proprietário">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getChangeOwner}>
            <TransferUser />
          </IconButton>
        </Tooltip>
        <Tooltip text="Apagar relatório">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDeleteHandler}>
            <Trash />
          </IconButton>
        </Tooltip>
      </Options>
    );
  };

  ReportsOptions.propTypes = {
    report: PropTypes.shape({}).isRequired,
  };

  const ReportsMoreOptions = ({ report }) => {
    const [showOptions, setShowOptions] = useState(false);

    const togglePopover = (e) => {
      e.stopPropagation();
      setShowOptions((o) => !o);
    };

    const fetchLists = async () => {
      setPage(0);
      await getReports(0, filters, pageQty, true);
    };

    const getReportColumns = () => {
      openReportColumns(report, fetchLists);
    };

    const getCustomColumns = () => {
      openCustomColumns(report, fetchLists);
    };

    const getResetHandler = () => {
      resetReport(report, fetchLists);
    };

    const getResetAttemptsHandler = () => {
      resetReportAttempts(report, fetchLists);
    };

    const getConvertToGed = () => {
      convertToGed(report, fetchLists);
    };

    const openETLsModal = async () => {
      const { data } = await getReports(
        0,
        { ...filters, companyId: { isOpen: false, selected: [report.companyId], isDirty: false } },
        3000, false, true,
      );
      const companyReports = data.reports.map((r) => (
        { label: r?.filename, value: r?.id, id: r?.id }
      ));
      openReportETLs(report, companyReports);
    };

    return (
      <Popover closePopover={() => setShowOptions(false)} open={showOptions}>
        <Popover.Action>
          <Tooltip text="Mais opções">
            <IconButton variant="pattern" shape="rounded" onClick={togglePopover}>
              <MoreVert aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
            </IconButton>
          </Tooltip>
        </Popover.Action>
        <Popover.Content>
          <List>
            {process.env.REACT_APP_FIREBASE_PROJECT_LABEL === 'legalone-analytics'
            && (report.connector === 'Mail' || report.connector === 'mail') && (
              <ListItem onClick={getConvertToGed}>
                <LegalOne color={theme.grey} width="24px" heigth="24px" />
                Converter para API GED
              </ListItem>
            )}
            <ListItem onClick={getReportColumns}>
              <Columns />
              Tipagem de Colunas
            </ListItem>
            <ListItem onClick={getCustomColumns}>
              <NewColumn width="24px" height="24px" />
              Colunas Customizadas
            </ListItem>
            <ListItem onClick={getResetHandler}>
              <Reverse width="24px" height="24px" />
              Resetar Relatório
            </ListItem>
            <ListItem onClick={getResetAttemptsHandler}>
              <ClockReverse width="24px" height="24px" />
              Resetar últimas atualizações
            </ListItem>
            <ListItem onClick={() => openETLsModal()}>
              <Code width="24px" height="24px" />
              Criar/Editar ETL
            </ListItem>
          </List>
        </Popover.Content>
      </Popover>
    );
  };

  ReportsMoreOptions.propTypes = {
    report: PropTypes.shape({
      origin: PropTypes.string,
      connector: PropTypes.string,
      hasEtl: PropTypes.bool,
      companyId: PropTypes.string,
    }).isRequired,
  };

  /**
   * Find company name by company id
   * @param {string} companyId Company unique identifier
   * @returns {string} Company name
   */
  const getCompanyName = (companyId) => {
    const company = instances.find((ins) => ins.id === companyId);
    return company?.name || companyId;
  };

  const getCompaniesOpt = () => {
    const temp = instances.map((ins) => ({
      id: ins.id, label: ins.name, value: ins.id,
    }));
    return temp.sort((a, b) => (compare(a.label, b.label)));
  };

  const toggleFilter = (field, state) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        isOpen: state !== undefined ? state : !f[field].isOpen,
      },
    }));
  };

  const handleFilter = (field, selected) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        selected,
        isDirty: true,
      },
    }));
  };

  const checkEtl = (hasETL) => {
    if (hasETL) {
      return (
        <Badge size="small" color={theme.success}>
          ETL
        </Badge>
      );
    }
    return null;
  };

  const checkCustomColumns = (hasCustomColumns) => {
    if (hasCustomColumns) {
      return (
        <Badge size="small" color={theme.info}>
          Col. Cust.
        </Badge>
      );
    }
    return null;
  };

  /** Arrangement of table columns */
  const columns = useMemo(() => [
    {
      field: 'origin',
      label: 'Origem',
      valueGetter: (param) => <IconWrapper>{originIcon(param.origin)}</IconWrapper>,
      tools: (
        <TableFilters
          field="origin"
          isOpen={filters?.origin?.isOpen || false}
          toggle={toggleFilter}
          options={userIsAdmin ? getOriginOptions : getOriginOptionsSupport}
          selected={filters?.origin?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'connector',
      label: 'Conector',
      valueGetter: (param) => getConnectorOptions.find(
        (element) => element.value === param.connector,
      ).label,
      tools: (
        <TableFilters
          field="connector"
          isOpen={filters?.connector?.isOpen || false}
          toggle={toggleFilter}
          options={userIsAdmin ? getConnectorOptions : getConnectorOptionsSupport}
          selected={filters?.connector?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'companyId',
      label: 'Empresa',
      valueGetter: (param) => <TableCellPop text={getCompanyName(param.companyId)} />,
      tools: (
        <TableFilters
          field="companyId"
          isOpen={filters?.companyId?.isOpen || false}
          toggle={toggleFilter}
          options={getCompaniesOpt() || []}
          selected={filters?.companyId?.selected || []}
          handleFilter={handleFilter}
          popDirection="bottom-start"
          isRadio
        />
      ),
    },
    {
      field: 'filename',
      label: 'Arquivo',
      valueGetter: (param) => (
        <>
          <TableCellPop text={param.filename} />
          <OwnerLabel>
            {param?.ownerEmail ? <TableCellPop text={param.ownerEmail} /> : 'Usuário não encontrado'}
          </OwnerLabel>
          <EtlAndCustomColumns>
            {checkEtl(param.hasEtl)}
            {' '}
            {checkCustomColumns(param.hasCustomColumns)}
          </EtlAndCustomColumns>
        </>
      ),
      tools: (
        <TableFilters
          field="filename"
          isOpen={filters?.filename?.isOpen || false}
          toggle={toggleFilter}
          selected={filters?.filename?.selected || ''}
          handleFilter={handleFilter}
          isText
        />
      ),
    },
    {
      field: 'size',
      label: 'Tamanho',
      valueGetter: (param) => (param.size ? printSize(param.size).replace('.', ',') : '-'),
      sortable: true,
    },
    {
      field: 'updatedAt',
      label: 'Última atualização',
      valueGetter: (param) => formatDateTime(param.updatedAt, { time: 'half' }),
    },
    {
      field: 'status',
      label: 'Status',
      valueGetter: (param) => getStatusBadge(param),
      tools: (
        <TableFilters
          field="status"
          isOpen={filters?.status?.isOpen || false}
          toggle={toggleFilter}
          options={[
            { id: 'status_success', label: 'Sucesso', value: 'SUCCESS' },
            { id: 'status_warning', label: 'Atenção', value: 'WARNING' },
            { id: 'status_error', label: 'Erro', value: 'ERROR' },
          ]}
          extraToggle={{
            label: 'Exibir apenas inativos',
            value: filters?.inactive?.selected || false,
            field: 'inactive',
          }}
          selected={filters?.status?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    userIsAdmin ? {
      field: 'options',
      label: 'Opções',
      valueGetter: (param) => <ReportsOptions report={param} />,
    } : {},
    userIsAdmin ? {
      field: 'MoreOptions',
      label: '',
      valueGetter: (param) => <ReportsMoreOptions report={param} />,
    } : {},
  ], [reports, filters]);

  return (
    <MainContainer>
      <TableOverFlow>
        <DataTable
          columns={columns}
          rowData={reports.list[page] || []}
          defaultSortField="updatedAt"
          defaultSortOrder="descending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme.background,
          }}
        />
      </TableOverFlow>
      <FooterContainer>
        <TablePagination
          page={page}
          setPage={setPage}
          totalPages={reports.pageTotal - 1}
          useGoToPage
        />
        <span style={{ marginLeft: '1rem' }}>
          Itens por página:
        </span>
        <Select
          value={{ label: pageQty, value: pageQty }}
          onChange={handlePageQty}
          options={[
            { label: '25', id: '25-itens', value: 25 },
            { label: '50', id: '50-itens', value: 50 },
            { label: '100', id: '100-itens', value: 100 },
          ]}
          style={{
            marginLeft: '1rem',
            minWidth: '50px',
            width: '60px',
          }}
        />
      </FooterContainer>
    </MainContainer>
  );
};

ReportsTable.propTypes = {
  /** Array of reports info */
  reports: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    pageTotal: PropTypes.number,
  }),
  /** Array of instances */
  instances: PropTypes.arrayOf(PropTypes.shape({})),
  /** Requests all the reports */
  getReports: PropTypes.func,
  /** Gets all etls from report */
  openReportETLs: PropTypes.func,
  /** Handles the removal of a report */
  deleteReport: PropTypes.func,
  /** Handles the change of report owner */
  changeReportOwner: PropTypes.func,
  /** Handles the modal with report info */
  openReportStatus: PropTypes.func,
  /** Handles the modal with inactive report info */
  openInactiveReportStatus: PropTypes.func,
  /** Handles the modal with report columns */
  openReportColumns: PropTypes.func,
  /** Handles the modal with report custom columns */
  openCustomColumns: PropTypes.func,
  /** Handles the resetting of a report */
  resetReport: PropTypes.func,
  /** Handles the convertion of a report from Mail to API GED */
  convertToGed: PropTypes.func,
  /** Handles the resetting of a report attempts */
  resetReportAttempts: PropTypes.func,
  /** Checks if the user has admin role */
  userIsAdmin: PropTypes.bool,
};

ReportsTable.defaultProps = {
  reports: null,
  instances: [],
  getReports: () => {},
  openReportETLs: () => {},
  deleteReport: () => {},
  changeReportOwner: () => {},
  openReportStatus: () => {},
  openInactiveReportStatus: () => {},
  openReportColumns: () => {},
  openCustomColumns: () => {},
  resetReport: () => {},
  convertToGed: () => {},
  resetReportAttempts: () => {},
  userIsAdmin: false,
};

export default ReportsTable;
