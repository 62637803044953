/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

import customTooltip from '../../utils/chartTools/handleCustomTooltip';
import colorSelector from '../../utils/formatCharts/handleColor';
import customAxis, { customPercentAxisRight } from '../../utils/formatCharts/formatAxis';
import { dataSort, genParetoData } from '../../utils/formatCharts/handleData';
import { getBiggestTick } from '../../utils/formatCharts/getAxisTicks';
import { customValueLabel } from '../../utils/formatCharts/handleCustomValue';
import ExtraLine from '../../utils/chartTools/ExtraLine';
import KpiStatus from '../../utils/chartTools/KpiStatus';

const Pareto = ({
  data, styleConfig, isEdit, globalTheme,
}) => {
  const [chartData, setChartData] = useState({ ready: false });
  useEffect(() => {
    setChartData(genParetoData(data));
  }, [
    data,
    styleConfig.DataFormat.type,
    styleConfig.DataFormat.target,
  ]);

  useEffect(() => {
    if (chartData.ready) {
      setChartData((cData) => ({
        ...cData,
        data: dataSort(chartData.data, styleConfig),
      }));
    }
  }, [
    ...styleConfig.CustomSortControl.kpiCategories,
    styleConfig.SortValuesControl,
  ]);

  const biggestTick = useMemo(() => (
    getBiggestTick(chartData?.total ?? 0, styleConfig)
  ), [chartData, styleConfig.GroupModeControl]);

  const dataFrom = () => {
    if (styleConfig?.ColorByControl === 'keys') return 'id';
    if (styleConfig?.ColorByControl === 'indexes') return 'indexValue';
    if (chartData.keys.length > 1) return 'id';
    return 'indexValue';
  };

  const createLabel = (label) => {
    const val = customValueLabel(label.value, styleConfig);
    if (styleConfig.LabelFormatControl === 'Central') return val;
    return <tspan y={-10}>{val}</tspan>;
  };

  const createTooltip = (val) => customTooltip(val, 'pareto', styleConfig);

  const ExtraInfo = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ExtraLine {...props} tooltip={createTooltip} styleConfig={styleConfig} />
  );

  return chartData.ready ? (
    <KpiStatus
      conditions={new Map([
        [
          'noLineSelected',
          data?.index?.[0] === 'Totais',
        ],
      ])}
    >
      <ResponsiveBar
        data={chartData.data}
        keys={chartData.keys}
        indexBy="label"
        valueScale={{ type: styleConfig.ValueScaleControl || 'linear' }}
        /* GENERAL */
        margin={styleConfig?.Margin || {
          top: 50,
          right: 145,
          bottom: 55,
          left: 60,
        }}
        groupMode={styleConfig.GroupModeControl || 'stacked'}
        layout={styleConfig.LayoutControl || 'vertical'}
        reverse={!!styleConfig?.ReverseControl?.checked || false}
        minValue={styleConfig?.MinValueControl?.checked ? 'auto' : styleConfig?.MinValueControl?.value}
        maxValue={styleConfig.MaxValueControl?.checked
          ? biggestTick : styleConfig.MaxValueControl?.value}
        padding={styleConfig?.PaddingControl?.value ?? 0.1}
        innerPadding={styleConfig?.InnerPaddingControl?.value ?? 0}
        /* BORDER */
        borderRadius={styleConfig?.BorderRadiusControl?.value ?? 5}
        borderWidth={styleConfig?.BorderWidthControl?.value ?? 0}
        borderColor={
          styleConfig.BorderColorControl
            ? styleConfig.BorderColorControl
            : { from: 'color', modifiers: [['darker', 1.6]] }
        }
        /* LABEL */
        enableLabel={styleConfig.EnableLabelControl ? styleConfig.EnableLabelControl.checked : true}
        labelSkipWidth={styleConfig.labelSkipWidth || 0}
        labelSkipHeight={styleConfig.labelSkipHeight || 0}
        labelTextColor={styleConfig.LabelTextColorControl}
        label={createLabel}
        axisTop={customAxis('top', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisLeft={customAxis('left', styleConfig, styleConfig.LayoutControl !== 'horizontal')}
        axisBottom={customAxis('bottom', styleConfig, styleConfig.LayoutControl === 'horizontal')}
        axisRight={customPercentAxisRight(styleConfig, chartData.total ?? 1)}
        /* TOOLTIP */
        isInteractive={styleConfig.InteractiveControl}
        tooltip={createTooltip}
        /* LEGEND */
        enableGridX={styleConfig?.EnableGridX ?? false}
        enableGridY={styleConfig?.EnableGridY ?? false}
        legends={styleConfig?.LegendControl?.checked ? [{
          ...styleConfig?.LegendPlacementControl?.value,
          dataFrom: dataFrom() === 'id' ? 'keys' : 'indexes',
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemsSpacing: parseInt(styleConfig?.LegendsSpacingControl?.value, 10),
          symbolSize: 18,
          symbolShape: styleConfig?.LegendSymbolControl || 'circle',
          text: { textDecoration: 'line-through' },
          effects: [{ on: 'hover', style: { itemTextColor: '#000' } }],
          toggleSerie: !isEdit,
        }] : []}
        layers={[
          'grid',
          'axes',
          'bars',
          ExtraInfo,
          'markers',
          'legends',
          'annotations',
        ]}
        /* STYLES/THEMES */
        colorBy={dataFrom()}
        colors={dataFrom() === 'id' ? colorSelector({
          dataLength: chartData.keys.length, config: styleConfig, colorKey: 'id', globalTheme,
        }) : colorSelector({
          dataLength: chartData.data.length, config: styleConfig, colorKey: 'indexValue', globalTheme,
        })}
        theme={{
          legends: {
            text: {
              fontSize: `${styleConfig?.LegendsFontSizeControl?.value}px`,
              fontFamily: `'${styleConfig?.LegendsFontFamilyControl?.value}', Arial`,
            },
            hidden: {
              text: {
                textDecoration: 'line-through',
              },
            },
          },
          labels: {
            text: {
              fontSize: `${styleConfig?.LabelsFontSizeControl ? styleConfig?.LabelsFontSizeControl.value : 13}px`,
              fontFamily: `'${styleConfig?.LabelsFontFamilyControl?.value}', Arial`,
              transform: `translate(${styleConfig?.LabelTranslateX ? styleConfig?.LabelTranslateX : 0}px, ${styleConfig?.LabelTranslateY ? styleConfig?.LabelTranslateY : 0}px) rotate(${styleConfig?.LabelRotationControl ? styleConfig?.LabelRotationControl : 0}deg)`,
              transformOrigin: 'center center',
              transformBox: 'fill-box',
            },
          },
          axis: {
            legend: {
              text: {
                fontSize: styleConfig?.AxisGlobal?.legendFontSize,
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
            ticks: {
              text: {
                fontFamily: `'${styleConfig?.AxisGlobal?.fontFamily}', Arial`,
                fontSize: styleConfig?.AxisGlobal?.fontSize,
                fill: styleConfig?.AxisGlobal?.color,
              },
            },
          },
        }}
        /* ETC */
        animate={styleConfig.InteractiveControl ? !!styleConfig.Animate : false}
        motionConfig={styleConfig.InteractiveControl ? (styleConfig.MotionConfig || 'default') : 'default'}
      />
    </KpiStatus>
  ) : <></>;
};

Pareto.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  styleConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool.isRequired,
  globalTheme: PropTypes.objectOf(PropTypes.any),
};

Pareto.defaultProps = {
  globalTheme: {},
};

export default Pareto;
