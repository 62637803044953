/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { AlertContext } from '../../context/AlertProvider';

import Controllers from '../../juristec-ui/kpis/insight/controllers';
import Insight from '../../juristec-ui/kpis/insight';
import TextArea from '../../juristec-ui/core/TextArea';
import MainTabs from '../../juristec-ui/core/Tabs';
import Button from '../../juristec-ui/core/Button';
import Select from '../../juristec-ui/core/SelectNew';
import Tooltip from '../../juristec-ui/core/Tooltip';
import IconButton from '../../juristec-ui/core/IconButton';
import Popover from '../../juristec-ui/core/Popover';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import InputNumberLine from '../../juristec-ui/core/InputNumberLine';
import List from '../../juristec-ui/core/List';
import ListItem from '../../juristec-ui/core/ListItem';
import MiniLoading from '../../juristec-ui/core/MiniLoading';

import {
  Close, Finder, UploadImage, Eye2, Send, HashTag, Letters, Number, DateIcon,
} from '../../juristec-ui/icons';

import { verifyFileImgType, verifyFileSize } from '../../juristec-ui/utils/validators/fileValidators';
import { trimString } from '../../juristec-ui/utils/functions/formatString';
import { dateOptions } from '../../options';

import {
  OuterLabelCreate,
  GridContainer,
  ScrollContainer,
  InputContainer,
  ImgContainer,
  LabelTypeContainer,
  GptContainer,
  GptControlsContainer,
  GPTCredits,
  IconWrapper,
  ListIconWrapper,
  LoadingWrapper,
  InputRow,
} from './styled/LabelCreate.styled';

const icons = {
  category: <Letters />,
  object: <Letters />,
  float64: <Number />,
  int64: <Number />,
  'datetime64[ns]': <DateIcon />,
};

const LabelCreate = ({
  allowGpt,
  styleConfig,
  handleStyleChanges,
  typeTabState,
  contentState,
  handleUnsplashPicker,
  addDesktopImg,
  removeImg,
  databaseOptions,
  selectedDatabaseState,
  openFilePreviewModal,
  columns,
  questionState,
  submitQuestion,
  getContent,
  columnInfoState,
  lineMax,
  isLoading,
  dashboardDoc,
}) => {
  const theme = useTheme();
  const { setAlertConfig } = useContext(AlertContext);

  const inputFile = useRef(null);
  const cron = useRef();
  const inputRef = useRef();
  const specialChar = useRef('');

  const [activeTab, setActiveTab] = useState('tabData');
  const [question, setQuestion] = questionState;
  const [popControl, setPopControl] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [fCols, setFCols] = useState(columns);
  const [columnInfo, dispatchColumnInfo] = columnInfoState;
  const [typeTab, setTypeTab] = typeTabState;
  const [content, setContent] = contentState;
  const [selectedDatabase, setSelectedDatabase] = selectedDatabaseState;

  const setSimpleTab = () => setTypeTab('SIMPLE');
  const setSmartTab = () => setTypeTab('SMART');
  const setContentTab = () => setTypeTab('CONTENT');

  useEffect(() => {
    setFCols(columns);
  }, [columns]);

  const handleContent = (e) => {
    setContent(e.target.value);
    setQuestion((q) => ({ ...q, isDirty: true }));
  };

  const contentTrim = () => {
    trimString(content);
  };

  const controlQuestionVal = (val) => {
    if (val.length <= 200) {
      setQuestion({ value: val, isDirty: true });
      return true;
    }
    return false;
  };

  const handleQuestionInput = (e) => {
    const val = e.target.value;
    controlQuestionVal(val);
  };

  const closePop = () => {
    setPopControl(false);
    setInputFilter('');
    setFCols(columns);
    specialChar.current = '';
  };

  const handleSubmitQuestion = () => {
    closePop();
    submitQuestion();
  };

  // Function to check if the image satisfies the specified conditions
  const checkFileIsImg = (file) => {
    const errorMsg = verifyFileImgType(file) || verifyFileSize(file, 1000000);
    if (errorMsg.length > 0) {
      setAlertConfig({ type: 'error', text: errorMsg });
      return false;
    }
    return true;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && checkFileIsImg(file)) {
      addDesktopImg(file);
    }
  };

  const handleInputFilter = (e) => {
    const val = e.target.value;
    setInputFilter(val);
    clearTimeout(cron.current);

    cron.current = setTimeout(() => {
      setFCols(columns.filter((col) => (
        col.label.toLowerCase().includes(val.toLowerCase())
      )));
    }, 200);
  };

  const toggleColMentionPop = (e, open) => {
    e.stopPropagation();
    setPopControl((o) => open || !o);
  };

  const addColToInput = (colName) => {
    inputRef.current.focus();
    const cursor = inputRef.current.selectionStart;
    const inputValue = inputRef.current.value;
    if (controlQuestionVal(
      trimString(`${inputValue.slice(0, cursor - specialChar.current.length)}${colName}${inputValue.slice(cursor)}`),
    )) {
      setTimeout(() => {
        inputRef.current.selectionStart = cursor + colName.length;
        inputRef.current.selectionEnd = cursor + colName.length;
      }, 0);
    }
    specialChar.current = '';
  };

  const handleKey = (e) => {
    switch (e.key) {
      case 'Enter': {
        e.preventDefault();
        e.stopPropagation();
        if (!!question.value.trim() && !isLoading) {
          handleSubmitQuestion();
        }
        break;
      }
      case '#':
        toggleColMentionPop(e, true);
        specialChar.current = '#';
        break;
      default:
        closePop();
        break;
    }
  };

  const handleColumn = (selected) => {
    dispatchColumnInfo({ type: 'SET_COLUMN', selected });
    handleStyleChanges(['int64', 'float64'].includes(selected.type), 'isNumeric');
  };

  const handleLine = (val) => {
    if (typeof val === 'number') dispatchColumnInfo({ type: 'SET_LINE', line: val });
    else dispatchColumnInfo({ type: 'SET_LINE', line: val.target.value });
  };

  const handleFormat = (selected) => {
    dispatchColumnInfo({ type: 'SET_FORMAT', selected });
  };

  const handleSelectFile = (selected) => {
    setSelectedDatabase(selected);
    dispatchColumnInfo({ type: 'RESET' });
  };

  return (
    <>
      <OuterLabelCreate>
        <GridContainer>
          <div className="sideBar">
            <MainTabs
              head={[
                { text: 'Dados', id: 'tabData' },
                { text: 'Imagem', id: 'tabImage' },
                { text: 'Estilos', id: 'tabStyles' },
              ]}
              active={activeTab}
              setActive={setActiveTab}
            >
              <ScrollContainer>
                <LabelTypeContainer>
                  <Button
                    variant={typeTab === 'SIMPLE' ? 'contained' : 'outlined'}
                    onClick={setSimpleTab}
                    disabled={isLoading}
                  >
                    Simples
                  </Button>
                  {allowGpt && (
                    <Button
                      variant={typeTab === 'SMART' ? 'contained' : 'outlined'}
                      onClick={setSmartTab}
                      disabled={isLoading}
                    >
                      Smart
                    </Button>
                  )}
                  <Button
                    variant={typeTab === 'CONTENT' ? 'contained' : 'outlined'}
                    onClick={setContentTab}
                    disabled={isLoading}
                  >
                    Conteúdo
                  </Button>
                </LabelTypeContainer>
                <InputContainer style={{ position: 'relative' }}>
                  {/* ============= GPT ============= */}
                  {['SMART', 'CONTENT'].includes(typeTab) && (
                    <>
                      {isLoading && (
                        <LoadingWrapper>
                          <MiniLoading fill="primary" />
                        </LoadingWrapper>
                      )}
                      <InputRow>
                        <Select
                          label="Arquivo"
                          placeholder="Selecione"
                          onChange={handleSelectFile}
                          options={databaseOptions}
                          value={selectedDatabase}
                          sortBy="ascending"
                          searchable
                        />

                        {selectedDatabase.value !== '' ? (
                          <Tooltip text="Visualizar Dados">
                            <IconButton
                              color="primary"
                              onClick={openFilePreviewModal}
                              style={{ marginLeft: 5 }}
                            >
                              <Eye2 />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </InputRow>
                    </>
                  )}
                  {typeTab === 'CONTENT' && columns.length > 0 && (
                    <>
                      <InputRow>
                        <div style={{ flex: '3' }}>
                          <Select
                            label="Coluna"
                            placeholder="Selecione"
                            onChange={handleColumn}
                            options={columns}
                            value={columnInfo.selected}
                            sortBy="ascending"
                            searchable
                          />
                        </div>
                        <div style={{ flex: '1', marginLeft: '5px' }}>
                          <InputNumberLine
                            label="Linha"
                            value={columnInfo.line}
                            onChange={handleLine}
                            setValue={handleLine}
                            min={1}
                            max={lineMax ?? Infinity}
                          />
                        </div>
                      </InputRow>
                      {columnInfo.selected.type === 'datetime64[ns]' && (
                        <InputRow>
                          <Select
                            label="Formato"
                            placeholder="Selecione"
                            onChange={handleFormat}
                            options={dateOptions}
                            value={columnInfo.format}
                            sortBy="ascending"
                            searchable
                          />
                        </InputRow>
                      )}
                      <Button
                        onClick={getContent}
                        disabled={isLoading || columnInfo.block}
                        size="small"
                        style={{ boxShadow: 'none', minWidth: 'auto', marginLeft: 'auto' }}
                      >
                        Buscar
                        <IconWrapper>
                          <Finder />
                        </IconWrapper>
                      </Button>
                    </>
                  )}
                  {typeTab === 'SMART' && (
                    <Popover
                      open={popControl}
                      closePopover={closePop}
                      direction="top-start"
                      offset={[16, 2]}
                      style={{
                        backgroundColor: theme.popoverBackground,
                      }}
                    >
                      <Popover.Action>
                        <GptContainer disabled={selectedDatabase.value === ''}>
                          <TextArea
                            label="Pergunta"
                            ref={inputRef}
                            value={question.value}
                            onChange={handleQuestionInput}
                            onKeyDown={handleKey}
                            onClick={closePop}
                          />
                          <GptControlsContainer>
                            <Tooltip text="Colunas" atModal>
                              <IconButton
                                onClick={toggleColMentionPop}
                                color={popControl ? 'secondary' : 'primary'}
                                style={{ boxShadow: 'none', padding: '5px' }}
                              >
                                <HashTag />
                              </IconButton>
                            </Tooltip>
                            <GPTCredits style={{ display: 'block' }}>
                              <span>
                                Alimentado pelo
                                {' '}
                              </span>
                              <a
                                href="https://openai.com/gpt-4"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                GPT-4
                              </a>
                            </GPTCredits>
                            <Button
                              onClick={handleSubmitQuestion}
                              disabled={!question.value.trim() || isLoading}
                              size="small"
                              style={{ boxShadow: 'none', minWidth: 'auto' }}
                            >
                              Enviar
                              <IconWrapper>
                                <Send />
                              </IconWrapper>
                            </Button>
                          </GptControlsContainer>
                        </GptContainer>
                      </Popover.Action>
                      <Popover.Content style={{ backgroundColor: 'white', boxShadow: '0px -1px 4px #9a9a9a' }}>
                        <div onClick={(e) => e.stopPropagation()}>
                          <div style={{ padding: '0 5px 5px 5px' }}>
                            <InputTextLine
                              placeholder="Buscar coluna..."
                              value={inputFilter}
                              onChange={handleInputFilter}
                              style={{ fontSize: '14px' }}
                            />
                          </div>
                          <List style={{
                            maxHeight: '200px',
                            overflow: 'hidden auto',
                            scrollbarWidth: 'thin',
                            borderRadius: '0 0 8px 8px',
                          }}
                          >
                            {fCols.map((col) => (
                              <ListItem
                                key={`mention-${col.value}`}
                                noOutline
                                style={{ fontSize: '16px', padding: '8px 10px' }}
                                onClick={() => addColToInput(col.label)}
                              >
                                <ListIconWrapper typeForColor={col.type}>
                                  {icons[col.type]}
                                </ListIconWrapper>
                                {col.label}
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </Popover.Content>
                    </Popover>
                  )}
                  {typeTab === 'SIMPLE' && (
                    <div style={{ height: '150px' }}>
                      {/* ============= NORMAL ============= */}
                      <TextArea
                        label="Conteúdo (texto)"
                        value={content}
                        onChange={handleContent}
                        onBlur={contentTrim}
                        styleContainer={{ boxSizing: 'border-box' }}
                      />
                    </div>
                  )}
                </InputContainer>
              </ScrollContainer>
              {/* ============= IMAGE ============= */}
              <ScrollContainer>
                <ImgContainer>
                  <input
                    type="file"
                    ref={inputFile}
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                  <Button onClick={() => inputFile.current.click()} textTransform="none">
                    Adicione uma imagem
                    <UploadImage />
                  </Button>
                  <span>ou</span>
                  <Button onClick={handleUnsplashPicker} textTransform="none">
                    Busque imagens na web
                    <Finder />
                  </Button>
                  {styleConfig?.image?.length > 0 && (
                    <>
                      <span>ou</span>
                      <Button
                        onClick={removeImg}
                        textTransform="none"
                        color="error"
                        variant="outlined"
                      >
                        Remova imagem atual
                        <Close />
                      </Button>
                    </>
                  )}
                </ImgContainer>
              </ScrollContainer>
              {/* ============= OPTS ============= */}
              <ScrollContainer>
                <div style={{ borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                  <Controllers
                    handle={handleStyleChanges}
                    config={styleConfig}
                    isGlobalThemeActive={Boolean(dashboardDoc?.cardStyles?.useGlobalTheme)}
                    columnIsNumeric={['int64', 'float64'].includes(columnInfo.selected.type)}
                  />
                </div>
              </ScrollContainer>
            </MainTabs>
          </div>
          <div className="main" style={{ borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', overflow: 'hidden' }}>
            <Insight
              item={{ style: styleConfig, name: content }}
              globalTheme={
                dashboardDoc?.cardStyles && styleConfig?.UseGlobalColor !== false
                  ? dashboardDoc.cardStyles : { useGlobalTheme: false }
              }
            />
          </div>
        </GridContainer>
      </OuterLabelCreate>
    </>
  );
};

LabelCreate.propTypes = {
  allowGpt: PropTypes.bool,
  styleConfig: PropTypes.shape({
    image: PropTypes.string,
    UseGlobalColor: PropTypes.bool,
  }),
  handleStyleChanges: PropTypes.func.isRequired,
  typeTabState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ])),
  contentState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  handleUnsplashPicker: PropTypes.func.isRequired,
  addDesktopImg: PropTypes.func.isRequired,
  removeImg: PropTypes.func.isRequired,
  databaseOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDatabaseState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
  openFilePreviewModal: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  questionState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ])),
  submitQuestion: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  lineMax: PropTypes.number,
  isLoading: PropTypes.bool,
  dashboardDoc: PropTypes.shape({
    cardStyles: PropTypes.shape({
      useGlobalTheme: PropTypes.bool,
    }),
  }),
  columnInfoState: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ])),
};

LabelCreate.defaultProps = {
  allowGpt: false,
  styleConfig: {},
  typeTabState: ['SIMPLE', () => {}],
  contentState: ['', () => {}],
  databaseOptions: [],
  selectedDatabaseState: ['', () => {}],
  columns: [],
  questionState: ['', () => {}],
  isLoading: false,
  lineMax: 0,
  dashboardDoc: {},
  columnInfoState: [{}, () => {}],
};

export default LabelCreate;
