/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { useTheme } from 'styled-components';

// Import AceEditor modes and themes
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';

import InputTextLine from '../../../juristec-ui/core/InputTextLine';
import ActionsGroup from '../../../juristec-ui/core/ActionsGroup';
import Button from '../../../juristec-ui/core/Button';
import Select from '../../../juristec-ui/core/SelectNew';
import InputNumberLine from '../../../juristec-ui/core/InputNumberLine';
import TextArea from '../../../juristec-ui/core/TextArea';
import Modal from '../../../juristec-ui/core/Modal';

import {
  MainContainer,
  FormContainer,
  InputContainer,
  InfoLabels,
  TabGroup,
  TabButton,
  InputRow,
  TabContent,
  TabContainer,
  TabGroupTitle,
  TextAreaContainer,
  CreateETLButton,
} from './styled/ReportETLs.styled';
import { formatDateTime } from '../../../juristec-ui/utils/functions/lab';
import IconButton from '../../../juristec-ui/core/IconButton';
import { Trash, Settings } from '../../../juristec-ui/icons';

import Tooltip from '../../../juristec-ui/core/Tooltip';
/**
* A modal for uploading files from desktop or google drive link
*/
const etlReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ETL':
      return action.payload;
    case 'UPDATE_ORDER':
      return { ...state, order: action.payload };
    case 'UPDATE_DESCRIPTION':
      return { ...state, description: action.payload };
    case 'UPDATE_NAME':
      return { ...state, name: action.payload };
    case 'UPDATE_REQUIRED_REPORTS':
      return { ...state, required_report_ids: action.payload };
    case 'UPDATE_CODE':
      return { ...state, code: action.payload };
    default:
      return state;
  }
};

const ReportETLs = ({
  etls,
  hide,
  reportDoc,
  companyReports,
  createETL,
  updateETL,
  handleDelete,
  fetchReportETLs,
}) => {
  const theme = useTheme();

  const [reportEtls, setReportEtls] = useState(etls);
  const [etlOptions, setEtlOptions] = useState([]);
  const [mode, setMode] = useState('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [dryRun, setDryRun] = useState(false);

  const fetchEtls = async () => {
    const res = await fetchReportETLs(reportDoc);
    setReportEtls(res);
  };

  useEffect(() => {
    const options = Object.keys(reportEtls)
      .map((etl) => ({
        label: reportEtls[etl]?.name || reportEtls[etl]?.description || reportEtls[etl]?.id || 'Sem Nome',
        value: etl,
        id: etl,
        ...reportEtls[etl],
      }))
      .sort((a, b) => a.order - b.order);
    setEtlOptions(options);
  }, [reportEtls]);

  const createEtl = {
    label: 'Criar ETL',
    value: 'create',
    id: 'create',
    name: '',
    description: '',
    order: etlOptions.length > 0 ? Math.max(...etlOptions.map((etl) => etl.order)) + 1 : 0,
    code: '',
    required_report_ids: [],
  };

  const [activeEtl, dispatch] = useReducer(
    etlReducer, etlOptions.length > 0 ? etlOptions[0] : createEtl,
  );

  const [selectedReports, setSelectedReports] = useState(
    activeEtl?.required_report_ids ? companyReports.filter(
      (report) => activeEtl?.required_report_ids?.includes(report.id),
    ) : [],
  );

  const handleCodeChange = (value) => {
    dispatch({ type: 'UPDATE_CODE', payload: value });
  };

  const handleRequiredReportsChange = (newValue) => {
    setSelectedReports(newValue);
    dispatch({
      type: 'UPDATE_REQUIRED_REPORTS',
      payload: newValue.map((report) => report.id),
    });
  };

  const handleOrderChange = (value) => {
    dispatch({ type: 'UPDATE_ORDER', payload: value });
  };

  const handleDescriptionChange = (e) => {
    dispatch({ type: 'UPDATE_DESCRIPTION', payload: e.target.value });
  };

  const handleNameChange = (e) => {
    dispatch({ type: 'UPDATE_NAME', payload: e.target.value });
  };

  useEffect(() => {
    setSelectedReports(
      activeEtl?.required_report_ids ? companyReports.filter(
        (report) => activeEtl?.required_report_ids?.includes(report.id),
      ) : [],
    );
  },
  [activeEtl.id]);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_REQUIRED_REPORTS',
      payload: selectedReports.map((report) => report.id),
    });
  }, [selectedReports]);

  const handleSubmit = async (etl, test = false) => {
    setDryRun(test);
    if (mode === 'create') {
      setIsSettingsModalOpen(true);
      return;
    }
    setIsConfirmModalOpen(true);
  };

  useEffect(
    () => {
      const etlToSave = activeEtl;
      if (
        etlToSave?.required_report_ids?.length > 0
        && etlToSave?.required_report_ids?.[0] !== reportDoc.id
      ) {
        etlToSave.required_report_ids.unshift(reportDoc.id);
        dispatch({
          type: 'UPDATE_REQUIRED_REPORTS',
          payload: etlToSave.required_report_ids,
        });
      }
    },
    [selectedReports],
  );
  const handleConfirmSave = async () => {
    setIsConfirmModalOpen(false);
    if (mode === 'create') {
      await createETL(reportDoc, activeEtl, dryRun, false);
    } else {
      await updateETL(reportDoc, activeEtl, dryRun, false);
    }
    fetchEtls();
  };

  useEffect(() => {
    if (mode === 'create') {
      dispatch({ type: 'SET_ACTIVE_ETL', payload: createEtl });
    }
  }, [mode]);

  const handleSelectActiveETL = (etl) => {
    dispatch({ type: 'SET_ACTIVE_ETL', payload: etl });
    setMode('edit');
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const handleDeleteETL = async (etl) => {
    handleSelectActiveETL(etl);
    const { handler, modalHandler } = handleDelete(reportDoc, etl);
    const auxRemove = () => {
      fetchEtls();
      if (etlOptions.length === 1) {
        setMode('create');
        dispatch({ type: 'SET_ACTIVE_ETL', payload: createEtl });
      }
    };
    const tmp = () => handler(auxRemove);
    modalHandler(tmp);
  };

  const handleSelectSettings = (etl) => {
    setDryRun(false);
    handleSelectActiveETL(etl);
    openSettingsModal();
  };

  const closeSettingsModal = () => (
    setIsSettingsModalOpen(false)
  );

  const saveSettingsModal = async () => {
    setIsSettingsModalOpen(false);
    if (mode === 'create') {
      await createETL(reportDoc, activeEtl, dryRun, false);
      fetchEtls();
    } else {
      await updateETL(reportDoc, activeEtl, dryRun, false);
      fetchEtls();
    }
  };

  return (
    <>
      <MainContainer>
        <TabContainer>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <TabGroupTitle>Listagem de ETLs</TabGroupTitle>
            <TabGroup>
              {etlOptions.map((etl) => (
                <TabContent
                  key={etl.id}
                  active={activeEtl.id === etl.id}
                >
                  <TabButton
                    key={etl.id}
                    onClick={() => handleSelectActiveETL(etl)}
                    active={activeEtl.id === etl.id}
                  >
                    {etl.label}
                  </TabButton>
                  <Tooltip atModal text="Mudar configurações do ETL">
                    <IconButton
                      onClick={() => handleSelectSettings(etl)}
                      disabled={Boolean(etl?.parent_etl_id)}
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                  <Tooltip atModal text="Deletar ETL">
                    <IconButton
                      onClick={() => handleDeleteETL(etl)}
                      disabled={etl.order !== etlOptions[etlOptions.length - 1].order}
                    >
                      <Trash />
                    </IconButton>
                  </Tooltip>
                </TabContent>
              ))}
            </TabGroup>
          </div>
          <CreateETLButton active={mode === 'create'} onClick={() => setMode('create')}>
            Criar um ETL
          </CreateETLButton>
        </TabContainer>
        { ['edit', 'create'].includes(mode) ? (
          <FormContainer key={activeEtl.id}>
            <InputContainer style={{ height: '100%' }}>
              <TextAreaContainer disabled={Boolean(activeEtl?.parent_etl_id)}>
                <TextArea
                  label="Descrição"
                  onChange={handleDescriptionChange}
                  value={activeEtl?.description || ''}
                  styleContainer={{ height: '60px' }}
                />
              </TextAreaContainer>
              <p style={{ fontSize: '11px', margin: '5px 0px' }}>
                <span style={{ color: theme.error }}><strong>* </strong></span>
                O relatório está armazenado na variável
                {' '}
                <strong style={{ fontStyle: 'italic' }}>dataframe</strong>
                , se o relatório depender de um ou mais relatórios,
                {' '}
                <strong style={{ fontStyle: 'italic' }}>dataframe</strong>
                {' '}
                será um array e os relatórios dependentes estarão na ordem de seleção.
                {' '}
                Exemplo:
                {' '}
                <strong style={{ fontStyle: 'italic' }}>dataframe[0]</strong>
                {' '}
                é o relatório onde está sendo criado o etl e do índice 1
                {' '}
                em diante são os relatórios selecionados.
              </p>
              <span style={{ textAlign: 'center' }}>
                <strong>Código python do ETL</strong>
              </span>
              <InputRow
                disabled={Boolean(activeEtl?.parent_etl_id)}
                style={{ height: '100%' }}
              >
                <AceEditor
                  mode="python"
                  theme="monokai"
                  fontSize={14}
                  lineHeight={19}
                  scrollMargin={[0, 0]}
                  enableLiveAutocompletion
                  enableBasicAutocompletion
                  enableSnippets
                  tabSize={4}
                  showPrintMargin
                  showGutter
                  highlightActiveLine
                  disabled={Boolean(activeEtl?.parent_etl_id)}
                  value={activeEtl.code}
                  style={{ width: '100%', height: '100%' }}
                  onChange={handleCodeChange}
                  name={`CodigoETL-${activeEtl.id}`}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    enableMobileMenu: true,
                    showLineNumbers: true,
                  }}
                />
              </InputRow>
              {mode === 'edit' && (
                <>
                  <InfoLabels>
                    <span>
                      <strong>{'Criado por: '}</strong>
                      {activeEtl.createdByEmail}
                    </span>
                    <span>
                      <strong>{'Criado em: '}</strong>
                      {formatDateTime(activeEtl.createdAt, { time: 'half' })}
                    </span>
                  </InfoLabels>
                  <InfoLabels>
                    <span>
                      <strong>{'Última atualização: '}</strong>
                      {formatDateTime(activeEtl.updatedAt, { time: 'half' })}
                    </span>
                    <span>
                      <strong>{'Atualizado por: '}</strong>
                      {activeEtl.updatedByEmail}
                    </span>
                  </InfoLabels>
                </>
              )}
            </InputContainer>
          </FormContainer>
        ) : (
          <FormContainer>
            <InputContainer>
              <p>
                Selecione um ETL para visualizar ou editar suas configurações ou clique em
                {' '}
                <strong style={{ fontStyle: 'italic' }}>Criar um ETL</strong>
                {' '}
                para criar um novo ETL.
              </p>
            </InputContainer>
          </FormContainer>
        )}
      </MainContainer>
      <ActionsGroup>
        <Button
          style={{ margin: '5px' }}
          onClick={hide}
          variant="outlined"
          size="large"
        >
          Cancelar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleSubmit(activeEtl, false)}
          size="large"
          disabled={Boolean(activeEtl?.parent_etl_id)}
          className="atualizar/criar-etl"
        >
          Salvar
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={() => handleSubmit(activeEtl, true)}
          size="large"
          variant="contained"
          color="secondary"
          disabled={Boolean(activeEtl?.parent_etl_id)}
          className="testar-etl"
        >
          Testar ETL
        </Button>
      </ActionsGroup>
      {isSettingsModalOpen && (
        <Modal
          onClose={closeSettingsModal}
          minWidth
          hide={closeSettingsModal}
          title="Configurações do ETL"
          yesLabel={`${dryRun ? 'Testar' : 'Salvar'}`}
          notLabel="Cancelar"
          yesFunc={saveSettingsModal}
          notFunc={closeSettingsModal}
        >
          <InputTextLine
            label="Nome"
            value={activeEtl?.name || ''}
            onChange={handleNameChange}
          />
          <InputNumberLine
            label="Ordem de execução"
            value={activeEtl?.order || 0}
            onChange={(e) => handleOrderChange(e.target.value)}
            setValue={handleOrderChange}
            min={0}
            step={1}
          />
          <Select
            label="Relatórios"
            options={companyReports}
            value={selectedReports}
            onChange={handleRequiredReportsChange}
            multiple
            stayOpen
            searchable
            clearable
            atModal
            sortBy="ascending"
            placeholder="Selecione os relatórios"
          />
        </Modal>
      )}
      {isConfirmModalOpen && (
        <Modal
          title={`${dryRun ? 'Testar' : 'Salvar'} ETL`}
          yesLabel="Confirmar"
          notLabel="Cancelar"
          hide={() => setIsConfirmModalOpen(false)}
          yesFunc={handleConfirmSave}
        >
          <div>
            Tem certeza de que deseja
            {' '}
            {dryRun ? 'testar' : 'salvar'}
            {' '}
            este ETL?
          </div>
        </Modal>
      )}
    </>
  );
};

ReportETLs.propTypes = {
  etls: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
  hide: PropTypes.func.isRequired,
  companyReports: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  createETL: PropTypes.func.isRequired,
  updateETL: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  reportDoc: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  fetchReportETLs: PropTypes.func.isRequired,
};
ReportETLs.defaultProps = {
};

export default ReportETLs;
