/* eslint-disable no-nested-ternary */
import React, {
  useState, useRef, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';

import useDeviceType from '../../../hooks/useDeviceType';

import Tooltip from '../../../core/Tooltip';
import Popover from '../../../core/Popover';

import { expDateOrder } from '../../../utils/formatCharts/orderDatetime';
import compare from '../../../../utils/functions/sorting';
import ControlFilter from '../../../../components/Popovers/ControlFilter';
import IconButton from '../../../core/IconButton';
import Button from '../../../core/Button';
import List from '../../../core/List';
import ListItem from '../../../core/ListItem';
import TextArea from '../../../core/TextArea';
import CardKpi from '../CardKpi';

import {
  StyledGridKpiCard,
  Comment,
  // FilterHeader,
  MenuCard,
} from './styled/GridKpisCard.styled';

import {
  Edit,
  Clone,
  MoreVert,
  PNG,
  Info,
  Report,
  AddComment,
  CommentExclamation,
  Filter,
  Fullscreen,
  Trash,
  Control,
  Warning,
  ArrowLeft,
} from '../../../icons';

const deviceThreshold = (device) => {
  switch (device) {
    case 'desktop':
      return 50;
    case 'tablet':
      return 12;
    case 'phone':
      return 4;
    default:
      return 0;
  }
};

const getSorting = (sConfig, firstColumn) => ({
  sortBy: !['Não ordenado', undefined].includes(sConfig?.SortDataControl)
    ? (sConfig?.ColumnToSortControl ?? firstColumn) : '',
  sortOrder: sConfig?.SortDataControl === 'Decrescente' ? 'desc' : 'asc',
});

const GridKpisCard = ({
  item,
  // isSuper,
  isOwner,
  gridKpiState,
  editCard,
  cloneCard,
  deleteCard,
  editPainel,
  openCardInfo,
  donwloadReport,
  downloadImgCard,
  createKpiComment,
  openModifiersModal,
  modifiersQtd,
  openKpiViewer,
  autoValueModifiers,
  viewKpiIdState,
  showToPrint,
  //
  getControlValues,
  handleDynamicKpi,
  // ...props
  cardSizes,
  globalFilters,
  globalFiltersOrder,
  dependencyFile,
  canAccessReport,
  getKpiWithoutDependencyMessage,
  globalTheme,
  database,
}) => {
  const device = useDeviceType();
  const kpiRef = useRef();
  const [showControlFilter, setShowControlFilter] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [showCommentContainer, setShowCommentContainer] = useState(false);
  const [isEditComment, setIsEditComment] = useState(false);
  const [comment, setComment] = useState(item.comment || '');

  const [controlSelected, setControlSelected] = useState();
  const [controlOptions, setControlOptions] = useState();

  const [drilldownKeys, setDrilldownKeys] = useState(null);

  const [controlFileError, setControlFileError] = useState();
  const [filteredItem, setFilteredItem] = useState(item);
  const [kpiLoading, setKpiLoading] = useState(false);
  const [toggleMenuCard, setToggleMenuCard] = useState(false);

  const [page, setPage] = useState(0);
  const [viewKpiId, setViewKpiId] = viewKpiIdState;

  useEffect(() => {
    if (item.type === 'SubsetTable') {
      (async () => {
        await handleDynamicKpi(
          item.id,
          item.type,
          item?.meta ?? {},
          item.database,
          setFilteredItem,
          { page: 0, ...getSorting(item.style, item?.meta?.values?.[0]?.column) },
        );
      })();
    } else {
      setFilteredItem(item);
      setDrilldownKeys(null);
    }
    setControlSelected(null);
  }, [item]);

  const meta = useMemo(() => (item?.meta ?? {}), [item]);
  const { collection, kpiItemList } = gridKpiState;

  const closeMenu = () => setMenuIsOpen(false);
  const toggleMenu = () => setMenuIsOpen((p) => !p);

  const closeControlFilter = () => setShowControlFilter(false);

  /** @async Load dataframe values from control variable */
  const loadControlValues = async () => {
    if (!editPainel && !controlOptions && meta?.control?.[0]?.column && meta.database) {
      const res = await getControlValues(
        item.database, meta.control[0].column, meta.control[0].map, meta.filters,
      );
      if (res.fileError) {
        setControlFileError(res.fileError);
      } else {
        res.opts.sort((a, b) => (compare(
          expDateOrder(a.label), expDateOrder(b.label),
        )));
        setControlOptions([
          ...res.opts,
        ]);
      }
    }
  };

  const toggleControlFilter = () => setShowControlFilter((p) => {
    if (!p) loadControlValues();
    return !p;
  });

  const handleCardImgDonwload = () => downloadImgCard(kpiRef.current, item.name);

  const handleOpenCardInfo = () => openCardInfo(item);

  const handleOpenFilterValues = () => openModifiersModal(item);

  const handleDelete = () => deleteCard(item);

  const handleEdit = () => editCard(item);

  const handleClone = () => cloneCard(item);

  const closeCommentContainer = (resetComment) => {
    setShowCommentContainer(false);
    setIsEditComment(false);
    if (resetComment) setComment(item.comment);
  };

  const toggleCommentContainer = () => setShowCommentContainer((p) => !p);
  const showCommentEditor = () => {
    if (isOwner && collection === 'kpis') setIsEditComment(true);
  };
  const showAddComment = () => {
    setShowCommentContainer((p) => !p);
    setIsEditComment(true);
  };

  const onSubmitComment = async () => {
    setShowCommentContainer(false);
    setIsEditComment(false);
    await createKpiComment(comment, item.id);
  };

  const checkComment = () => !!item?.comment?.trim();

  const genControllerFilter = (values) => {
    if (values && values?.length !== controlOptions?.length) {
      return {
        ftype: 'values',
        inverse: false,
        column: meta.control[0].column,
        selector: meta.control[0].map,
        values,
      };
    } return null;
  };

  const genDrilldownFilter = (dataKey) => {
    if (dataKey?.length > 0) {
      const result = meta?.linesMap?.find((line) => line.rule === dataKey);
      return {
        ftype: result?.ftype || 'values',
        inverse: result?.inverse ?? false,
        column: meta?.lines[0].column,
        selector: meta?.lines[0].map,
        values: result ? result.values : [dataKey],
      };
    } return null;
  };

  const genDrildownColFilter = (colKey) => {
    if (colKey?.length > 0) {
      const isValue = meta?.values?.find((v) => v?.column === colKey);
      if (isValue) return null;
      return {
        ftype: 'values',
        inverse: false,
        column: meta?.columns[0].column,
        selector: meta?.columns[0].map,
        values: [colKey],
      };
    } return null;
  };

  const genGlobalFilter = () => {
    if (globalFiltersOrder?.current?.length > 0) {
      return globalFiltersOrder?.current?.reduce((aux, fId) => {
        if (globalFilters[fId]) {
          aux.push({
            ftype: globalFilters[fId].ftype,
            inverse: globalFilters[fId].inverse,
            column: globalFilters[fId].column,
            selector: globalFilters[fId].selector,
            values: globalFilters[fId].values,
          });
        }
        return aux;
      }, []);
    }
    return [];
  };

  const handleTempFilters = async (controlValues, pg = 0, ddKeys = null) => {
    setKpiLoading(true);
    const control = genControllerFilter(controlValues);
    const global = genGlobalFilter();
    const drilldownFilter = genDrilldownFilter(ddKeys?.dataKey);
    const drilldownColFilter = genDrildownColFilter(ddKeys?.colKey);
    let tempFilters = [];

    if (global.length > 0) {
      tempFilters = tempFilters.concat(global);
    }
    if (control) tempFilters.push(control);

    if (drilldownFilter) tempFilters.push(drilldownFilter);
    if (drilldownColFilter) tempFilters.push(drilldownColFilter);
    if (tempFilters.length > 0 || item.type === 'SubsetTable') {
      const tempMeta = cloneDeep(meta);
      if (tempFilters.length > 0) {
        tempMeta.filters = [
          ...tempFilters,
          ...meta.filters,
        ];
        if (tempMeta?.drilldown?.length > 0 && drilldownFilter) {
          tempMeta.lines = [...tempMeta.drilldown];
          tempMeta.linesMap = [];
        }
      }
      setPage(pg);
      await handleDynamicKpi(
        item.id,
        item.type,
        tempMeta,
        item.database || database,
        setFilteredItem,
        { page: pg, ...getSorting(item.style, tempMeta.values?.[0]?.column ?? '') },
      );
    } else {
      setFilteredItem(item);
      setKpiLoading(false);
    }
  };

  const handlePageChange = (pgState) => {
    const pg = typeof pgState === 'function' ? pgState(page) : pgState;
    handleTempFilters(controlSelected, pg, drilldownKeys);
  };

  /**
   * Filters kpi data, based on selected control values
   * @param {array} selected Selected values
   */
  const handleControl = (selected) => {
    setControlSelected(selected);
    handleTempFilters(selected, 0, drilldownKeys);
  };

  const handleDrildown = (dataKey, colKey) => {
    setDrilldownKeys({ dataKey, colKey });
    handleTempFilters(controlSelected, 0, { dataKey, colKey });
  };

  const removeDrilldown = () => {
    setDrilldownKeys(null);
    handleTempFilters(controlSelected, 0, null);
  };

  const handleGlobalFilters = () => {
    handleTempFilters(controlSelected, 0, drilldownKeys);
  };

  useEffect(() => {
    if (globalFilters && Object.keys(globalFilters).length > 0 && !['smartLabel', 'Content'].includes(item.type)) {
      handleGlobalFilters();
    }
  }, [globalFilters]);

  const getReport = async () => {
    const control = genControllerFilter(controlSelected);
    const global = genGlobalFilter();
    let tempFilters = [];

    if (global.length > 0) {
      tempFilters = tempFilters.concat(global);
    }
    if (control) tempFilters.push(control);

    await donwloadReport(item.id, item?.name, tempFilters);
  };

  const handleOpenMenuCard = () => {
    setToggleMenuCard(true);
  };

  useEffect(() => {
    setToggleMenuCard(menuIsOpen || showCommentContainer || showControlFilter);
  }, [menuIsOpen, showCommentContainer, showControlFilter]);

  const handleHoverCloseMenuCard = () => {
    setToggleMenuCard(menuIsOpen || showCommentContainer || showControlFilter);
  };

  const handleOpenKpiViewer = () => {
    // openKpiViewer(item.id);
    setViewKpiId(item.id);
  };

  useEffect(() => {
    if (viewKpiId !== null && viewKpiId === item.id) {
      openKpiViewer(
        viewKpiId,
        filteredItem,
        [page, handlePageChange],
        kpiLoading,
        drilldownKeys,
        handleDrildown,
        removeDrilldown,
      );
    }
  }, [viewKpiId, filteredItem, page, kpiLoading]);

  useEffect(() => {
    setKpiLoading(false);
  }, [filteredItem]);

  return (
    <StyledGridKpiCard
      onMouseEnter={handleOpenMenuCard}
      onMouseLeave={handleHoverCloseMenuCard}
    >
      <Popover
        style={{
          borderRadius: '8px',
          backgroundColor: 'trasparent',
          overflow: 'hidden',
          minWidth: 'auto',
        }}
        offset={[0, 0]}
        open={!editPainel && (device !== 'desktop' || toggleMenuCard)}
        stayOpened
        // closePopover={() => {}}
        direction="right-start"
        customPopperModifier={[
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              altAxis: (cardSizes.x + cardSizes.w) >= deviceThreshold(device),
            },
          },
        ]}
      >
        <Popover.Action>
          <CardKpi
            ref={kpiRef}
            key={item.id + modifiersQtd}
            item={filteredItem}
            editPainel={editPainel}
            id={item.id}
            showToPrint={showToPrint}
            isDataLoading={kpiLoading}
            handleDrilldownClick={
              item?.meta?.drilldown?.length > 0 && !drilldownKeys ? handleDrildown : null
            }
            drilldownSelected={Boolean(drilldownKeys)}
            removeDrilldown={removeDrilldown}
            globalTheme={
              globalTheme && filteredItem.style?.UseGlobalColor !== false
                ? globalTheme : { useGlobalTheme: false }
            }
            collection={collection}
            pageState={[page, handlePageChange]}
            autoValueModifiers={!editPainel ? autoValueModifiers : null}
          />
        </Popover.Action>
        <Popover.Content>
          {!editPainel && (
            <>
              <MenuCard className="menuCard">
                {/* ========== Drilldown Back ========== */}
                {Boolean(drilldownKeys) && (
                  <Tooltip text="Retornar ao gráfico original" direction="left">
                    <IconButton
                      color="secondary"
                      shape="circle"
                      variant="pattern"
                      size={cardSizes.w > 5 ? 'medium' : 'small'}
                      style={{ boxShadow: 'none' }}
                      onClick={removeDrilldown}
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Tooltip>
                )}
                {/* ========== Control Filter ========== */}
                {collection === 'kpis' && meta?.control?.[0]?.column?.length > 0 && (
                  <Popover
                    open={showControlFilter}
                    closePopover={closeControlFilter}
                    stayOpened
                    offset={[-20, 2]}
                    style={{ zIndex: '1050' }}
                  >
                    <Popover.Action>
                      <Tooltip text="Controle" direction="left">
                        <IconButton
                          color={
                            controlSelected && controlSelected?.length !== controlOptions?.length ? 'secondary' : 'primary'
                          }
                          shape="circle"
                          variant="pattern"
                          size={cardSizes.w > 5 ? 'medium' : 'small'}
                          style={{ boxShadow: 'none' }}
                          onClick={toggleControlFilter}
                        >
                          <Control />
                        </IconButton>
                      </Tooltip>
                    </Popover.Action>
                    <Popover.Content>
                      <ControlFilter
                        close={closeControlFilter}
                        controlOptions={controlOptions}
                        controlFilterValues={controlSelected}
                        handleFilter={handleControl}
                        controlVar={meta?.control?.[0]?.column || ''}
                        errorMsg={controlFileError}
                      />
                    </Popover.Content>
                  </Popover>
                )}

                {/* ========== Value Filter ========== */}
                {isOwner
                  && !['label', 'smartLabel', 'BoxPlot', 'ScatterPlot', 'Iframe', 'Histogram', 'Content'].includes(item.type)
                  && collection === 'kpis'
                  && device !== 'phone'
                  && cardSizes.h > 6
                  && item.data?.index?.length > 1 && (
                  <Tooltip text="Filtrar valores" direction="left">
                    <IconButton
                      color={modifiersQtd > 0 ? 'secondary' : 'primary'}
                      shape="circle"
                      variant="pattern"
                      size="medium"
                      style={{ boxShadow: 'none' }}
                      onClick={handleOpenFilterValues}
                    >
                      <Filter />
                    </IconButton>
                  </Tooltip>
                )}

                {/* ========== Comment ========== */}
                {(checkComment() || isOwner)
            && device !== 'phone'
            && !['label', 'smartLabel', 'Iframe', 'Content'].includes(item.type)
            && (
              <Popover
                open={showCommentContainer}
                closePopover={closeCommentContainer}
                stayOpened
                offset={[-20, 2]}
                style={{
                  width: '250px', padding: '1rem 1rem', whiteSpace: 'normal', zIndex: '1050',
                }}
              >
                <Popover.Action>
                  {checkComment() ? (
                    <Tooltip text="Ver comentário" direction="left">
                      <IconButton
                        color="secondary"
                        shape="circle"
                        variant="pattern"
                        size="medium"
                        style={{ boxShadow: 'none' }}
                        onClick={toggleCommentContainer}
                      >
                        <CommentExclamation />
                      </IconButton>
                    </Tooltip>
                  ) : isOwner && collection === 'kpis' && (
                    <Tooltip text="Adicionar comentário" direction="left">
                      <IconButton
                        shape="circle"
                        variant="pattern"
                        size={cardSizes.w > 5 ? 'medium' : 'small'}
                        style={{ boxShadow: 'none' }}
                        onClick={showAddComment}
                      >
                        <AddComment />
                      </IconButton>
                    </Tooltip>
                  )}
                </Popover.Action>
                <Popover.Content>
                  <span style={{ color: '#696969', fontSize: '0.85rem', fontWeight: 'bold' }}>Comentário</span>
                  {!isEditComment ? (
                    <Comment
                      onClick={showCommentEditor}
                      isOwner={isOwner}
                      story={collection === 'kpis'}
                    >
                      {item.comment}
                    </Comment>
                  ) : (
                    <div>
                      <TextArea
                        value={comment}
                        label=""
                        onChange={(e) => setComment(e.target.value)}
                        style={{ height: '100px' }}
                      />
                      <div style={{
                        display: 'flex', justifyContent: 'center', gap: '5px', paddingTop: '5px',
                      }}
                      >
                        <Button
                          color="primary"
                          variant="outlined"
                          size="medium"
                          shape="rounded"
                          onClick={() => closeCommentContainer(true)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="medium"
                          shape="rounded"
                          onClick={onSubmitComment}
                        >
                          Salvar
                        </Button>
                      </div>
                    </div>
                  )}
                </Popover.Content>
              </Popover>
            )}

                {/* ========== Check dependencies ========== */}
                {(!dependencyFile?.allowed && !['label', 'Iframe'].includes(item.type) && device !== 'phone') && (
                  <Tooltip text="Sem acesso ao arquivo de origem" direction="left">
                    <IconButton
                      color="error"
                      shape="circle"
                      variant="pattern"
                      size={cardSizes.w > 5 ? 'medium' : 'small'}
                      className="kpi-options"
                      style={{ boxShadow: 'none' }}
                      onClick={() => getKpiWithoutDependencyMessage(dependencyFile, item)}
                    >
                      <Warning aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
                    </IconButton>
                  </Tooltip>
                )}

                {/* ========== Menu options ========== */}
                <Popover
                  open={menuIsOpen}
                  closePopover={closeMenu}
                  offset={[-20, 2]}
                  style={{ zIndex: '1050' }}
                >
                  <Popover.Action>
                    <Tooltip text="Mais opções" direction="left">
                      <IconButton
                        shape="circle"
                        variant="pattern"
                        size={cardSizes.w > 5 ? 'medium' : 'small'}
                        className="kpi-options"
                        style={{ boxShadow: 'none', transform: 'rotate(90deg)' }}
                        onClick={toggleMenu}
                      >
                        <MoreVert aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close" />
                      </IconButton>
                    </Tooltip>
                  </Popover.Action>
                  <Popover.Content>
                    <List>
                      {isOwner
                        && !['label', 'smartLabel', 'BoxPlot', 'ScatterPlot', 'Iframe', 'Histogram', 'Content'].includes(item.type)
                        && collection === 'kpis'
                        && device !== 'phone'
                        && cardSizes.h <= 6 && (
                        <ListItem noOutline onClick={handleOpenFilterValues}>
                          <Filter />
                          Filtrar valores
                        </ListItem>
                      )}
                      {isOwner && !['label', 'smartLabel', 'Iframe', 'Content'].includes(item.type) && collection === 'kpis' && (
                        <ListItem noOutline onClick={handleOpenCardInfo}>
                          <Info fill="none" />
                          Informações de KPI
                        </ListItem>
                      )}

                      {!['label', 'smartLabel', 'Iframe', 'SubsetTable', 'Content'].includes(item.type) && (isOwner || canAccessReport) && collection === 'kpis' && (
                        <ListItem noOutline onClick={getReport}>
                          <Report fill="none" />
                          Relatório
                        </ListItem>
                      )}

                      {!['label', 'smartLabel', 'Iframe', 'SubsetTable', 'Content'].includes(item.type) && (
                        <ListItem
                          noOutline
                          onClick={handleCardImgDonwload}
                        >
                          <PNG />
                          Salvar PNG
                        </ListItem>
                      )}
                      {isOwner && collection === 'kpis' && device !== 'phone' && (
                        <>
                          <ListItem noOutline onClick={handleDelete}>
                            <Trash />
                            Apagar
                          </ListItem>

                          <ListItem noOutline onClick={handleEdit}>
                            <Edit />
                            Editar
                          </ListItem>

                          {item.type !== 'Iframe' && (
                            <ListItem noOutline onClick={handleClone}>
                              <Clone />
                              Clonar
                            </ListItem>
                          )}
                        </>
                      )}
                      {/* {!['label', 'smartLabel', 'Iframe'].includes(item.type)
                        && cardSizes.h <= 6 && (
                        <ListItem noOutline onClick={handleOpenKpiViewer}>
                          <Fullscreen />
                          Expandir
                        </ListItem>
                      )} */}
                    </List>
                  </Popover.Content>
                </Popover>

                {/* ========== Kpi Viewer ========== */}
                {!['label', 'smartLabel', 'Iframe', 'Content'].includes(item.type) && cardSizes.h > 6 && (
                  <div className="menuItemBottom">
                    <Tooltip text="Expandir" direction="left">
                      <IconButton
                        shape="circle"
                        variant="pattern"
                        size={cardSizes.w > 5 ? 'medium' : 'small'}
                        style={{ boxShadow: 'none' }}
                        onClick={handleOpenKpiViewer}
                      >
                        <Fullscreen />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </MenuCard>
            </>
          )}
        </Popover.Content>
      </Popover>
    </StyledGridKpiCard>
  );
};

GridKpisCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    database: PropTypes.string,
    style: PropTypes.shape({}),
    type: PropTypes.string,
    comment: PropTypes.string,
    data: PropTypes.shape({
      index: PropTypes.arrayOf(PropTypes.string),
    }),
    meta: PropTypes.shape({
      drilldown: PropTypes.arrayOf(PropTypes.shape({})),
      values: PropTypes.arrayOf(PropTypes.shape({
        column: PropTypes.string,
      })),
    }),
  }),
  isOwner: PropTypes.bool,
  gridKpiState: PropTypes.shape({
    collection: PropTypes.string,
    kpiItemList: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  editCard: PropTypes.func,
  cloneCard: PropTypes.func,
  deleteCard: PropTypes.func,
  openCardInfo: PropTypes.func,
  donwloadReport: PropTypes.func,
  downloadImgCard: PropTypes.func,
  createKpiComment: PropTypes.func,
  editPainel: PropTypes.bool,
  openModifiersModal: PropTypes.func,
  autoValueModifiers: PropTypes.func,
  modifiersQtd: PropTypes.number,
  openKpiViewer: PropTypes.func,
  showToPrint: PropTypes.bool,
  getControlValues: PropTypes.func,
  handleDynamicKpi: PropTypes.func,
  cardSizes: PropTypes.shape({
    h: PropTypes.number,
    w: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  dependencyFile: PropTypes.shape({
    allowed: PropTypes.bool,
  }),
  getKpiWithoutDependencyMessage: PropTypes.func,
  globalTheme: PropTypes.shape({}),
  viewKpiIdState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ),
  globalFilters: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.shape({})),
  }),
  globalFiltersOrder: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.string),
  }),
  database: PropTypes.string,
  canAccessReport: PropTypes.bool,
};

GridKpisCard.defaultProps = {
  item: {},
  // isSuper: false,
  isOwner: false,
  gridKpiState: {},
  editCard: () => {},
  cloneCard: () => {},
  deleteCard: () => {},
  openCardInfo: () => {},
  donwloadReport: () => {},
  downloadImgCard: () => {},
  createKpiComment: () => {},
  editPainel: false,
  openModifiersModal: () => {},
  autoValueModifiers: () => {},
  modifiersQtd: 0,
  openKpiViewer: () => {},
  showToPrint: false,
  getControlValues: () => {},
  handleDynamicKpi: () => {},
  cardSizes: {
    h: 7, w: 4, x: 0, y: 0,
  },
  dependencyFile: {},
  getKpiWithoutDependencyMessage: () => {},
  globalTheme: undefined,
  viewKpiIdState: ['', () => {}],
  globalFilters: undefined,
  globalFiltersOrder: undefined,
  database: '',
  canAccessReport: false,
};

export default GridKpisCard;
