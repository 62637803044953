/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../controllers/ControllersGroup';
import {
  InputLineController,
  SelectController,
  RangeController,
  ChoiceBoxController,
  ButtonLineController,
  SimpleColorPickerController,
  ToggleSwitchController,
} from '../../controllers/Base';

import fontOptions from '../../utils/controllersUtils/options';

const OptionControllers = ({
  handle,
  config,
  isGlobalThemeActive,
  columnIsNumeric,
}) => (
  <>
    <ControllersGroup title="Opções" id="option" extend="option" alwaysOpen key={config?.kpiKey}>
      <SelectController
        label="Posição do conteúdo"
        handle={handle}
        kWord="textPosition"
        options={[
          { label: 'Superior Esquerda', value: 'top-left', id: 'top-left' },
          { label: 'Superior Centralizado', value: 'top', id: 'top' },
          { label: 'Superior Direita', value: 'top-right', id: 'top-right' },
          { label: 'Central Esquerda', value: 'center-left', id: 'center-left' },
          { label: 'Centralizado', value: 'center', id: 'center' },
          { label: 'Central Direita', value: 'center-right', id: 'center-right' },
          { label: 'Inferior Esquerda', value: 'bottom-left', id: 'bottom-left' },
          { label: 'Inferior Centralizado', value: 'bottom', id: 'bottom' },
          { label: 'Inferior Direita', value: 'bottom-right', id: 'bottom-right' },
        ]}
        configValue={config?.textPosition}
        fullWidth
      />
      <SelectController
        label="Fonte"
        handle={handle}
        kWord="fontFamily"
        options={fontOptions}
        configValue={config?.fontFamily?.value}
      />
      <InputLineController
        label="Tamanho"
        handle={handle}
        kWord="fontSize"
        configValue={config?.fontSize}
        min={1}
        max={80}
        isNumber
      />
      <ButtonLineController
        handle={handle}
        boldKWord="bold"
        boldVal={config?.bold}
        italicKWord="italic"
        italicVal={config?.italic}
        underlineKWord="underline"
        underlineVal={config?.underline}
        fontColorKWord="fontColor"
        fontColorVal={config?.fontColor}
        fontColorText="Utilizando tema global"
        fontColorDisabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor de destaque"
        handle={handle}
        kWord="colorOpacityText"
        configValue={config?.colorOpacityText}
        fullWidth
        orientation="row"
      />
      <RangeController
        label="Opacidade do destaque"
        handle={handle}
        kWord="opacityText"
        configValue={config?.opacityText}
        min={0}
        max={1}
        step={0.1}
        precision={1}
        fullWidth
      />
      <SimpleColorPickerController
        label="Cor de fundo"
        handle={handle}
        kWord="bgColor"
        configValue={config?.bgColor}
        fullWidth
        orientation="row"
        text="Utilizando tema global"
        disabled={config?.UseGlobalColor !== false && isGlobalThemeActive}
      />
      <InputLineController
        label="Link externo (opcional)"
        handle={handle}
        kWord="link"
        configValue={config?.link}
        fullWidth
      />
      <ChoiceBoxController
        label="Ajuste da imagem"
        handle={handle}
        kWord="imageFit"
        options={[
          { label: 'Original', id: 'contain', value: 'contain' },
          { label: 'Preencher', id: 'cover', value: 'cover' },
        ]}
        configValue={config?.imageFit || 'contain'}
        fullWidth
        disabled={config?.image?.length === 0}
      />
      <RangeController
        label="Opacidade da imagem"
        handle={handle}
        kWord="opacityImage"
        configValue={config?.opacityImage}
        min={0.1}
        max={1}
        step={0.1}
        precision={1}
        fullWidth
        disabled={config?.image?.length === 0}
      />
      <ToggleSwitchController
        label="Utilizar tema global"
        handle={handle}
        kWord="UseGlobalColor"
        configValue={config?.UseGlobalColor !== false}
        firstOption=""
        secondOption=""
        fullWidth
      />
      {columnIsNumeric && (
        <>
          <ChoiceBoxController
            label="Casas decimais"
            handle={handle}
            kWord="Decimals"
            options={[
              { value: 'auto', label: 'Automático', id: 'auto' },
              { value: 'fixed', label: 'Customizável', id: 'fixed' },
            ]}
            configValue={config?.Decimals || 'auto'}
            fullWidth
          />
          <RangeController
            label="Quantidade de casas"
            handle={handle}
            kWord="separadorNumericoCasas"
            configValue={config?.separadorNumericoCasas}
            min={0}
            max={5}
            step={1}
            precision={0}
            fullWidth
            disabled={config?.Decimals !== 'fixed'}
          />
          <SelectController
            label="Moeda"
            handle={handle}
            kWord="LabelCurrencyControl"
            options={['Nenhum', 'R$', 'US$', '€'].map((d) => ({ value: d, label: d, id: d }))}
            configValue={config?.LabelCurrencyControl}
            disabled={config?.DataFormat?.type === 'percentual'}
          />
          <SelectController
            label="Agrupamento"
            handle={handle}
            kWord="LabelGroupingControl"
            options={[
              { value: 'Nenhum', label: 'Nenhum', id: 'Nenhum' },
              { value: 'milhar', label: 'Milhar (M)', id: 'Milhar (M)' },
              { value: 'milhao', label: 'Milhão (Mi)', id: 'Milhão (Mi)' },
              { value: 'bilhao', label: 'Bilhão (Bi)', id: 'Bilhão (Bi)' },
            ]}
            configValue={config?.LabelGroupingControl || 'Nenhum'}
            disabled={config?.DataFormat?.type === 'percentual'}
          />
        </>
      )}
    </ControllersGroup>
  </>
);

OptionControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  isGlobalThemeActive: PropTypes.bool,
  columnIsNumeric: PropTypes.bool,
};

OptionControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  isGlobalThemeActive: false,
  columnIsNumeric: false,
};

export default OptionControllers;
