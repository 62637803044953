import React from 'react';

import {
  LegalOne,
  GoogleDriveNew,
  Connect,
  Postgresql,
  Mysql,
  Oracle,
  Excel,
  Csv,
  Antecipei,
  AtSign,
  File,
  BennerSimple,
  OneDrive,
  Datajuri,
  Astrea,
  Advbox,
  VersionMerge,
  Jira,
  Omie,
} from '../icons';

const originIcon = (origin) => {
  switch (origin) {
    case 'Advbox':
      return <Advbox />;

    case 'Antecipei':
      return <Antecipei />;

    case 'Astrea':
      return <Astrea />;

    case 'Benner':
      return <BennerSimple />;

    case 'csv':
      return <Csv />;

    case 'Datajuri':
      return <Datajuri />;

    case 'Desktop':
    case 'xls':
    case 'xlsx':
      return <Excel />;

    case 'Drive':
    case 'GoogleDrive':
      return <GoogleDriveNew />;

    case 'FileApi':
      return <Connect />;

    case 'JIRA':
      return <Jira />;

    case 'LegalOne':
    case 'LegalOneExtractor':
    case 'L1ReportsApi':
      return <LegalOne />;

    case 'Mail':
    case 'mail':
      return <AtSign />;

    case 'MySql':
      return <Mysql />;

    case 'Omie':
      return <Omie />;

    case 'OneDrive':
    case 'oneDrive':
      return <OneDrive />;

    case 'Oracle':
      return <Oracle />;

    case 'PgSql':
      return <Postgresql />;

    case 'Snapshot':
      return <VersionMerge />;

    default:
      return <File />;
  }
};

export default originIcon;

export const originAutoUpdated = (origin) => {
  switch (origin) {
    case 'Advbox':
    case 'Antecipei':
    case 'Astrea':
    case 'Benner':
    case 'Datajuri':
    case 'Drive':
    case 'GoogleDrive':
    case 'FileApi':
    case 'JIRA':
    case 'LegalOne':
    case 'LegalOneExtractor':
    case 'L1ReportsApi':
    case 'Mail':
    case 'mail':
    case 'MySql':
    case 'Omie':
    case 'OneDrive':
    case 'oneDrive':
    case 'Oracle':
    case 'PgSql':
    case 'Snapshot':
      return true;

    case 'csv':
    case 'Desktop':
    case 'xls':
    case 'xlsx':
      return false;

    default:
      return false;
  }
};

export const getOriginOptions = [
  {
    id: 'origin-Advbox',
    label: 'Advbox',
    value: 'Advbox',
  },
  {
    id: 'origin-Antecipei',
    label: 'Antecipei',
    value: 'Antecipei',
  },
  {
    id: 'origin-Astrea',
    label: 'Astrea',
    value: 'Astrea',
  },
  {
    id: 'origin-Benner',
    label: 'Benner',
    value: 'Benner',
  },
  {
    id: 'origin-FileApi',
    label: 'Connect',
    value: 'FileApi',
  },
  {
    id: 'origin-Datajuri',
    label: 'Datajuri',
    value: 'Datajuri',
  },
  {
    id: 'origin-Desktop',
    label: 'Desktop',
    value: 'Desktop',
  },
  {
    id: 'origin-Mail,mail',
    label: 'Email',
    value: 'Mail,mail',
  },
  {
    id: 'origin-GoogleDrive,Drive',
    label: 'Google Drive',
    value: 'GoogleDrive,Drive',
  },
  {
    id: 'origin-JIRA,Jira',
    label: 'Jira',
    value: 'JIRA',
  },
  {
    id: 'origin-LegalOne,LegalOneExtractor,L1ReportsApi',
    label: 'Legal One',
    value: 'LegalOne,LegalOneExtractor,L1ReportsApi',
  },
  {
    id: 'origin-MySql',
    label: 'MySql',
    value: 'MySql',
  },
  {
    id: 'origin-Omie',
    label: 'Omie',
    value: 'Omie',
  },
  {
    id: 'origin-OneDrive',
    label: 'One Drive',
    value: 'OneDrive',
  },
  {
    id: 'origin-Oracle',
    label: 'Oracle',
    value: 'Oracle',
  },
  {
    id: 'origin-PgSql',
    label: 'PgSql',
    value: 'PgSql',
  },
  {
    id: 'origin-Snapshot',
    label: 'Histórico',
    value: 'Snapshot',
  },
];

export const getOriginOptionsSupport = [
  {
    id: 'origin-Mail,mail',
    label: 'Email',
    value: 'Mail,mail',
  },
  {
    id: 'origin-LegalOne,LegalOneExtractor,L1ReportsApi',
    label: 'Legal One',
    value: 'LegalOne,LegalOneExtractor,L1ReportsApi',
  },
];

export const getConnectorOptions = [
  { id: 'connector-cloudstorage', label: 'cloudstorage', value: 'cloudstorage' },
  { id: 'connector-desktop', label: 'desktop', value: 'desktop' },
  { id: 'connector-fileapi', label: 'fileapi', value: 'fileapi' },
  { id: 'connector-l1reportsapi', label: 'ged', value: 'l1reportsapi' },
  { id: 'connector-LegalOneDataExtractor', label: 'LegalOneDataExtractor', value: 'LegalOneDataExtractor' },
  { id: 'connector-mail', label: 'mail', value: 'mail' },
  { id: 'connector-snapshot', label: 'snapshot', value: 'snapshot' },
  { id: 'connector-sql', label: 'sql', value: 'sql' },
  { id: 'connector-web', label: 'web', value: 'web' },
];

export const getConnectorOptionsSupport = [
  { id: 'connector-l1reportsapi', label: 'ged', value: 'l1reportsapi' },
  { id: 'connector-LegalOneDataExtractor', label: 'LegalOneDataExtractor', value: 'LegalOneDataExtractor' },
  { id: 'connector-mail', label: 'mail', value: 'mail' },
  { id: 'connector-web', label: 'web', value: 'web' },
];
