/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ControllersGroup from '../../../controllers/ControllersGroup';
import {
  RangeController,
  ChoiceBoxController,
} from '../../../controllers/Base';

const GeneralControllers = ({
  handle,
  config,
  extend,
  setExtend,
}) => (
  <>
    <ControllersGroup
      title="Opções Gerais"
      id="general"
      key="general"
      extend={extend}
      setExtend={setExtend}
      sizeIncrease={0}
    >
      <ChoiceBoxController
        label="Formato"
        handle={handle}
        kWord="LayoutControl"
        configValue={config?.LayoutControl}
        options={[
          { label: 'horizontal', value: 'horizontal', id: 'horizontal' },
          { label: 'vertical', value: 'vertical', id: 'vertical' },
        ]}
        fullWidth
      />
      <RangeController
        label="Margem Superior"
        handle={handle}
        kWord="MarginTop"
        configValue={config?.Margin?.top}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Inferior"
        handle={handle}
        kWord="MarginBottom"
        configValue={config?.Margin?.bottom}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Esquerda"
        handle={handle}
        kWord="MarginLeft"
        configValue={config?.Margin?.left}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <RangeController
        label="Margem Direita"
        handle={handle}
        kWord="MarginRight"
        configValue={config?.Margin?.right}
        min={0}
        max={500}
        step={1}
        precision={0}
        fullWidth
      />
      <ChoiceBoxController
        label="Formato dos dados"
        handle={handle}
        kWord="DataFormatValueType"
        configValue={config?.DataFormatValueType}
        options={[
          { value: 'absolute', label: 'Contagem', id: 'absolute' },
          { value: 'percentual', label: 'Proporcional', id: 'percentual' },
        ]}
        fullWidth
      />
    </ControllersGroup>
  </>
);

GeneralControllers.propTypes = {
  config: PropTypes.objectOf(PropTypes.any),
  handle: PropTypes.func,
  extend: PropTypes.string,
  setExtend: PropTypes.func,
};

GeneralControllers.defaultProps = {
  config: undefined,
  handle: () => {},
  extend: '',
  setExtend: () => {},
};

export default GeneralControllers;
