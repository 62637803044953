/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, {
  useContext, useEffect, useState, useRef, useMemo, useLayoutEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// components
import FilesToolbar from '../../components/Toolbars/FilesToolbar';
import FilesCards from '../../components/FilesView/FilesCards';
import FilesList from '../../components/FilesView/FilesList';
import FileUpload from '../../components/Modals/FileUpload';
import FileInfo from '../../components/Modals/FileInfo';
import TransferFileOwner from '../../components/Modals/TransferFileOwner';
import FileValidation from '../../components/Modals/FileValidation';
import FileVisualize from '../../components/Modals/FileVisualize';
import NoFiles from '../../components/FilesView/NoFiles';
import Loader from '../../juristec-ui/core/Loader';
import FileReport from '../../components/FilesView/FileReport';
import ShareList from '../../juristec-ui/core/ShareList';
import ColumnAlias from '../../components/Modals/ColumnAlias';
import ChangeDatabasePassword from '../../components/Modals/ChangeDatabasePassword';
import CreateFileProvision from '../../components/Modals/CreateFileProvision';
// contexts
import { FilesContext } from '../../context/FilesProvider';
import { AuthContext } from '../../context/AuthProvider';
import { ModalContext } from '../../context/ModalProvider';
import { AlertContext } from '../../context/AlertProvider';
import { TourContext } from '../../context/TourProvider';
import { UsersContext } from '../../context/UsersProvider';

// hooks
import useDeviceType from '../../juristec-ui/hooks/useDeviceType';

// others / utils
import { error } from '../../label';
import urlHash from '../../utils/functions/urlHash';
import renameFile from '../../utils/functions/renameFile';
import { shareFileOptions } from '../../options';
import ListFileProvisions from '../../components/Modals/ListFileProvisions/ListFileProvisions';

// RETIRADO PARA TESTE (BUG CASO O ARQUIVO POSSUA ALGUM ETL)
// const columnsDiff = (oldColumns, oldCustomColumns, newColumns) => {
//   if ((oldColumns.length - oldCustomColumns.length) > newColumns.length) {
//     return 'missing';
//   }
//   if ((oldColumns.length - oldCustomColumns.length) < newColumns.length) {
//     return 'leftover';
//   }
//   return '';
// };

const getSupportEmail = () => {
  switch (process.env.REACT_APP_FIREBASE_PROJECT_LABEL) {
    case 'legalone-analytics':
      return <b>suporte@legaloneanalytics.com.br</b>;

    case 'benner-metrics':
      return <b>suporte@bennermetrics.com.br</b>; // ALTERAÇÃO BENNER: alterar para o e-mail correto

    default:
      return <b>suporte@legalmetrics.com.br</b>;
  }
};

function FilesPage() {
  const {
    userCompany, user, changeUserConfigs,
  } = useContext(AuthContext);
  const { setModalConfig, closeModal } = useContext(ModalContext);
  const { setAlertConfig, toggleAlert } = useContext(AlertContext);
  const { state: filesState, filesAPI } = useContext(FilesContext);
  const {
    tourOpen, nextStep, refreshTour, remakeTourList,
  } = useContext(TourContext);
  const { state: usersState, usersAPI } = useContext(UsersContext);
  const {
    started, isLoading: fileLoading, files, size, waitingMsg,
  } = filesState;
  const totalSize = parseFloat(userCompany?.volumeData || 0);

  const device = useDeviceType();

  const isMounted = useRef(false);
  const history = useHistory();
  //
  const [viewLayout, setViewLayout] = useState(true);
  // true: lista
  // false : cards
  const FilesComponent = device !== 'phone' && viewLayout ? FilesList : FilesCards;

  // filter
  const [filterText, setFilterText] = useState('');
  const cron = useRef(null);
  const handleFilterText = (t) => {
    clearTimeout(cron.current);
    cron.current = setTimeout(() => {
      setFilterText(t);
    }, 200);
  };

  /**
   * Opens an alert showing the file's update information
   * Attention with the logic of this function.
   * A copy of that is used in KpisPage component inside the handleDependencies function
   * @param {object} file file info
   */
  const getReportStatus = async (file) => {
    const generateErrorReport = async () => {
      const res = await filesAPI.errorReport(file.filename, file.file_id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: 'Houve um erro ao gerar o relatório de erros.',
          child: res.raw,
        });
        return;
      }
      const blob = res.res;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', blob.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      toggleAlert();
    };
    const GenerateReport = ({ text, auditable }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{text}</span>
        {auditable && (
          <span>
            Se desejar extrair o relatório de auditoria,
            <div
              style={{
                marginLeft: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline', display: 'inline',
              }}
              onClick={generateErrorReport}
            >
              clique aqui
            </div>
          </span>
        )}
      </div>
    );
    GenerateReport.propTypes = {
      text: PropTypes.string,
      auditable: PropTypes.bool,
    };
    GenerateReport.defaultProps = {
      text: 'Houve um erro ao atualizar o relatório.',
      auditable: false,
    };
    const handleUpdate = async () => {
      const res = await filesAPI.baseUpdate(file.filename, file.file_id);
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
          child: <GenerateReport text={res.raw} auditable={res?.auditable} />,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: `Arquivo ${file.filename} atualizado com sucesso!`,
      });
      filesAPI.init();
    };

    const updateFile = () => {
      setAlertConfig({
        type: 'warning',
        text: `Atualizar o relatório ${file.filename}?`,
        withoutConfirm: true,
        withFunction: true,
        confirmFunction: () => handleUpdate(files[0]),
      });
    };

    const res = await filesAPI.getFile(file.file_id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
      return;
    }

    const infoFile = {
      ...res.res,
    };
    const owner = usersState.users.find((u) => u.uid === infoFile.owner);

    let status = '';
    let msg = '';
    if (infoFile.lastAttemptStatus === 'ERROR') {
      status = 'error';
      msg = 'Erro ao atualizar o relatório!';
    } else if (infoFile.lastAttemptStatus === 'WARNING') {
      status = 'warning';
      msg = 'Relatório atualizado!';
    } else {
      status = 'success';
      msg = 'Relatório atualizado com sucesso!';
    }

    if ((user.id === file.owner || file.shared_write.includes(user.id))
      && ['GoogleDrive', 'Drive', 'OneDrive', 'PgSql', 'MySql', 'Benner', 'LegalOne', 'LegalOneExtractor', 'L1ReportsApi', 'Mail'].includes(file.origin)) {
      setAlertConfig({
        type: status,
        text: msg,
        child: <FileReport
          file={infoFile}
          owner={owner}
          adminAccess={false}
          manualUpdate={updateFile}
        />,
      });
    } else {
      setAlertConfig({
        type: status,
        text: msg,
        child: <FileReport
          file={infoFile}
          owner={owner}
          adminAccess={false}
        />,
      });
    }
  };

  const handleDelete = async (f) => {
    const deleteFile = async () => {
      const result = await filesAPI.deleteFile(f);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
      } else {
        setAlertConfig({ type: 'success', text: 'Arquivo apagado com sucesso.' });
      }
    };

    const fileDependencies = await filesAPI.getFile(f.file_id);
    if (fileDependencies.error) {
      setAlertConfig({
        type: 'error',
        text: fileDependencies.msg,
        child: fileDependencies.raw,
      });
    } else {
      const res = fileDependencies.res.dashboards;
      const dashs = Object.values(res);
      const dashsOwners = dashs.map((d) => (d.owner === user.uid
        ? 'Você'
        : d.displayName || usersState.filter((u) => u.uid === d.owner)
      ));
      const dashsPerUser = Object.entries(dashsOwners.reduce((obj, value) => {
        obj[value] = obj[value] + 1 || 1;
        return obj;
      }, {}));
      setAlertConfig({
        type: 'warning',
        text: 'Tem certeza que deseja excluir o arquivo?',
        child: dashsOwners.length < 1
          ? (
            <span>
              O arquivo
              {' '}
              <strong style={{ fontStyle: 'italic' }}>
                {f.filename.replace('.metrics', '')}
              </strong>
              {' '}
              não poderá ser recuperado
            </span>
          ) : (
            <>
              <div>
                Existem
                {' '}
                <strong>
                  {dashsPerUser.length}
                </strong>
                {' '}
                usuários com
                {' '}
                <strong>
                  {dashs.length}
                </strong>
                {' '}
                dashboards dependentes deste arquivo:
              </div>
              <div style={{ textAlign: 'center' }}>
                {dashsPerUser.map((element, index) => (
                  index !== dashsPerUser.length - 1
                    ? `${element[0]} (${element[1]}), `
                    : `${element[0]} (${element[1]}).`
                ))}
              </div>
              <div>
                Ao excluir o arquivo
                {' '}
                <strong style={{ fontStyle: 'italic' }}>
                  {f.filename.replace('.metrics', '')}
                </strong>
                {', '}
                estes usuários não poderão mais atualizar seus dashboards.
              </div>
            </>
          ),
        withoutConfirm: true, // tirar o ok do alerta
        withFunction: true, // colocar btn cancelar e confirmar
        confirmFunction: deleteFile,
      });
    }
  };

  const downloadFile = async (file) => {
    const res = await filesAPI.downloadFile(file);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: res.msg,
        child: res.raw,
      });
    }
  };

  const showFileDashboards = async (file) => {
    const { error: er, msg, res } = await filesAPI.getFile(file.file_id);
    if (er) {
      setAlertConfig({
        type: 'error',
        text: msg,
      });
      return;
    }

    const fileInfos = res;
    const onClick = (dashId, owner) => history.push(
      `/home/${urlHash(
        `${dashId}/${owner}`,
      )}`,
    );

    setModalConfig({
      title: 'Dashboards',
      yesLabel: 'Ok',
      children: (
        <FileInfo
          currentUser={user}
          file={fileInfos}
          users={usersState.users}
          close={closeModal}
          onClickDash={onClick}
          deviceType={device}
        />
      ),
    });
  };

  const handleViewDataFile = async (fileItem, openAssistant) => {
    history.push(
      `/arquivos/${urlHash(`${fileItem.file_id}`)}${openAssistant ? '?assistant' : ''}`,
    );
  };

  const handleViewFileAssistant = async (fileItem) => {
    handleViewDataFile(fileItem, true);
  };

  const handleConvertFileToMail = async (file) => {
    const sizeUsed = size * 1024 ** 2;
    const sizeLimit = totalSize * 1024 ** 2;
    if (sizeUsed > sizeLimit) {
      setAlertConfig({
        type: 'error',
        text: 'Não há memória suficiente!',
      });
      return;
    }
    const res = await filesAPI.convertFileToMail(file.filename, file.file_id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: error.generic,
        child: res.msg,
      });
    } else {
      setAlertConfig({ type: 'success', text: 'Relatório criado com sucesso' });
      filesAPI.init();
    }
  };

  const handleConvertFileApiGed = async (file) => {
    const sizeUsed = size * 1024 ** 2;
    const sizeLimit = totalSize * 1024 ** 2;
    if (sizeUsed > sizeLimit) {
      setAlertConfig({
        type: 'error',
        text: 'Não há memória suficiente!',
      });
      return;
    }
    const res = await filesAPI.convertFileApiGed(file.filename, file.file_id);
    if (res.error) {
      setAlertConfig({
        type: 'error',
        text: error.generic,
        child: res.msg,
      });
    } else {
      setAlertConfig({ type: 'success', text: 'Relatório criado com sucesso' });
      filesAPI.init();
    }
  };

  const handleTransferFile = async (file) => {
    const usersList = usersState.users.filter((u) => (
      u.role !== 'guest' && u.uid !== user.id
    )).map((u) => ({
      id: u.uid,
      label: u?.name || u.email.split('@')[0],
      value: u.uid,
    }));

    const submitTransfer = (newUser, selfRemove) => {
      const confirmTransfer = async () => {
        const result = await filesAPI.transferFileOwner(file.file_id, newUser.value, selfRemove);
        if (result.error) {
          setAlertConfig({
            type: 'error',
            text: result.msg,
            child: `${result.raw}`,
          });
          return;
        }

        setAlertConfig({
          type: 'success',
          text: 'Arquivo transferido com sucesso!',
          child: (
            <span>
              A partir de agora, o arquivo
              {' '}
              <b style={{ fontStyle: 'italic' }}>
                {file.filename}
              </b>
              {' '}
              está sob propriedade do usuário
              {' '}
              <b style={{ fontStyle: 'italic' }}>
                {newUser.label}
              </b>
            </span>
          ),
        });
        closeModal('transfer_file_modal');
        filesAPI.init();
      };

      setAlertConfig({
        type: 'warning',
        text: 'Tem certeza?',
        child: 'Você perderá a propriedade do arquivo selecionado!',
        withFunction: true,
        withoutConfirm: true,
        confirmFunction: confirmTransfer,
      });
    };

    setModalConfig({
      nodeTarget: 'transfer_file_modal',
      title: 'Transferir arquivo',
      children: (
        <TransferFileOwner
          file={file}
          usersOptions={usersList}
          close={() => closeModal('transfer_file_modal')}
          submit={submitTransfer}
        />
      ),
    });
  };

  // Create or update a provision from the parent file (main file)
  const handleCreateFileProvision = async (file) => {
    const submitCreateProvision = async (tag) => {
      const result = file.origin !== 'Snapshot'
        ? await filesAPI.createFileProvision(file.file_id, tag)
        : await filesAPI.createFileProvision(file?.parent_file_id, tag);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Histórico criado com sucesso',
      });
      closeModal();
      filesAPI.init();
    };

    const submitSchedule = async (cronCode) => {
      const result = file.origin !== 'Snapshot'
        ? await filesAPI.historySchedule(file.file_id, cronCode)
        : await filesAPI.historySchedule(file?.parent_file_id, cronCode);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Histórico agendado com sucesso',
      });
      closeModal();
    };

    setModalConfig({
      title: file.origin !== 'Snapshot' ? 'Criar Histórico' : 'Atualizar Histórico',
      children: (
        <CreateFileProvision
          type={file.origin !== 'Snapshot' ? 'create' : 'update'}
          isDesktopFile={file.origin === 'Desktop'}
          filename={file.filename}
          close={() => closeModal()}
          submitNow={submitCreateProvision}
          submitSchedule={submitSchedule}
        />
      ),
    });
  };

  // Function that lists all the provisions created
  const handleListFileProvisions = async (file) => {
    const parentFile = files.find((f) => f.file_id === file?.parent_file_id);
    const parentFilename = parentFile?.filename || '';

    let result = null;
    result = await filesAPI.listFileProvisions(file.file_id);
    if (result.error) {
      setAlertConfig({
        type: 'error',
        text: result.msg,
        child: result.raw,
      });
      return;
    }

    const handleDeleteProvision = (provisionId) => {
      const confirmDelete = async (f) => {
        const res = await filesAPI.deleteFileProvision(file.file_id, provisionId);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: res.msg,
            child: res.raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: 'Histórico removido com sucesso!',
        });
        f();
        filesAPI.init();
      };

      const modalHandler = (f) => {
        setAlertConfig({
          type: 'warning',
          text: 'Tem certeza? Essa operação não poderá ser desfeita!',
          withFunction: true,
          withoutConfirm: true,
          confirmFunction: f,
        });
      };

      return { confirmDelete, modalHandler };
    };

    setModalConfig({
      title: 'Histórico de Base',
      yesLabel: 'Ok',
      children: (
        <ListFileProvisions
          user={user}
          provisionOwner={file?.ownerId || ''}
          parentFilename={parentFilename}
          provisions={result.res}
          deleteProvision={handleDeleteProvision}
        />
      ),
    });
  };

  /**
   * Check by name if the file already exists
   * @param {string} fileName The submitted file name
   * @returns {object} Object with the answer
   */
  const verifyFilesNames = (fileName) => {
    const spName = fileName.split('.');
    // ".split('.metrics')" for compatibility
    const eqFile = files.find((f) => f.filename.split('.metrics')[0] === `${spName[0]}.${spName[1]}`);
    if (eqFile) {
      let higherName = 0;
      const reg = new RegExp(`${spName[0]} - cópia \\((\\d)+\\)\\.${spName[1]}`, 'gi');
      files.forEach((f) => {
        const regRes = reg.exec(f.filename);
        if (regRes?.length > 1) higherName = Math.max(higherName, Number(regRes[1]));
      });
      return {
        alreadyExists: true, newName: `${spName[0]} - cópia (${higherName + 1}).${spName[1]}`, oldFile: eqFile,
      };
    }
    return { alreadyExists: false };
  };

  const handleUpdateSubmit = async (newFile, oldFile) => {
    if (tourOpen) { remakeTourList('updateFile'); }

    if (!newFile || !oldFile) return;
    const newFileName = (newFile.filename || newFile.name);

    const oldFileInfo = await filesAPI.getFileSample(oldFile.file_id, false, 10);
    if (oldFileInfo.error || !oldFileInfo.res) {
      setAlertConfig({
        type: 'error',
        text: oldFileInfo.msg,
        child: oldFileInfo.raw,
      });
      return;
    }

    // RETIRADO PARA TESTE (BUG CASO O ARQUIVO POSSUA ALGUM ETL)
    // const columnError = columnsDiff(
    //   oldFileInfo.res.columns, oldFileInfo.res.custom_columns ?? [], newFile.columns,
    // );
    // if (columnError) {
    //   setAlertConfig({
    //     type: 'error',
    //     text: 'Seu arquivo não pode ser atualizado!',
    //     child: (
    //       <p>
    //         As colunas do seu arquivo não são compatíveis com o arquivo original
    //         <br />
    //         {columnError === 'missing' && 'Existem colunas faltando no arquivo enviado'}
    //         {columnError === 'leftover' && 'Existem colunas a mais no arquivo enviado'}
    //       </p>
    //     ),
    //   });
    //   return;
    // }
    // console.log(newFile.types, oldFileInfo.res.types);

    newFile.types = oldFileInfo.res.types;

    const handleSubmit = async (startLine) => {
      const generateErrorReport = async () => {
        const res = await filesAPI.errorReport(newFileName, oldFile.file_id, startLine);
        if (res.error) {
          setAlertConfig({
            type: 'error',
            text: 'Houve um erro ao gerar o relatório de erros.',
            child: res.raw,
          });
          return;
        }
        const blob = res.res;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', blob.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toggleAlert();
      };
      const GenerateReport = ({ text, auditable }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{text}</span>
          {auditable && (
            <span>
              Se desejar extrair o relatório de auditoria,
              <div
                style={{
                  marginLeft: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline', display: 'inline',
                }}
                onClick={generateErrorReport}
              >
                clique aqui
              </div>
            </span>
          )}
        </div>
      );
      GenerateReport.propTypes = {
        text: PropTypes.string,
        auditable: PropTypes.bool,
      };
      GenerateReport.defaultProps = {
        text: 'Houve um erro ao atualizar o relatório.',
        auditable: false,
      };
      if (tourOpen) nextStep();
      const resUpdate = await filesAPI.baseUpdate(newFileName, oldFile.file_id, startLine);
      if (resUpdate.error) {
        setAlertConfig({
          type: 'error',
          text: resUpdate.msg,
          child: <GenerateReport text={resUpdate.raw} auditable={resUpdate?.auditable} />,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Arquivo atualizado com sucesso',
        child: resUpdate.res.message,
      });
      filesAPI.init();
    };

    setModalConfig({
      title: 'Atualização de arquivo',
      className: 'updateFileModal',
      confirmClassName: 'submit_update_file',
      children: (
        <FileVisualize
          fileData={newFile}
          reopenFile={filesAPI.reopen}
          fileName={newFileName}
          handleSubmit={handleSubmit}
          hide={closeModal}
        />
      ),
    });
  };

  const handleLink = async (url, filename, headerSkip, connector) => {
    const linkRes = await filesAPI.uploadFileByLink(url, filename, headerSkip, connector);
    if (linkRes.error) {
      setAlertConfig({
        type: 'error',
        text: linkRes.msg,
        child: linkRes.raw,
      });
      closeModal();
      return null;
    }
    return linkRes.res.file_id;
  };

  const handleFile = async (toHandleFile, overwrite, overwriteName) => {
    let newFile = toHandleFile;
    if (overwriteName) {
      newFile = renameFile(newFile, overwriteName);
    }
    const {
      res: fileColumnsInfos,
      msg,
      error: handleError,
      raw,
    } = await filesAPI.uploadFile(newFile, overwrite);
    if (tourOpen) refreshTour();
    if (handleError) {
      setAlertConfig({ type: 'error', text: `${msg}`, child: raw });
      return null;
    }
    return { name: newFile.name, ...fileColumnsInfos };
  };

  const handleSqlConnection = async (dbParams) => {
    const sqlRes = await filesAPI.uploadBySqlDB(dbParams);
    if (sqlRes.error) {
      setAlertConfig({
        type: 'error',
        text: sqlRes.msg,
        child: sqlRes.raw,
      });
      closeModal();
      return null;
    }
    return sqlRes.res.file_id;
  };
  const handleTestSqlConnection = async (dbParams) => {
    const sqlRes = await filesAPI.testSqlConnection(dbParams);
    if (sqlRes.error) {
      setAlertConfig({
        type: 'error',
        text: sqlRes.msg,
        child: sqlRes.raw,
      });
      return null;
    }
    setAlertConfig({
      type: 'success',
      text: sqlRes.res.msg,
    });
    return null;
  };
  const handleBennerConnect = async (filename, url, username, password, headerSkip) => {
    const bennerRes = await filesAPI.uploadBennerReport(
      filename, url, username, password, headerSkip,
    );
    if (bennerRes.error) {
      setAlertConfig({
        type: 'error',
        text: bennerRes.msg,
        child: bennerRes.raw,
      });
      closeModal();
      return null;
    }
    return bennerRes.res.file_id;
  };

  const handleOptionalValidation = async (filename, fileId) => {
    if (!fileId) return;
    const openNewFile = await filesAPI.getFileSample(fileId, true, 10, 1);

    const handleClose = async () => {
      closeModal();
      await filesAPI.init();
    };

    const handleSubmit = async ({
      columnTypes,
    }) => {
      const {
        error: editError,
        msg: editMsg,
        raw,
      } = await filesAPI.editColumns(fileId, columnTypes);
      if (editError) {
        setAlertConfig({
          type: 'error',
          // text: handleOptimizeError(raw),
          text: editMsg,
          child: raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Arquivo validado com sucesso!',
        child: (
          <span>
            O arquivo
            {' '}
            <strong style={{ fontStyle: 'italic' }}>
              {filename}
            </strong>
            {' '}
            foi validado e agora você já pode utilizá-lo para construir seus indicadores!
          </span>
        ),
      });
      handleClose();
    };

    setModalConfig({
      title: `Validação de dados: ${filename}`,
      className: 'modal_validation',
      children: (
        <FileValidation
          tourContext={{ tourOpen, nextStep }}
          hide={handleClose}
          fileData={openNewFile.res}
          filename={filename}
          submitData={handleSubmit}
          reopenFile={filesAPI.reopen}
          required={false}
        />
      ),
    });
  };

  /** Handles a new file upload */
  const handleNewFileUpload = () => {
    const handleOptimizer = (fileInfos, newName) => {
      if (!fileInfos) return;

      const handleSubmit = async ({
        dateFormat,
        floatColumns,
        dateColumns,
        // textColumns,
        selectedDecimal,
        startLine,
      }) => {
        const aux = {
          filename: newName || fileInfos.name || fileInfos.filename,
          uploaded_file: fileInfos.name || fileInfos.filename,
          date_columns: dateColumns,
          float_columns: floatColumns,
          int_columns: [],
          decimal: selectedDecimal || ',',
          date_format: dateFormat || '',
          header: startLine || 0,
          overwrite: false,
        };
        const {
          error: optimizeError,
          msg,
          raw,
        } = await filesAPI.optimize(aux);
        if (optimizeError) {
          setAlertConfig({
            type: 'error',
            // text: handleOptimizeError(raw),
            text: msg,
            child: raw,
          });
          return;
        }
        setAlertConfig({
          type: 'success',
          text: 'Arquivo validado com sucesso!',
          child: (
            <span>
              O arquivo
              {' '}
              <strong style={{ fontStyle: 'italic' }}>
                {newName || fileInfos.name || fileInfos.filename}
              </strong>
              {' '}
              foi validado e agora você já pode utilizá-lo para construir seus indicadores!
            </span>
          ),
        });
        closeModal();
        await filesAPI.init();
      };

      if (tourOpen) nextStep();
      // 3
      setModalConfig({
        title: `Validação de dados: ${newName || fileInfos.name || fileInfos.filename}`,
        className: 'modal_validation',
        children: (
          <FileValidation
            tourContext={{ tourOpen, nextStep }}
            hide={closeModal}
            fileData={fileInfos}
            filename={fileInfos.name || fileInfos.filename}
            submitData={handleSubmit}
            reopenFile={filesAPI.reopen}
          />
        ),
      });
    };

    /**
     * Check by name if the file already exists and, if true,
     * gives the user two options: 'New file' or 'Update
     * @param {object} fileData Uploaded file data
     */
    const verifyFileName = async (fileData) => {
      const sendedFilename = fileData.name || fileData.filename;
      const { alreadyExists, newName, oldFile } = verifyFilesNames(sendedFilename);
      if (alreadyExists) {
        // 2
        setModalConfig({
          nodeTarget: 'modal_exists_file',
          title: 'Arquivo com o mesmo nome',
          className: 'modal_upload_file',
          children: (
            <div style={{ padding: '5px' }}>
              <p>
                Este arquivo já existe em nosso sistema.
                <br />
                Deseja atualizá-lo ou criar um novo arquivo (
                {newName}
                )?
              </p>
            </div>
          ),
          yesLabel: 'Atualizar',
          yesFunc: () => handleUpdateSubmit(fileData, oldFile),

          notLabel: 'Novo arquivo',
          notFunc: () => handleOptimizer(fileData, newName),
        });
        if (tourOpen) refreshTour();
      } else {
        handleOptimizer(fileData);
      }
    };

    /**
     * @async
     * Uploads a file from desktop
     * @param {File} file File submitted
     */
    const handleFileByDesktop = async (file) => {
      verifyFileName(await handleFile(file, true));
    };

    /**
     * @async
     * Uploads a file by Google Drive link
     * @param {string} url Drive shared link
     */
    const handleFileByLink = async (url, filename, headerSkip, connector) => {
      handleOptionalValidation(filename, await handleLink(url, filename, headerSkip, connector));
    };

    /**
     * @async
     * Uploads a file by from a sql query
     * @param {object} dbParams Database parameters (condition, credentials)
     */
    const handleFileBySql = async (dbParams) => {
      handleOptionalValidation(dbParams.filename, await handleSqlConnection(dbParams));
    };
    /**
     * @async
     * Tests Sql conection to a db
     * @param {object} dbParams Database parameters (condition, credentials)
     */
    const handleConnectionTestBySql = async (dbParams) => {
      handleOptionalValidation(dbParams.filename, await handleTestSqlConnection(dbParams));
    };

    const handleBennerReport = async (filename, url, username, password, headerSkip) => {
      handleOptionalValidation(filename, await handleBennerConnect(
        filename, url, username, password, headerSkip,
      ));
    };

    if (userCompany.plan === 'push') {
      setAlertConfig({
        type: 'warning',
        text: 'Você não pode adicionar um novo arquivo',
        child: (
          <div>
            <p style={{ margin: '0px' }}>
              O plano contratado não permite a adição de novos arquivos!
            </p>
            <span>
              Caso deseje alterar o seu plano, entre em contato com
              {' '}
              {getSupportEmail()}
            </span>
          </div>
        ),
      });
    } else {
      setModalConfig({
        title: 'Upload de Arquivos',
        close: closeModal,
        // className: 'modal_upload_file',
        children: (
          <FileUpload
            availableSize={(totalSize - size) * 1024 ** 2}
            uploadFile={handleFileByDesktop}
            uploadFileByLink={handleFileByLink}
            uploadBySql={handleFileBySql}
            uploadBennerReport={handleBennerReport}
            testSqlConnection={handleConnectionTestBySql}
            updatingFileName=""
            hide={closeModal}
            tourContext={{ tourOpen, nextStep }}
          />
        ),
      });
    }
  };

  /// /////// update base ///////////
  /**
  * Opens an modal to upload a new file to be updated
  * Attention with the logic of this function.
  * A copy of that is used in KpisPage component inside the handleDependencies function
  * @param {object} file file info
  */
  const handleUpdateFile = async (file) => {
    const handleUpdateByFile = async (newFile) => (
      handleUpdateSubmit(await handleFile(newFile, true), file)
    );

    setModalConfig({
      title: 'Atualização de arquivo',
      close: closeModal,
      className: 'updateFileModal',
      children: (
        <FileUpload
          availableSize={(totalSize - size) * 1024 ** 2}
          uploadFile={handleUpdateByFile}
          updateMode
          updatingFileName={file.filename.split('.metrics')[0]}
          hide={closeModal}
          // tourContext={{ tourOpen, nextStep }}
        />
      ),
    });
  };

  // save userConfig
  const handleChangeLayout = async () => {
    let layout = false;
    setViewLayout((p) => {
      layout = !p;
      return !p;
    });

    const configs = {
      filesLayout: layout,
    };
    changeUserConfigs(configs);
    const res = await usersAPI.setUserConfigs(configs);
    if (res.error) {
      console.log(res);
    }
  };

  useLayoutEffect(() => {
    if (user && !isMounted.current) {
      setViewLayout(user.configs?.filesLayout === undefined ? true : user.configs?.filesLayout);
    }
  }, [user]);

  useEffect(() => {
    if (!started) {
      (async () => {
        await filesAPI.init();
      // if (tourOpen) refreshTour();
      })();
    }
  }, [started, filesAPI]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredFiles = useMemo(() => (
    files.filter((f) => (
      !filterText || f.filename.toLowerCase().includes(filterText.toLowerCase())
    ))
  ), [filterText, files]);

  const handleShareFile = (file) => {
    const verifyShare = async (selected, removed) => {
      // console.log(selected);
      const result = await filesAPI.changeFilePermissions(file.file_id, selected, removed);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: `${result.raw}`,
        });
        return;
      }

      setAlertConfig({
        type: 'success',
        text: 'Opções de compartilhamento atualizadas!',
        child: selected?.length > 0 ? (
          `O Arquivo ${file.filename} está compartilhado com ${selected.map((u) => (
            `${u.name} (${shareFileOptions.get(u.sharePermission?.value)})`)).join(', ')}.`
        ) : (
          `O Arquivo ${file.filename} não está compartilhado com outros usuários.`
        ),
      });
      filesAPI.init();
      closeModal();
    };

    // console.log(file)

    const genSharedWith = () => {
      const shared = file?.shared_read?.reduce((aux, uid) => {
        aux[uid] = { value: 'read' };
        return aux;
      }, {}) || {};
      return file?.shared_write?.reduce((aux, uid) => {
        aux[uid] = { value: 'write' };
        return aux;
      }, shared) || shared;
    };

    setModalConfig({
      title: 'Compartilhar Arquivo',
      className: 'shareModal',
      children: (
        <ShareList
          users={usersState.users}
          owner={file.owner}
          publicLink={false}
          sharedWith={genSharedWith()}
          submit={verifyShare}
          close={closeModal}
          // publicUrl={dash.publicUrl}
          // publicTimestamp={dash.publicTimestamp}
          genPublicUrl={() => {}}
          tourContext={{ tourOpen, nextStep, refreshTour }}
          permissionsOpts={shareFileOptions.getOptions()}
        />
      ),
    });
  };

  const handleChangeDatabasePassword = (file) => {
    const changeDatabasePassword = async (password) => {
      const result = await filesAPI.changeDatabasePassword(file.file_id, password);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: result.msg,
          child: result.raw,
        });
        return;
      }
      setAlertConfig({
        type: 'success',
        text: 'Senha atualizada com sucesso!',
      });
      closeModal();
    };

    setModalConfig({
      title: 'Alterar Senha do Banco de Dados',
      className: 'changeDatabasePasswordModal',
      children: (
        <ChangeDatabasePassword
          close={closeModal}
          submit={changeDatabasePassword}
        />
      ),
    });
  };

  const handleSelfRemovalFromFile = (file) => {
    const selfRemoval = async (options) => {
      const result = await filesAPI.selfRemoval(file.file_id, options);
      if (result.error) {
        setAlertConfig({
          type: 'error',
          text: error.generic,
          child: result.msg,
        });
      } else {
        setAlertConfig({
          type: 'success',
          text: 'Você foi removido do compartilhamento!',
        });
      }
      filesAPI.init();
    };

    setAlertConfig({
      type: 'warning',
      text: 'Tem certeza que deseja sair do compartilhamento?',
      child: (
        <span>
          Você não poderá mais utilizar o arquivo
          {' '}
          <strong style={{ fontStyle: 'italic' }}>
            {file.filename.replace('.metrics', '')}
          </strong>
          {' '}
          para a criação de indicadores!
        </span>
      ),
      withoutConfirm: true, // tirar o ok do alerta
      withFunction: true, // colocar btn cancelar e confirmar
      confirmFunction: selfRemoval,
    });
  };

  const columnAlias = async (file) => {
    const submitAlias = async (aliasMap) => {
      const resAlias = await filesAPI.renameColumns(file.file_id, aliasMap);
      if (resAlias.error) {
        setAlertConfig({ type: 'error', text: error.generic, child: resAlias.msg });
      } else {
        setAlertConfig({
          type: 'success',
          text: `Colunas do arquivo ${file.filename} foram renomeadas!`,
        });
      }
    };

    const res = await filesAPI.getColumns(file.file_id);
    if (res.error) {
      setAlertConfig({ type: 'error', text: error.generic, child: res.msg });
    } else {
      setModalConfig({
        title: 'Apelidar Colunas',
        className: 'columnAlias',
        children: (
          <ColumnAlias
            columns={res.columns}
            alias={res.alias}
            hide={closeModal}
            submit={submitAlias}
          />
        ),
      });
    }
  };

  return (
    <>
      {fileLoading && (
        // Falta boolean de modo edição
        <Loader underText={waitingMsg} />
      )}
      <FilesToolbar
        sizeUsed={size * 1024 ** 2}
        sizeLimit={totalSize * 1024 ** 2}
        // textSearch
        setTextSearch={handleFilterText}
        viewLayout={viewLayout}
        toggleViewLayout={handleChangeLayout}
        uploadFile={handleNewFileUpload}
        // importSqlTable={handleImportSqlTables}
        tourContext={{ tourOpen, nextStep }}
        isMobileSize={device === 'phone'}
      />
      {!fileLoading && (!filteredFiles.length || !files.length) ? (
        <NoFiles toggle={handleNewFileUpload} typeMsg={files.length ? 'empty' : ''} />
      ) : (
        <FilesComponent
          user={user}
          usersList={usersState.users}
          files={filteredFiles}
          userCompany={userCompany}
          viewFile={handleViewDataFile}
          virtualAssistant={userCompany?.gptApi ? handleViewFileAssistant : null}
          updateFile={handleUpdateFile}
          downloadFile={downloadFile}
          deleteFile={handleDelete}
          showFileDashboards={showFileDashboards}
          className="files_list"
          tourContext={{ tourOpen, nextStep }}
          transferFile={handleTransferFile}
          createFileProvision={handleCreateFileProvision}
          listFileProvisions={handleListFileProvisions}
          changeDatabasePassword={handleChangeDatabasePassword}
          selfRemove={handleSelfRemovalFromFile}
          convertFileToMail={handleConvertFileToMail}
          convertFileApiGed={handleConvertFileApiGed}
          getReportStatus={getReportStatus}
          deviceType={device}
          shareFile={handleShareFile}
          columnAlias={columnAlias}
        />
      )}
    </>
  );
}

export default FilesPage;
