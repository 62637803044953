import styled, { css } from 'styled-components';
import { devices } from '../../../juristec-ui/core/breakpoints';

export const OuterLabelCreate = styled.main`
  @page {
    size: auto;
    margin: auto;
  }

  overflow: hidden;
  -webkit-overflow-scrolling: auto;
  padding: 5px 0;
  margin-top: 0rem;
  margin-left: 5%;
  margin-right: 5%;

  box-sizing: border-box;
  height: calc(100vh - 124px) !important;
  @media ${devices.tablet} {
    margin: 0px 0px;
    height: calc(100vh - 112px) !important;
  } ;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 25% auto;
  grid-gap: 10px;
  grid-template-areas: 'sideBar main';
  height: 100%;

  @media ${devices.phoneS}, ${devices.phoneL} {
    display: flex;
    flex-direction: column;
    .sideBar {
      margin-bottom: 5px;
    }
  }
  .sideBar {
    grid-area: sideBar;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
  .main {
    grid-area: main;
    display: flex;
    justify-content: center;
    min-width: 0px;
    border-radius: 4px;
    max-height: calc(100vh - 185px);
    min-height: 250px;
    background-color: transparent;
    height: 100%;
  }
  .tab-content, .mainTabs {
    height: 100%;
  }
`;

export const ScrollContainer = styled.div`
  padding: 2px 5px 10px;
  overflow: hidden auto;
  box-sizing: border-box;
  margin-top: 5px;
  height: calc(100% - 35px);
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  border-left: 5px solid ${({ theme }) => theme.containerLine};
  background-color: ${({ theme }) => theme.container};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.containerOdd};
    border-left-color: ${({ theme }) => theme.containerLineOdd};
  }
`;

export const ImgContainer = styled.div`
  & span {
    line-height: 11px;
    height: 16px;
    display: block;
    text-align: center;
    margin: .4rem 0;
  }
  & > button {
    justify-content: space-between;
    width: 100%;
    & svg {
      height: 20px;
      width: 20px;
    }
    &:last-of-type {
      & svg {
        stroke: ${({ theme }) => theme.error};
      }
    }
  }
`;

export const LabelTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
  width: 100%;
  & button {
    min-width: auto;
    width: auto;
    border-radius: 0;
    flex: 1;
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
`;

export const GptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  ${({ disabled }) => disabled && css`
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
  `}
  & > .textAreaContainer {
    width: 100%;
    height: 150px;
    & textarea {
      width: 100%;
      font-size: 14px;
    }
    & .textAreaBar {
      bottom: 1px;
    }
  }
`;

export const GptControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: .2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    & > button {
      max-height: 30px;
    }
  }
  @media ${devices.phoneS} {
    justify-content: flex-start;
  }
`;

export const GPTCredits = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.grey};
  margin: 0 .5rem;
  & a {
    color: ${({ theme }) => theme.grey};
  }
  @media ${devices.phoneS} {
    font-size: 10px;
  }
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
  height: 15px;
  width: 15px;
  & > svg {
    fill: ${({ theme }) => theme.white};
  }
`;

const colorByType = {
  category: 'info',
  object: 'info',
  float64: 'success',
  int64: 'success',
  'datetime64[ns]': 'warning',
  get(type, theme) {
    return theme[this[type] || 'grey'];
  },
};

export const ListIconWrapper = styled.div`
  height: 22px;
  width: 22px;
  margin-right: 5px;
  & > svg {
    height: 22px;
    width: 22px;
    fill: ${({ theme, typeForColor }) => colorByType.get(typeForColor, theme)};
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  z-index: 1;
  background-color: ${({ theme }) => theme.fadedBackground};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
