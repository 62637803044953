export const error = {
  generic: 'Algo deu errado!',
  instanceProvider: {
    getInstances: 'Erro ao tentar carregar as instâncias',
    getInstance: 'Erro ao tentar carregar informações da instância',
    getInstanceUsers: 'Erro ao tentar carregar informações de usuários da instância',
    createInstance: 'Erro ao tentar criar nova instância',
    editInstance: 'Erro ao tentar editar instância',
    blockInstance: 'Erro ao tentar bloquear/desbloquear instância',
    deleteInstance: 'Erro ao tentar apagar a instância',
    createOnboardingReport: 'Erro ao tentar criar o relatório de onboarding',
    getDataExtractorInfo: 'Erro ao tentar buscar as informações do cliente',
    getDataExtractorModules: 'Erro ao tentar buscar informações do módulo selecionado',
    getL1ReportModuleFields: 'Erro ao tentar buscar os campos do módulo',
    getExtractorPossibleTemplates: 'Erro ao tentar buscar os templates disponíveis',
    getExtractorTemplate: 'Erro ao tentar buscar o template',
    // instance users
    blockUser: 'Erro ao tentar bloquear/desbloquear usuário',
    multiSessionUser: 'Erro ao tentar bloquear/libera sessão simultânea',
    deleteUser: 'Erro ao tentar apagar o usuário',
    addUser: 'Erro ao tentar ciar usuário',
    editUser: 'Erro ao tentar editar o usuário',
    transferUser: 'Erro ao tentar transferir os dados do usuário',
    getReports: 'Erro ao tentar listar os relatórios',
    getReportEtls: 'Erro ao tentar listar os etls do relatório',
    createETL: 'Erro ao tentar criar O ETL',
    updateETL: 'Erro ao tentar atualizar O ETL',
    deleteETL: 'Erro ao tentar remover O ETL',
    getReportStatus: 'Erro ao tentar carregar as informações do relatório',
    deleteReport: 'Erro ao tentar remover um relatório',
    resetReport: 'Erro ao tentar resetar um relatório',
    resetReportAttempts: 'Erro ao tentar resetar as tentativas de um relatório',
    convertToGed: 'Erro ao tentar converter o relatório',
    genEmptyGed: 'Erro ao tentar gerar um relatório',
    manualReportUpdate: 'Erro ao tentar atualizar um relatório',
    errorReport: 'Erro ao tentar gerar o relatório de erros',
    requestApiCredentials: 'Erro ao tentar requisitar as credenciais',
    saveApiCredentials: 'Erro ao tentar salvar as credenciais',
    validateApiCredentials: 'Erro ao tentar validar as credenciais',
    saveGedCredentials: 'API GED cadastrada com sucesso',
    addAntecipeiReport: 'Erro ao tentar criar o relatório Antecipei',
    addBennerReport: 'Erro ao tentar criar o relatório Benner',
    inactivateReport: 'Erro ao tentar inativar o relatório',
    activateReport: 'Erro ao tentar reativar o relatório',
    restoreReport: 'Erro ao tentar restaurar o relatório',
    changeOwnerReport: 'Erro ao tentar alterar a propriedade do relatório',
    sendUsersMessage: 'Erro ao tentar enviar a mensagem',
    sendInstanceUsersMessage: 'Erro ao tentar enviar a mensagem',
    getReportColumns: 'Erro ao tentar carregar os nomes das colunas do relatório',
    editReportColumns: 'Erro ao tentar alterar as colunas do relatório',
    previewCustomColumns: 'Erro ao tentar gerar a prévia das colunas customizadas',
    manageCustomColumns: 'Erro ao tentar alterar as colunas customizadas',
    getUsers: 'Erro ao tentar carregar os usuários do sistema',
    getUserHistory: 'Erro ao tentar carregar o histórico de logins do usuário',
    getUserInfo: 'Erro ao tentar carregar as informações do usuário',
    getUserDashboards: 'Erro ao tentar carregar os dashboards do usuário',
    getUserFiles: 'Erro ao tentar listar os arquivos do usuário',
    deleteDashboard: 'Erro ao tentar deletar o dashboard do usuário',
  },
  filesProvider: {
    getFilesList: 'Erro ao tentar carregar os arquivos',
    getFile: 'Erro ao tentar carregar o arquivo',
    getCurrentCompanyStorage: 'Erro ao tentar carregar informações de armazenamento',
    downloadFile: 'Erro ao tentar fazer download do arquivo',
    convertFileToMail: 'Erro ao tentar reconfigurar o arquivo para e-mail',
    convertFileApiGed: 'Erro ao tentar reconfigurar o arquivo para utilizar a API GED',
    getSample: 'Erro ao tentar visualizar o arquivo', // ?
    deleteFile: 'Erro ao tentar apagar o arquivo',
    deleteGlobalFile: 'Erro ao tentar apagar arquivo',
    uploadFile: 'Erro ao tentar fazer upload do arquivo',
    uploadFileByLink: 'Erro ao tentar fazer upload do arquivo pelo link',
    uploadBySql: 'Erro ao tentar conectar com a base SQL',
    uploadBennerReport: 'Erro ao tentar gerar o relatório Benner',
    reopen: 'Erro ao tentar reabrir arquivo',
    getUnique: 'Erro ao tentar pegar os valores únicos do arquivo',
    baseUpdate: 'Erro ao tentar atualizar o arquivo',
    errorReport: 'Erro ao tentar gerar o relatório de erros',
    tooManyAttempts: 'Número de tentativas excedido',
    optimize: 'Erro ao tentar validar o arquivo',
    filePermissions: 'Erro ao tentar alterar as permissões do arquivo',
    transferUser: 'Erro ao tentar transferir o arquivo',
    createFileProvision: 'Erro ao tentar criar histórico para o arquivo',
    listFileProvisions: 'Erro ao tentar listar o histórico para o arquivo',
    deleteFileProvision: 'Erro ao tentar remover o histórico',
    selfRemoval: 'Erro ao tentar se auto remover do compartilhamnto do arquivo',
    getColumns: 'Erro ao tentar pegar os nomes das colunas do arquivo',
    editColumns: 'Erro ao tentar alterar as colunas do arquivo',
    renameColumns: 'Erro ao tentar renomear as colunas do arquivo',
    callVirtualAssistant: 'Erro ao tentar consultar o assistente virtual',
    changeDatabasePassword: 'Erro ao tentar alterar a senha do banco de dados',
    previewCustomColumns: 'Erro ao tentar gerar a prévia das colunas customizadas',
    manageCustomColumns: 'Erro ao tentar alterar as colunas customizadas',
    historySchedule: 'Erro ao tentar agendar o histórico',
  },
  notificationsProvider: {
    setNotificationListener: 'Erro ao verificar as notificações',
    getNotifications: 'Erro ao tentar carregar as notificações',
    setViewedAtAllVisible: 'Erro ao tentar atualizar as notificações',
    getNotificationsCount: 'Erro ao tentar carregar quantidade contador de notificações',
    getSystemMessages: 'Erro ao tentar carregar as mensagens de sistema',
    setViewdSystemMessage: 'Erro ao tentar fechar a mensagem do sistema',
  },
  policyProvider: {
    getActualPolicyId: 'Erro ao tentar receber o id da política atual',
  },
  fetchGeneric: 'Erro na comunicação com o servidor',
  useUsers: {
    getUsers: 'Erro ao tentar carregar as informações dos usuários',
    editUser: 'Erro ao tentar editar as informações do usuário',
    toggleBlock: 'Erro ao tentar bloquear/desbloquear usuário',
    deleteUser: 'Erro ao tentar apagar o usuário',
    addUser: 'Erro ao tentar criar novo usuário',
    resetPW: 'Erro ao tentar redefinir senha do usuário',
  },
  useGridKpi: {
    getAllInfos: 'Erro ao tentar carregar as informações do dashboard',
    saveLayout: 'Erro ao tentar salvar o layout',
    getReport: 'Erro ao tentar baixar o relatório',
    cloneCard: 'Erro ao tentar clonar card',
    removeCard: 'Erro ao tentar remover card',
    newSnap: 'Não foi possível criar um novo story',
    editSnap: 'Não foi possível o story',
    removeSnap: 'Não foi possível apagar o story',
    scheduleSnap: 'Não foi possível agendar a criação do story',
    createKpiComment: 'Erro ao salvar o seu comentário',
    createKpiValueFilters: 'Erro ao salvar os filtros',
    createCard: 'Erro ao tentar criar kpi',
    createInsight: 'Erro ao tentar criar um insigth',
    exportDashboard: 'Erro ao tentar exportar o dashboard',
    importDashboard: 'Erro ao tentar importar o dashboard',
    getPlotlyLink: 'Erro ao tentar carregar um kpi externo',
    // comments
    loadComments: 'Erro ao tentar carregar os comentários',
    loadMoreComments: 'Erro ao tentar carregar mais comentários',
    addComment: 'Erro ao tentar criar novo comentário',
    editComment: 'Erro ao tentar editar comentário',
    archiveComment: 'Erro ao tentar arquivar comentário',
    //
    setCardStyles: 'Erro ao tentar atualizar os estilos dos cards',
    setGlobalFilterSelectors: 'Erro ao tentar atualizar o filtro global',
    // template
    createTemplate: 'Erro ao tentar criar novo template',
    editTemplate: 'Erro ao tentar editar o template',
    deleteTemplate: 'Erro ao tentar editar o template',
  },
  useGridDash: {
    generic: 'Algo deu errado!',
    fetchGeneric: 'Erro na comunicação com o servidor',
    dashNotFound: 'Dashboard não encontrado',
    copyDash: 'Erro ao copiar o dashboard',
    kpiListNoFound: 'Lista de kpis não encontrado',
    layoutNotFound: 'Layout dos dashboards não foi encontrado',
    bookMarkDashboard: 'Erro ao tentar favoritar dashboard',
    genRequiredFromDashboard: 'Erro ao tentar acessar informações do dashboard',
  },
  useKpiData: {
    genKpiData: 'Erro ao gerar os dados do kpi',
    genSubsetData: 'Erro ao gerar os dados de sub tabela',
    genMultData: 'Erro ao gerar dados para os kpis',
  },
  instance: {
    edit: 'Aconteceu algum erro enquanto tentávamos editar a instância',
    create: 'Aconteceu algum erro enquanto tentávamos criar a instância',
  },

  optmize: {
    dateFormat: 'Data com formato diferente do selecionado',
    missingDateFormat: 'Não foi selecionado o formato para o tipo Data',
    stringToFloat: 'Campo com formato de texto não pode ser convertido para número real',
    stringToInt: 'Campo com formato de texto não pode ser convertido para número inteiro',
  },
  templateProvider: {
    getTemplate: 'Erro ao tentar carregar os templates',
    createTemplate: 'Erro ao tentar criar template',
  },
  useJiraHelpDesk: {
    newTicket: 'Erro ao tentar enviar o chamado de suporte',
    getSupportInfo: 'Erro ao tentar carregar os motivos comuns',
  },
  bigDataProvider: {
    queryPublications: 'Erro ao tentar realizar a busca',
    exportPublications: 'Erro ao tentar exportar a busca',
    queryMeta: 'Erro ao tentar realizar o filtro',
    exportMeta: 'Erro ao tentar exportar o filtro',
    getProcess: 'Erro ao tentar carregar o processo',
    getBlock: 'Erro ao tentar carregar um bloco do processo',
    initMeta: 'Erro ao tentar carregar as métricas',
    getOptions: 'Erro ao tentar carregar as opções',
    saveQueryHistory: 'Erro ao tentar salvar o histórico de busca',
    loadQueryHistory: 'Erro ao tentar carregar o histórico de busca',
  },
};

export const comfirm = {
  generic: 'Algo deu errado!',
};
