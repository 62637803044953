/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import InstanceUserRow from '../InstanceUserRow';
import InputTextLine from '../InputTextLine';
import ListItem from '../ListItem';
import List from '../List';

import { Finder } from '../../icons';

import {
  Container, InputWrapper, OverflowControl, TableFooter,
} from './styled/InstanceUserTable.styled';
import Button from '../Button';

const InstanceUserTable = ({
  users,
  handleTransfer,
  handleBlock,
  handleDelete,
  handlePermission,
  handleCopyDashboards,
  handleMultiSession,
  handleGenEmptyGed,
  userRole,
  closeModal,
  userIsAdmin,
}) => {
  const [filterTxt, setFilterTxt] = useState('');
  const [_users, _setUsers] = useState([]);
  const cronRef = useRef();

  const handleFilter = (e) => {
    const { value } = e.target;
    clearTimeout(cronRef.current);
    cronRef.current = setTimeout(() => {
      setFilterTxt(value);
    }, 150);
  };

  const filteredUsers = filterTxt
    ? _users.filter((u) => u.name.toLowerCase().includes(filterTxt.toLowerCase())
     || u.email.toLowerCase().includes(filterTxt.toLowerCase()))
    : _users;

  // handlers (feito nesse modelo porque o modal é criado fora da arvore do contexto...)
  const _handleDelete = (user) => {
    handleDelete(user);
  };

  const _handleBlock = (user) => {
    const { modalHandler, handler } = handleBlock(user);

    const aux = (d) => {
      _setUsers((p) => p.map((u) => (u.uid === user.uid ? { ...u, disabled: d } : u)));
    };

    const handlerWrapper = () => handler(aux);
    modalHandler(handlerWrapper);
  };

  function renderUsers() {
    if (!_users.length) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
          <span>
            Nenhum usuário da permissão
            {' '}
            <strong>
              {userRole}
            </strong>
            .
          </span>
        </div>
      );
    }

    if (!_users.length) {
      return (
        <h2>
          Nenhum usuário encontrado.
        </h2>
      );
    }

    return filteredUsers.map((u) => (
      <ListItem key={u.id || u.uid} withoutIconStyle>
        <InstanceUserRow
          key={u.id}
          user={u}
          handleBlock={_handleBlock}
          handleTransfer={handleTransfer}
          handlePermission={handlePermission}
          handleDelete={_handleDelete}
          handleCopyDashboards={handleCopyDashboards}
          handleMultiSession={handleMultiSession}
          handleGenEmptyGed={handleGenEmptyGed}
          userIsAdmin={userIsAdmin}
        />
      </ListItem>
    ));
  }

  useEffect(() => {
    _setUsers(users);
  }, [users]);

  return (
    <Container>
      <hr className="divider" />
      <InputWrapper>
        <InputTextLine
          placeholder="Digite o nome ou e-mail para buscar"
          onChange={handleFilter}
        />
        <Finder />
      </InputWrapper>
      <OverflowControl>

        <List>
          {renderUsers()}
        </List>
      </OverflowControl>

      <TableFooter>
        <Button onClick={closeModal} color="primary">
          OK
        </Button>
      </TableFooter>
    </Container>
  );
};

InstanceUserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  })),
  closeModal: PropTypes.func,
  userRole: PropTypes.string,
  handleTransfer: PropTypes.func,
  handleBlock: PropTypes.func,
  handleDelete: PropTypes.func,
  handlePermission: PropTypes.func,
  handleCopyDashboards: PropTypes.func,
  handleMultiSession: PropTypes.func,
  handleGenEmptyGed: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};

InstanceUserTable.defaultProps = {
  users: [],
  userRole: '',
  closeModal: () => {},
  handleTransfer: () => {},
  handleBlock: () => {},
  handleDelete: () => {},
  handlePermission: () => {},
  handleCopyDashboards: () => {},
  handleMultiSession: () => {},
  handleGenEmptyGed: () => {},
  userIsAdmin: false,
};

export default InstanceUserTable;
